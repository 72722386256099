import { PurchasePageState } from '../purchase-page/interfaces'
import { RdxActionPayload } from '../reduxTypes'
import { ACTIONS, SAGA_ACTIONS, TEST_PURCHASE_PAGE_INIT_STATE } from './interfaces'

export const testPurchasePageReducer = (
    state: PurchasePageState = TEST_PURCHASE_PAGE_INIT_STATE,
    action: RdxActionPayload
) => {

    switch (action.type) {
        case ACTIONS.TEST_SET_QUIK_CHOICE:
            return {
                ...state,
                quikChoice: action.payload
            }
        case ACTIONS.TEST_SET_BRANDS:
            return {
                ...state,
                brands: action.payload
            }
        case ACTIONS.TEST_SET_APP_VERSIONS:
            return {
                ...state,
                appVersions: action.payload
            }
        case ACTIONS.TEST_SET_FROM_DATE:
            return {
                ...state,
                fromDate: action.payload
            }
        case ACTIONS.TEST_SET_TO_DATE:
            return {
                ...state,
                toDate: action.payload
            }
        case ACTIONS.TEST_SET_CHOSEN_APPS:
            return {
                ...state,
                chosenApps: action.payload,
                appVersions: state.appVersions.length ? state.appVersions.filter((v: string) => {
                    let name = v.substring(v.indexOf('(') + 1, v.length - 1)
                    return action.payload.includes(name)
                }) : state.appVersions
            }
        case ACTIONS.TEST_SET_START_YEAR:
            return {
                ...state,
                startYear: action.payload
            }
        case ACTIONS.TEST_SET_END_YEAR:
            return {
                ...state,
                endYear: action.payload
            }
        case SAGA_ACTIONS.TEST_SET_APP_VERSION_CHOICES:
            return {
                ...state,
                appVersionChoices: action.payload
            }
        case SAGA_ACTIONS.TEST_SET_INIT_LOADING:
            return {
                ...state,
                initLoading: action.payload
            }
        case SAGA_ACTIONS.TEST_SET_YEAR_CHOICES:
            return {
                ...state,
                yearChoices: action.payload
            }
        case SAGA_ACTIONS.TEST_SET_AVAILABLE_APPS:
            return {
                ...state,
                appChoices: action.payload
            }
        case SAGA_ACTIONS.TEST_SET_BRAND_CHOICES:
            return {
                ...state,
                brandDropdownChoices: action.payload
            }
        default:
            return { ...state }
    }
}
