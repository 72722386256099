import s from './SalesPage.module.scss'
import ComparisonTableArea from './SalesPage/ComparisonTableArea';
import SalesCSVUploader from './SalesPage/SalesCSVUploader';
import CurrentSkuRecords from './SalesPage/CurrentSkuRecords';
import SalesPageContextProvider from './SalesPage/SalesPageContextProvider';


const SalesPage = () => {

    return (
        <div className={s.wrap}>
            <SalesPageContextProvider>
                <SalesCSVUploader/>
                <div className={s.top}>
                    <CurrentSkuRecords/>
                    <ComparisonTableArea/>
                </div>
            </SalesPageContextProvider>
        </div>
    );
};

export default SalesPage;