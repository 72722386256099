import { RdxActionPayload } from '../reduxTypes'
import { ACTIONS, DUAL_ACTIONS, PurchaseTableState, PURCHASE_TBL_INITIAL_STATE, SAGA_ACTIONS } from './interfaces'

export const purchaseTableReducer = (
    state: PurchaseTableState = PURCHASE_TBL_INITIAL_STATE,
    action: RdxActionPayload
) => {

    switch (action.type) {
        case ACTIONS.SET_DATA_LOADING:
            return {
                ...state,
                dataLoading: action.payload
            }
        case ACTIONS.SET_SHEET_DATA:
            return {
                ...state,
                sheetData: action.payload,
                totalRows: action.payload.length,
                currPage: undefined
            }
        case ACTIONS.SET_SEARCH_BY_FILTER:
            return {
                ...state,
                searchByFilter: action.payload,
                currPage: action.payload === true && state.currPage === undefined ? 0 : state.currPage,
                totalRows: action.payload === true && state.totalRows === undefined ? 0 : state.totalRows
            }
        case ACTIONS.SET_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.payload
            }
        case ACTIONS.SET_CURR_PAGE:
            return {
                ...state,
                currPage: action.payload
            }
        case ACTIONS.GET_SHEET_DATA:
            return {
                ...state,
                currPage: 0,
                lastReceivedDate: undefined
            }
        case ACTIONS.RESET_LAST_RECEIVED_DATE:
            return {
                ...state,
                lastReceivedDate: undefined
            }
        case ACTIONS.SET_GOING_FORWARD:
            return {
                ...state,
                goingForward: action.payload
            }
        case SAGA_ACTIONS.SET_CURR_FILTERS:
            return {
                ...state,
                currFilters: action.payload
            }
        case SAGA_ACTIONS.SET_NEW_SHEET_DATA:
            return {
                ...state,
                ...action.payload,
                currPage: 0,
                dataLoading: false
            }
        case SAGA_ACTIONS.HANDLE_PAGE_CHANGE:
            return {
                ...state,
                ...action.payload,
                dataLoading: false
            }
        case DUAL_ACTIONS.SET_TOTAL_ROWS:
            return {
                ...state,
                totalRows: action.payload
            }
        case DUAL_ACTIONS.CLEAR_TABLE:
            return {
                ...PURCHASE_TBL_INITIAL_STATE,
                searchByFilter: state.searchByFilter,
                sheetData: state.searchByFilter === false ? state.sheetData : [],
                totalRows: state.searchByFilter === false ? state.totalRows : 0,
                currPage: state.searchByFilter === false ? state.currPage : 0
            }
        default:
            return { ...state }
    }
}
