import { SemanticDropdownOpts } from '../../ts/generic/interfaces'
import { ACTIONS, SAGA_ACTIONS } from './interfaces'

export function setQuikChoice(str: string) {
    return {
        type: ACTIONS.SET_QUIK_CHOICE,
        payload: str
    }
}

export function setBrands(arr: string[]) {
    return {
        type: ACTIONS.SET_BRANDS,
        payload: arr
    }
}

export function setAppVersions(arr: string[]) {
    return {
        type: ACTIONS.SET_APP_VERSIONS,
        payload: arr
    }
}

export function setFromDate(date: Date | string) {
    return {
        type: ACTIONS.SET_FROM_DATE,
        payload: date
    }
}

export function setToDate(date: Date | string) {
    return {
        type: ACTIONS.SET_TO_DATE,
        payload: date
    }
}

export function setChosenApps(name: string[]) {
    return {
        type: ACTIONS.SET_CHOSEN_APPS,
        payload: name
    }
}

export function setStartYear(y: string) {
    return {
        type: ACTIONS.SET_START_YEAR,
        payload: y
    }
}

export function setEndYear(y: string) {
    return {
        type: ACTIONS.SET_END_YEAR,
        payload: y
    }
}

export function updateColsPurchTbl(field: string, hidden: boolean) {
    return {
        type: ACTIONS.UPDATE_COLS,
        payload: { field, hidden }
    }
}

export function setGetDataLoading(l: boolean) {
    return {
        type: ACTIONS.SET_GET_DATA_LOADING,
        payload: l
    }
}

export function initPurchasePageState() {
    return {
        type: SAGA_ACTIONS.INIT_PURCHASE_PAGE_STATE
    }
}

export function setAppChoices(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.SET_APP_CHOICES,
        payload: choices
    }
}

export function setYearOptions(options: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.SET_YEAR_CHOICES,
        payload: options
    }
}

export function setAppVersionChoices(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.SET_APP_VERSION_CHOICES,
        payload: choices
    }
}

export function setInitLoading(bool: boolean) {
    return {
        type: SAGA_ACTIONS.SET_INIT_LOADING,
        payload: bool
    }
}

export function setBrandChoices(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.SET_BRAND_CHOICES,
        payload: choices
    }
}
