import MaterialTable, { Column } from 'material-table'
import { connect } from 'react-redux'
import { PurchasePageState } from '../../../redux/purchase-page/interfaces'
import { PurchaseTableState, TableColumn } from '../../../redux/purchase-table/interfaces'
import { RootState } from '../../../redux/reduxTypes'
import s from './TestPurchaseTable.module.scss'

type Props = {
    sheetData: PurchaseTableState["sheetData"],
    dataLoading: PurchaseTableState["dataLoading"],
    columns: PurchasePageState["columns"]
}

const TestPurchaseTable = ({
    sheetData,
    columns,
    dataLoading
}: Props) => {
    const fileName = `report_${new Date().toLocaleDateString('en-GB').replace(/\//g, '_')}`

    return (
        <div className={s.wrap}>
            <MaterialTable
                columns={columns as Column<TableColumn>[]}
                data={sheetData ? sheetData : [] as any[]}
                onSearchChange={(searchText: string) => console.log(searchText)}
                isLoading={dataLoading}
                options={{
                    filtering: true,
                    tableLayout: "fixed",
                    columnsButton: true,
                    exportButton: true,
                    exportFileName: fileName,
                    exportAllData: true,
                    pageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    search: true,
                    debounceInterval: 600,
                    loadingType: "overlay"
                }}
            />

        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    const { sheetData, dataLoading } = state.testPurchaseTable
    return { dataLoading, sheetData }
}

export default connect(mapStateToProps)(TestPurchaseTable)
