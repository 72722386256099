import { SemanticDropdownOpts } from '../../ts/generic/interfaces'
import { ACTIONS, SAGA_ACTIONS } from './interfaces'

export function setQuikChoiceAnalyticsTbl(str: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_QUIK_CHOICE,
        payload: str
    }
}

export function setQuikChoiceSecondAnalyticsTbl(str: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_QUIK_CHOICE_SECOND,
        payload: str
    }
}

export function setTableAnalyticsTbl(str: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_TABLE,
        payload: str
    }
}

export function setTableFieldsAnalyticsTbl(name: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_TABLE_FIELDS,
        payload: name
    }
}

export function setManyTableFieldsAnalyticsTbl(name: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_MANY_TABLE_FIELDS,
        payload: name
    }
}

export function setTableDistinctFieldsAnalyticsTbl(arr: string[]) {
    return {
        type: ACTIONS.ANALYTICS_SET_TABLE_DISTINCT_FIELDS,
        payload: arr
    }
}

export function setTableDistinctFieldsChoicesAnalyticsTbl(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_TABLE_DISTINCT_FIELD_CHOICES,
        payload: choices
    }
}

export function setTableFieldsChoicesAnalyticsTbl(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_TABLE_FIELD_CHOICES,
        payload: choices
    }
}

export function setAppVersionsAnalyticsTbl(arr: string[]) {
    return {
        type: ACTIONS.ANALYTICS_SET_APP_VERSIONS,
        payload: arr
    }
}

export function setFromDateAnalyticsTbl(date: Date | string) {
    return {
        type: ACTIONS.ANALYTICS_SET_FROM_DATE,
        payload: date
    }
}

export function setFromDateSecondAnalyticsTbl(date: Date | string) {
    return {
        type: ACTIONS.ANALYTICS_SET_FROM_DATE_SECOND,
        payload: date
    }
}

export function setToDateAnalyticsTbl(date: Date | string) {
    return {
        type: ACTIONS.ANALYTICS_SET_TO_DATE,
        payload: date
    }
}

export function setToDateSecondAnalyticsTbl(date: Date | string) {
    return {
        type: ACTIONS.ANALYTICS_SET_TO_DATE_SECOND,
        payload: date
    }
}

export function setChosenAppsAnalyticsTbl(name: string[]) {
    return {
        type: ACTIONS.ANALYTICS_SET_CHOSEN_APPS,
        payload: name
    }
}

export function setStartYearAnalyticsTbl(y: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_START_YEAR,
        payload: y
    }
}

export function setStartYearSecondAnalyticsTbl(y: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_START_YEAR_SECOND,
        payload: y
    }
}

export function setEndYearAnalyticsTbl(y: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_END_YEAR,
        payload: y
    }
}

export function setEndYearSecondAnalyticsTbl(y: string) {
    return {
        type: ACTIONS.ANALYTICS_SET_END_YEAR_SECOND,
        payload: y
    }
}

export function updateColsAnalyticsTbl(field: string, hidden: boolean) {
    return {
        type: ACTIONS.ANALYTICS_UPDATE_COLS,
        payload: { field, hidden }
    }
}

export function setGetDataLoadingAnalyticsTbl(l: boolean) {
    return {
        type: ACTIONS.ANALYTICS_SET_GET_DATA_LOADING,
        payload: l
    }
}

export function initAnalyticsPageState() {
    return {
        type: SAGA_ACTIONS.INIT_ANALYTICS_PAGE_STATE
    }
}

export function setAppChoicesAnalyticsTbl(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_APP_CHOICES,
        payload: choices
    }
}
export function setYearOptionsAnalyticsTbl(options: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_YEAR_CHOICES,
        payload: options
    }
}

export function setAppVersionChoicesAnalyticsTbl(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_APP_VERSION_CHOICES,
        payload: choices
    }
}

export function setInitLoadingAnalyticsTbl(bool: boolean) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_INIT_LOADING,
        payload: bool
    }
}

export function setTableChoicesAnalyticsTbl(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.ANALYTICS_SET_TABLE_CHOICES,
        payload: choices
    }
}
