import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { quickChoices } from '../../../../../helpers/constants'
import { getQuickDates } from '../../../../../helpers/utils'
import { PurchasePageState } from '../../../../../redux/purchase-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'

export type QuikChoiceProps = {
    fromDate: PurchasePageState["fromDate"],
    toDate: PurchasePageState["toDate"],
    startYear: PurchasePageState["startYear"],
    endYear: PurchasePageState["endYear"],
    quikChoice: PurchasePageState["quikChoice"],
    initLoading: PurchasePageState["initLoading"],
    setFromDate: (date: Date | string) => RdxActionPayload,
    setToDate: (date: Date | string) => RdxActionPayload,
    setStartYear: (yr: string) => RdxActionPayload,
    setEndYear: (yr: string) => RdxActionPayload,
    setQuikChoice: (s: string) => RdxActionPayload
}

const QuikChoice = ({
    fromDate,
    toDate,
    startYear,
    endYear,
    initLoading,
    quikChoice,
    setFromDate,
    setToDate,
    setStartYear,
    setEndYear,
    setQuikChoice
}: QuikChoiceProps) => {

    const { dayBefore, seven, thirty } = getQuickDates()

    function resetStartDate() {
        setFromDate("")
        setStartYear("")
    }

    function resetEndDate() {
        setToDate("")
        setEndYear("")
    }

    function handleQuickChoice(event: React.SyntheticEvent) {
        let { currentTarget } = event

        if (currentTarget.textContent) {
            let { textContent } = currentTarget
            setQuikChoice(textContent)
            switch (textContent) {
                case 'Yesterday':
                    setFromDate(dayBefore[0]);
                    setToDate(dayBefore[1]);
                    break
                case 'Last 30 days':
                    setFromDate(thirty[0]);
                    setToDate(thirty[1]);
                    break
                case 'Last 7 days':
                    setFromDate(seven[0]);
                    setToDate(seven[1]);
                    break
                default:
                    setFromDate("");
                    setToDate("");
            }
        } else {
            setQuikChoice("")
            resetEndDate()
            resetStartDate()
        }
    }

    return (
        <section>
            <h3>Quick choices:</h3>
            <Dropdown
                placeholder='quick choice'
                selection
                options={quickChoices}
                onChange={handleQuickChoice}
                selectOnBlur={false}
                clearable
                loading={initLoading}
                value={quikChoice}
                disabled={(startYear || endYear) ? true : false}
            />
            {quikChoice ?
                <div style={{ fontWeight: 'bold' }}>
                    <p> {new Date(fromDate).toString().split('(')[0]} </p>
                    <p>TO</p>
                    <p>  {new Date(toDate).toString().split('(')[0]} </p>
                </div>
                : null
            }
        </section>
    )
}

export default QuikChoice
