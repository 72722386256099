import DbSkuRecordsTable from '../../reusable/DbSkuRecordsTable';
import s from './ComparisonTableArea.module.scss'
import { useSalesContext } from './SalesPageContextProvider';



const ComparisonTableArea = () => {
    const { comparisonRecords } = useSalesContext()!;
    return (
        <div className={s.wrap}>
            <h3>Comparison Table</h3>
            <DbSkuRecordsTable records={comparisonRecords} useDates={true}/>
        </div>
    );
};

export default ComparisonTableArea;