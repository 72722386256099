import { createSelector } from 'reselect'
import { TestPurchQueryParams } from '../purchase-table/interfaces'
import { RootState } from '../reduxTypes'

const testPurchasePage = (rootState: RootState) => rootState.testPurchasePage

export const selectQuikChoice = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.quikChoice
)

export const selectVersions = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.appVersions
)

export const selectFromDate = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.fromDate
)

export const selectToDate = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.toDate
)

export const selectChosenApps = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.chosenApps
)

export const selectStartYear = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.startYear
)

export const selectEndYear = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.endYear
)

export const selectBrands = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.brands
)

export const selectColumns = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.columns
)

export const selectVersionChoices = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.appVersionChoices
)

export const selectInitLoading = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.initLoading
)

export const selectYearChoices = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.yearChoices
)

export const selectAppChoices = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.appChoices
)

export const selectBrandChoices = createSelector(
    [testPurchasePage],
    testPurchasePage => testPurchasePage.brandDropdownChoices
)

export const selectUpdateTestTableProps = (rootState: RootState): TestPurchQueryParams => {
    const { toDate, fromDate, chosenApps, appVersions, brands } = rootState.testPurchasePage;
    return {
        startDate: fromDate,
        endDate: toDate,
        chosenApps,
        appVersions,
        brands,
        getTests: true
    }
}
