import { createSelector } from 'reselect'
import { RootState } from '../reduxTypes'

const purchasePage = (rootState: RootState) => rootState.purchasePage
const testPurchasePage = (rootState: RootState) => rootState.testPurchasePage

export const selectQuickChoice = createSelector(
    [purchasePage],
    purchasePage => purchasePage.quikChoice
)

export const selectVersions = createSelector(
    [purchasePage],
    purchasePage => purchasePage.appVersions
)

export const selectFromDate = createSelector(
    [purchasePage],
    purchasePage => purchasePage.fromDate
)

export const selectToDate = createSelector(
    [purchasePage],
    purchasePage => purchasePage.toDate
)

export const selectChosenApps = createSelector(
    [purchasePage],
    purchasePage => purchasePage.chosenApps
)

export const selectStartYear = createSelector(
    [purchasePage],
    purchasePage => purchasePage.startYear
)

export const selectEndYear = createSelector(
    [purchasePage],
    purchasePage => purchasePage.endYear
)

export const selectBrands = createSelector(
    [purchasePage],
    purchasePage => purchasePage.brands
)

export const selectGetDataLoading = createSelector(
    [purchasePage],
    purchasePage => purchasePage.getDataLoading
)

export const selectVersionChoices = createSelector(
    [purchasePage],
    purchasePage => purchasePage.appVersionChoices
)

export const selectInitLoading = createSelector(
    [purchasePage],
    purchasePage => purchasePage.initLoading
)

export const selectYearChoices = createSelector(
    [purchasePage],
    purchasePage => purchasePage.yearChoices
)

export const selectAppChoices = createSelector(
    [purchasePage],
    purchasePage => purchasePage.appChoices
)

export const selectBrandChoices = createSelector(
    [purchasePage],
    purchasePage => purchasePage.brandDropdownChoices
)

export const selectAllChosenApps = createSelector(
    [purchasePage, testPurchasePage],
    (purchasePage, testPurchasePage) => {
        return {
            realChosenApps: purchasePage.chosenApps,
            testChosenApps: testPurchasePage.chosenApps
        }
    }
)

export const selectAllAppChoices = createSelector(
    [purchasePage, testPurchasePage],
    (purchasePage, testPurchasePage) => {
        return {
            realAppChoices: purchasePage.appChoices,
            testAppChoices: testPurchasePage.appChoices
        }
    }
)

export const selectUpdatePurchTblProps = (rootState: RootState) => {
    const { endYear, startYear, toDate, fromDate, chosenApps, appVersions, brands } = rootState.purchasePage;
    return {
        endYear,
        startYear,
        toDate,
        fromDate,
        chosenApps,
        appVersions,
        brands
    }
}
