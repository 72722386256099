import to from 'await-to-js'
import { CsvBuilder } from 'filefy'
import MaterialTable, { Column, Options } from 'material-table'
import { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { PAGES, purchaseTblCols, toastErrConfig } from '../../../helpers/constants'
import { setToast } from '../../../redux/admin-state/adminActions'
import { Toast } from '../../../redux/admin-state/interfaces'
import { PurchasePageState } from '../../../redux/purchase-page/interfaces'
import { updateColsPurchTbl } from '../../../redux/purchase-page/purchasePageActions'
import { PurchaseTableState, TableColumn, TableFilter } from '../../../redux/purchase-table/interfaces'
import { changePageWhileFilteringPurchTbl, resetLastReceivedDatePurchTbl, setCurrPagePurchTbl, setGoingForwardPurchTbl, setRowsPerPagePurchTbl, updateSheetDataPurchTbl } from '../../../redux/purchase-table/purchaseTableActions'
import { selectCurrFiltersPurchTbl, selectCurrPagePurchTbl, selectDataLoadingPurchTbl, selectHasNextPurchTbl, selectSearchByFilterPurchTbl, selectSheetDataPurchTbl, selectTotalLTVPurchTbl, selectTotalRowsPurchTbl } from '../../../redux/purchase-table/purchaseTableSelectors'
import { RdxActionPayload, RootState } from '../../../redux/reduxTypes'
import { exportDefaultCsv } from '../../../redux/store'
import TableTitleWithToggle from '../../reusable/TableTitleWithToggle/TableTitleWithToggle'
import s from './PurchaseTable.module.scss'

type Props = {
    sheetData: PurchaseTableState["sheetData"],
    dataLoading: PurchaseTableState["dataLoading"],
    updateSheetDataPurchTbl: (filters: TableFilter[]) => RdxActionPayload,
    setRowsPerPagePurchTbl: (n: number) => RdxActionPayload,
    setCurrPagePurchTbl: (n: number) => RdxActionPayload,
    setGoingForwardPurchTbl: (b: boolean) => RdxActionPayload,
    changePageWhileFilteringPurchTbl: () => RdxActionPayload,
    updateColsPurchTbl: (field: string, hidden: boolean) => RdxActionPayload,
    resetLastReceivedDatePurchTbl: () => RdxActionPayload,
    setToast: (toast: Toast) => RdxActionPayload
    columns: PurchasePageState["columns"],
    totalRows: PurchaseTableState["totalRows"],
    totalLTV: PurchaseTableState["totalLTV"],
    currPage: PurchaseTableState["currPage"],
    hasNext: PurchaseTableState["hasNext"],
    searchByFilter: PurchaseTableState["searchByFilter"],
    currFilters: PurchaseTableState["currFilters"]
}

const PurchaseTable = ({
    sheetData,
    dataLoading,
    columns,
    totalRows,
    totalLTV,
    currPage,
    hasNext,
    searchByFilter,
    currFilters,
    updateSheetDataPurchTbl,
    setRowsPerPagePurchTbl,
    setCurrPagePurchTbl,
    setGoingForwardPurchTbl,
    resetLastReceivedDatePurchTbl,
    changePageWhileFilteringPurchTbl,
    updateColsPurchTbl,
    setToast
}: Props) => {

    const [isLoading, setIsLoading] = useState(false)
    const fileName = `purchases_report_${new Date().toLocaleDateString('en-GB').replace(/\//g, '_')}`

    function handleChangePage(page: number, pageSize: number) {
        if (currPage !== undefined) {
            if (page > currPage) setGoingForwardPurchTbl(true)
            else setGoingForwardPurchTbl(false)
            let difference = Math.abs(page - currPage)
            if (difference > 1 || !hasNext) resetLastReceivedDatePurchTbl()
        }

        if (page) setCurrPagePurchTbl(page)
        if (pageSize) setRowsPerPagePurchTbl(pageSize)
        changePageWhileFilteringPurchTbl()
    }

    function handleChangeColHidden(column: Column<TableColumn>, hidden: boolean) {
        let field = column?.field || ''
        if (!field) return

        updateColsPurchTbl(field, hidden);
        if (hidden && Array.isArray(currFilters) && currFilters.length > 0)
            updateSheetDataPurchTbl(currFilters.filter((f: TableFilter) => f.column.field !== field));

    }

    let tableOptions: Options<TableColumn> = {
        filtering: true,
        tableLayout: "fixed",
        columnsButton: true,
        exportButton: { csv: true, pdf: false },
        exportFileName: fileName,
        exportAllData: true,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        search: true,
        debounceInterval: 750,
        loadingType: "overlay",
        exportCsv: async (__: any[], ___: any[]) => {
            setIsLoading(true)
            const [e1, response] = await to<any, string>(exportDefaultCsv());
            if (e1 || sheetData.length === 0) {
                setToast({
                    msg: e1 ? e1 : 'No Data Found',
                    config: toastErrConfig
                })
            } else {
                const columns = Object.keys(response[0])
                let columnTitles: string[] = []
                columns.forEach((c: string) => {
                    let nameC = purchaseTblCols.find(ptc => ptc.field === c)
                    if (nameC && nameC?.title)
                        columnTitles.push(nameC?.title)
                    else columnTitles.push(c)
                })

                const csvData = response.map((rowData: any) => Object.values(rowData))
                new CsvBuilder(fileName)
                    .setColumns(columnTitles)
                    .addRows(csvData)
                    .exportFile();
            }
            setIsLoading(false)
        }
    }

    return (
        <div className={s.wrap}>
            <h5>Total LTV of associated user: {totalLTV}</h5>
            <MaterialTable
                title={<TableTitleWithToggle page={PAGES.PURCHASE} />}
                columns={columns as Column<TableColumn>[]}
                data={sheetData ? sheetData : [] as any[]}
                onSearchChange={(searchText: string) => console.log(searchText)}
                onFilterChange={(filters) => updateSheetDataPurchTbl(filters as TableFilter[])}
                onChangePage={handleChangePage}
                isLoading={dataLoading || isLoading}
                totalCount={totalRows}
                onChangeColumnHidden={handleChangeColHidden}
                page={currPage}
                options={tableOptions}
            />
        </div>
    )
}

type SelectorProps = Pick<Props, "sheetData" | "dataLoading" | "totalRows" | "totalLTV" | "currPage" | "hasNext" | "searchByFilter" | "currFilters">;
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    sheetData: selectSheetDataPurchTbl,
    dataLoading: selectDataLoadingPurchTbl,
    totalRows: selectTotalRowsPurchTbl,
    totalLTV: selectTotalLTVPurchTbl,
    currPage: selectCurrPagePurchTbl,
    hasNext: selectHasNextPurchTbl,
    searchByFilter: selectSearchByFilterPurchTbl,
    currFilters: selectCurrFiltersPurchTbl
})

const mapDispatchToProps = {
    updateSheetDataPurchTbl: (filters: TableFilter[]) => updateSheetDataPurchTbl(filters),
    setRowsPerPagePurchTbl: (n: number) => setRowsPerPagePurchTbl(n),
    setCurrPagePurchTbl: (n: number) => setCurrPagePurchTbl(n),
    setGoingForwardPurchTbl: (b: boolean) => setGoingForwardPurchTbl(b),
    resetLastReceivedDatePurchTbl: () => resetLastReceivedDatePurchTbl(),
    changePageWhileFilteringPurchTbl: () => changePageWhileFilteringPurchTbl(),
    updateColsPurchTbl: (field: string, hidden: boolean) => updateColsPurchTbl(field, hidden),
    setToast: (toast: Toast) => setToast(toast)
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseTable)
