import { createSelector } from "reselect"
import { AnalyticsTableState } from "./analytics-table/interfaces"
import { AdUtilState } from "./data-utils/ad-util/interfaces"
import { PurchaseTableState } from "./purchase-table/interfaces"
import { RootState } from "./reduxTypes"

const purchaseTable = (state: RootState) => state.purchaseTable
const analyticsTable = (state: RootState) => state.analyticsTable
const testPurchaseTable = (state: RootState) => state.testPurchaseTable
const adDataState = (state: RootState) => state.dataUtils.adData;

export type AllSheetData = {
    purchaseTblData: PurchaseTableState["sheetData"],
    analyticsTblData: AnalyticsTableState["sheetData"],
    testPurchaseTblData: PurchaseTableState["sheetData"],
    adData: AdUtilState["data"]
}

export const selectAllSheetData = createSelector(
    [purchaseTable, analyticsTable, testPurchaseTable, adDataState],
    (purchaseTable, analyticsTable, testPurchaseTable, adDataState) => {
        let purchaseTblData = purchaseTable.sheetData;
        let analyticsTblData = analyticsTable.sheetData;
        let testPurchaseTblData = testPurchaseTable.sheetData;
        let adData = adDataState.data;
        return {
            purchaseTblData,
            analyticsTblData,
            testPurchaseTblData,
            adData
        }
    }
)
