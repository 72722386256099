import { useState } from 'react'
import { connect } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button } from 'semantic-ui-react'
import { toastErrConfig, toastInfoConfig } from '../../../helpers/constants'
import { AnalyticsPageState, ANALYTICS_PAGE_INITIAL_STATE } from '../../../redux/analytics-page/interfaces'
import { getSheetDataAnalytics } from '../../../redux/analytics-table/analyticsTableActions'
import { RdxActionPayload, RootState } from '../../../redux/reduxTypes'
import { dbSearchActionsNeeded } from '../../../redux/store'
import s from './AnalyticsDbSearchOptions.module.scss'
import AppSection from './AppSection/AppSection'
import DateSection from './DateSection/DateSection'
import DateSectionSecond from './DateSection/DateSectionSecond'
import { formatAndCheckDatesAnalytics, formatChildPropsAnalytics } from './supplement'
import TableFieldSection from './TableFieldSection/TableFieldSection'
import TableSection from './TableSection/TableSection'

type Props = {
    analyticsPage: AnalyticsPageState,
    getSheetDataAnalytics: () => RdxActionPayload
}

const AnalyticsDbSearchOptions = ({
    analyticsPage,
    getSheetDataAnalytics
}: Props) => {

    const { addToast } = useToasts()
    const {
        appSectionProps,
        tableSectionProps,
        tableFieldSectionProps,
        dateSectionProps,
        dateSectionSecondProps
    } = formatChildPropsAnalytics(analyticsPage, dbSearchActionsNeeded)

    const [showSecondDate, setShowSecondDate] = useState(false)

    async function queryDb() {
        try {
            let { endYear, startYear, toDate, fromDate } = (ANALYTICS_PAGE_INITIAL_STATE as AnalyticsPageState)
            let { endYearSecond, startYearSecond, toDateSecond, fromDateSecond } = (ANALYTICS_PAGE_INITIAL_STATE as AnalyticsPageState)
            endYear = analyticsPage.endYear
            startYear = analyticsPage.startYear
            toDate = analyticsPage.toDate
            fromDate = analyticsPage.fromDate

            endYearSecond = analyticsPage.endYearSecond
            startYearSecond = analyticsPage.startYearSecond
            toDateSecond = analyticsPage.toDateSecond
            fromDateSecond = analyticsPage.fromDateSecond
            let dates = formatAndCheckDatesAnalytics(startYear, endYear, fromDate, toDate)
            let datesSecond = formatAndCheckDatesAnalytics(startYearSecond, endYearSecond, fromDateSecond, toDateSecond)

            if (dates.endDate === "err" || datesSecond.endDate === "err") return addToast('Please ensure end year is after start year, and end date is after start date.', toastErrConfig)
            if (analyticsPage.chosenTable === "purchases" && analyticsPage.chosenTableFields === "") return addToast('Please select a field.', toastErrConfig)
            if (analyticsPage.chosenTable === "clicks") addToast("It may take a while to fetch click data...", toastInfoConfig)

            getSheetDataAnalytics()
        } catch (e: any) {
            addToast(`Error in function: ${e.message}`, toastErrConfig);
        }
    }

    function initialSecondDate() {
        let { startDatePicker, endDatePicker } = dateSectionSecondProps
        const now = new Date()
        const earlyToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 1)
        startDatePicker.setFromDate(earlyToday)
        startDatePicker.setStartYear(String(now.getFullYear()))
        endDatePicker.setEndYear(String(now.getFullYear()))
        endDatePicker.setToDate(now)
        setShowSecondDate(true)
    }

    return (
        <div className={s.actions}>
            <h1>Search Options</h1>
            <div className={s.choices}>
                <AppSection {...appSectionProps} />
                <TableSection {...tableSectionProps} setManyTableFields={tableFieldSectionProps.setManyTableFields} />
                <TableFieldSection {...tableFieldSectionProps} />
                <DateSection {...dateSectionProps} />
                {showSecondDate ?
                    <DateSectionSecond {...dateSectionSecondProps} setShowSecondDate={setShowSecondDate} />
                    :
                    <Button
                        onClick={initialSecondDate}
                        color="grey"
                    >
                        Comparison
                    </Button>
                }
            </div>
            <Button loading={analyticsPage.getDataLoading ? true : false} onClick={queryDb} color="red">Get Data</Button>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    const { analyticsPage } = state
    return { analyticsPage }
}

const mapDispatch = {
    getSheetDataAnalytics: () => getSheetDataAnalytics()
}

export default connect(mapStateToProps, mapDispatch)(AnalyticsDbSearchOptions)
