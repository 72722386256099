import React from 'react'
import { Button, Icon, Table } from 'semantic-ui-react';
import { useSalesContext } from '../pages/SalesPage/SalesPageContextProvider';
import s from './DbSkuRecordsTable.module.scss';

type Props = {
    records: SkuDbRecord[]
    useDates?: boolean
    titleYear?: number
    titleMonth?: number
}

export type SkuDbRecord = {
    month: number
    year: number
    sku: string
    ee: {[key: string]: any}
}

const DbSkuRecordsTable = ({ records, useDates, titleYear, titleMonth } : Props) => {
    const { addComparisonRecord, delComparisonRecord } = useSalesContext()!;
    const allCurrencies: { [key: string]: any} = {};
    for (const dbSkuRecord of records) {
        for (const currency in dbSkuRecord.ee) {
            if (!(currency in allCurrencies)) allCurrencies[currency] = true;
        }
    }
    const currencies = Object.keys(allCurrencies).sort((a,b) => b.localeCompare(a));

    const TopTableHeaderRow = () => (
        <Table.Row>
            <Table.HeaderCell >
                Estimated Earnings {titleMonth! < 10 ? `0${titleMonth}` : titleMonth}-{titleYear}
            </Table.HeaderCell>
            {
                currencies.map((_, idx) => <Table.HeaderCell key={idx}></Table.HeaderCell>)
            }
            {
                <Table.HeaderCell></Table.HeaderCell>   
            }
        </Table.Row>
    );

    return (
        <Table className={s.table} selectable size="small" striped>
            
            <Table.Header>
                    {
                        titleYear && titleMonth && (
                            <TopTableHeaderRow/>
                        )
                    }
                <Table.Row>
                    
                    <Table.HeaderCell>Unique SKU</Table.HeaderCell>
                    {
                        useDates && (
                            <Table.HeaderCell>Period</Table.HeaderCell>
                        )
                    }
                    {
                        currencies.map((currency, idx) => <Table.HeaderCell key={idx}>{currency}</Table.HeaderCell>)
                    }
                    {
                        <Table.HeaderCell></Table.HeaderCell>   
                    }
                </Table.Row>
            </Table.Header>

            <Table.Body>
            {
                records.length > 0 && records.map((data, idx) => (
                    <Table.Row key={idx} verticalAlign='top'>
                        <Table.Cell className={s.cell}>{data.sku}</Table.Cell>
                        {
                            useDates && (
                                <Table.Cell>{`${data.month}-${data.year}`}</Table.Cell>
                            )
                        }
                        {
                            currencies.map((currency, idx) => {
                                if (currency in data.ee) {
                                    return <Table.Cell key={idx}>{data.ee[currency].toFixed(2)}</Table.Cell>
                                } else {
                                    return <Table.Cell key={idx}>{null}</Table.Cell>
                                }
                            })
                        }
                        {
                            !useDates ? (
                                <Table.Cell>
                                    <Button size="tiny" onClick={() => addComparisonRecord(data)}>
                                        <Icon name='arrow alternate circle right'/>
                                    </Button>
                                </Table.Cell>
                            ) : (
                                <Table.Cell>
                                    <Button size="tiny" color="red" onClick={() => delComparisonRecord(data)}>
                                        <Icon name='delete'/>
                                    </Button>
                                </Table.Cell>
                            )
                        }
                       
                    </Table.Row>
                ))
            }
            </Table.Body>
        </Table>
    );
};

export default DbSkuRecordsTable;