import { adDataQueryOptions } from "../../../helpers/constants"
import { AdData, SemanticDropdownOpts } from "../../../ts/generic/interfaces"

export interface DateRange {
    earliest: string,
    mostRecent: string
}

export interface AdUtilState {
    queryChoices: SemanticDropdownOpts[],
    appChoices: SemanticDropdownOpts[],
    yearChoices: SemanticDropdownOpts[],
    chosenApps: string[],
    chosenQuery: string,
    fromDate: string | Date,
    toDate: string | Date,
    startYear: string,
    endYear: string,
    data: AdData[],
    dateRange: DateRange

    /*
    Pagination related stuff, not really needed for now 
    
    totalRows: number | undefined,
    rowsPerPage: number,
    currPage: number | undefined,
    lastReceivedDate: string | undefined,
    hasNext: boolean,
    goingForward: boolean
    */
}

export const AD_UTIL_INITIAL_STATE = {
    queryChoices: adDataQueryOptions,
    appChoices: [],
    yearChoices: [],
    chosenQuery: "",
    chosenApps: [],
    fromDate: "",
    toDate: "",
    startYear: "",
    endYear: "",
    data: [],
    dateRange: {
        earliest: "",
        mostRecent: ""
    }

    /*
    Pagination related stuff, not really needed for now 

    totalRows: undefined, // test
    rowsPerPage: 10,
    currPage: 0,
    lastReceivedDate: undefined,
    hasNext: false,
    goingForward: true
    */
}

export const ACTIONS = {
    adDataUtil_SET_CHOSEN_APPS: "adDataUtil_SET_CHOSEN_APPS",
    adDataUtil_SET_FROM_DATE: "adDataUtil_SET_FROM_DATE",
    adDataUtil_SET_TO_DATE: "adDataUtil_SET_TO_DATE",
    adDataUtil_SET_START_YEAR: "adDataUtil_SET_START_YEAR",
    adDataUtil_SET_END_YEAR: "adDataUtil_SET_END_YEAR",
    adDataUtil_SET_DATA: "adDataUtil_SET_DATA",
    adDataUtil_SET_CHOSEN_QUERY: "adDataUtil_SET_CHOSEN_QUERY"

    /*
    Pagination related stuff, not really needed for now 

    adDataUtil_SET_TOTAL_ROWS: "adDataUtil_SET_TOTAL_ROWS",
    adDataUtil_SET_ROWS_PER_PAGE: "adDataUtil_SET_ROWS_PER_PAGE",
    adDataUtil_SET_CURR_PAGE: "adDataUtil_SET_CURR_PAGE",
    adDataUtil_SET_LAST_RECEIVED_DATE: "adDataUtil_SET_LAST_RECEIVED_DATE",
    adDataUtil_CLEAR_LAST_RECEIVED_DATE: "adDataUtil_CLEAR_LAST_RECEIVED_DATE",
    adDataUtil_SET_HAS_NEXT: "adDataUtil_SET_HAS_NEXT",
    adDataUtil_SET_GOING_FORWARD: "adDataUtil_SET_GOING_FORWARD"
    */
}

export const SAGA_ACTIONS = {
    adDataUtil_SET_APP_CHOICES: "adDataUtil_SET_APP_CHOICES",
    adDataUtil_SET_YEAR_CHOICES: "adDataUtil_SET_YEAR_CHOICES",
    adDataUtil_SET_DATE_RANGE: "adDataUtil_SET_DATE_RANGE",
    adDataUtil_INIT_STATE: "adDataUtil_INIT_STATE"
}

export interface AdDataRowFromDB {
    appName: string[],
    campaigns: string[],
    cancellationsDuringTrial: number,
    cancelledAfterConversion: number,
    conversionRate: number,
    conversions: number,
    costPerInstall: number,
    currentConvertedSubscriptions: number,
    currentTrials: number,
    installCost: number,
    profit: number,
    queryId: string,
    roi: number,
    siteId: string,
    totalCancelled: number,
    totalInstalls: number,
    totalRevenue: number,
    totalTrials: number,
    trialsPerInstall: number
}

export interface AdDataRow extends Omit<AdDataRowFromDB, "conversionRate" | "appName" | "roi"> {
    conversionRate: string,
    appName: string,
    roi: string
}

/*
Pagination related stuff, not really needed for now

export type PaginationData = {
    rowsPerPage: AdUtilState["rowsPerPage"],
    currPage: AdUtilState["currPage"],
    lastReceivedDate: AdUtilState["lastReceivedDate"]
}
*/
