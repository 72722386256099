import axios, { AxiosResponse } from "axios"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { formatAndCheckDates, FormattedDates } from "../../components/reusable/DbSearchOptions/supplement"
import { BASE_URL, toastErrConfig, toastWarningConfig } from "../../helpers/constants"
import { checkInput } from "../../helpers/utils"
import { setToast } from "../admin-state/adminActions"
import { setGetDataLoading } from "../purchase-page/purchasePageActions"
import { selectUpdatePurchTblProps } from "../purchase-page/purchasePageSelectors"
import { RdxActionPayload } from "../reduxTypes"
import { ACTIONS, PaginationData, QueryParams, TableFilter } from "./interfaces"
import { handleChangePagePurchTbl, setCurrFiltersPurchTbl, setDataLoadingPurchTbl, setNewSheetDataPurchTbl } from "./purchaseTableActions"
import { selectCurrFiltersPurchTbl, selectGoingForwardPurchTbl, selectLastReceivedDatePurchTbl, selectPaginationDataPurchTbl, selectRowsPerPagePurchTbl } from "./purchaseTableSelectors"

export interface AppNamesAndVersions {
    appName: string,
    appVersions: string[]
}

export type PurchaseQueryOptionsParams = QueryParams & FormattedDates & {
    getTests: boolean
}

export interface GetPurchaseQueryData extends PaginationData {
    optionsParams: PurchaseQueryOptionsParams
    filters: TableFilter[]
    testTable: boolean
    goingForward: boolean
    appNamesAndVersions: string
}

export interface GetAllPurchaseQueryData {
    optionsParams: PurchaseQueryOptionsParams
    filters: TableFilter[]
    testTable: boolean
    goingForward: boolean
    appNamesAndVersions: string
}

function* handleUpdatePurchTbl(action: RdxActionPayload) {
    let filters: TableFilter[]
    let paginationData: PaginationData
    let goingForward: boolean = yield select(selectGoingForwardPurchTbl)

    try {
        if (action.type === ACTIONS.UPDATE_SHEET_DATA) {
            paginationData = {
                currPage: 0,
                rowsPerPage: yield select(selectRowsPerPagePurchTbl),
                lastReceivedDate: yield select(selectLastReceivedDatePurchTbl)
            }
            filters = action.payload

            let { hasBadInput, msg } = checkInput(filters)

            if (hasBadInput) {
                yield put(setToast({ msg, config: toastWarningConfig }))
                return
            }
            if (action.payload.length === 0) paginationData = { ...paginationData, currPage: 0, lastReceivedDate: undefined }
            yield put(setCurrFiltersPurchTbl(filters))

        } else {
            filters = yield select(selectCurrFiltersPurchTbl)
            paginationData = yield select(selectPaginationDataPurchTbl)
            if (action.type === ACTIONS.GET_SHEET_DATA) yield put(setGetDataLoading(true))
        }

        try {
            let neededParams: QueryParams = yield select(selectUpdatePurchTblProps)
            const { startYear, endYear, fromDate, toDate } = neededParams
            let appNamesAndVersionsArray: AppNamesAndVersions[] = []

            Array.isArray(neededParams.chosenApps) &&
                neededParams.chosenApps.forEach((item) => {
                    let currAppName = item
                    let newEntry: AppNamesAndVersions = { appName: currAppName, appVersions: [] }
                    appNamesAndVersionsArray.push(newEntry)
                })

            Array.isArray(neededParams.appVersions) &&
                neededParams.appVersions.forEach((item) => {
                    let currAppName = item.substring(item.indexOf('(') + 1, item.length - 1)
                    let currAppVersion = item.substring(0, item.indexOf('('))

                    let existingAppNameIndex =
                        appNamesAndVersionsArray.findIndex((entry) => {
                            if (entry.appName === currAppName) return true
                            return false
                        })

                    if (existingAppNameIndex !== -1)
                        appNamesAndVersionsArray[existingAppNameIndex].appVersions.push(currAppVersion)
                })

            const dates = formatAndCheckDates(startYear, endYear, fromDate, toDate)
            if (dates.endDate === 'err') {
                yield put(setToast({
                    msg: "Please make sure years are in correct order, and start date is before end date.",
                    config: toastErrConfig
                }))
                return
            }

            let appNamesAndVersions = JSON.stringify(appNamesAndVersionsArray)
            const url = BASE_URL + '/data/querypurchases/aggregatepurchases'
            const optionsParams: PurchaseQueryOptionsParams = { ...neededParams, ...dates, getTests: true }

            const data: GetPurchaseQueryData = {
                optionsParams,
                filters,
                testTable: false,
                ...paginationData,
                goingForward,
                appNamesAndVersions
            }

            yield put(setDataLoadingPurchTbl(true))
            const res: AxiosResponse = yield call(axios.post, url, data)

            if (res) {
                if (res.data) {
                    if (res.data.err || res.data.error) {
                        yield put(setToast({
                            msg: 'There was an error getting purchases. Contact developer.',
                            config: toastErrConfig
                        }))
                    }
                    if (action.type === ACTIONS.UPDATE_SHEET_DATA) yield put(setNewSheetDataPurchTbl(res.data))
                    else {
                        yield put(handleChangePagePurchTbl(res.data))
                        if (action.type === ACTIONS.GET_SHEET_DATA) yield put(setGetDataLoading(false))
                    }
                }
            }
        } catch (e) {
            yield put(setGetDataLoading(false))
            yield put(setDataLoadingPurchTbl(false))
            yield put(setToast({
                msg: 'There was an error getting purchases. Contact developer.',
                config: toastErrConfig
            }))
        }
    } catch (e) {
        yield console.log(e)
    }
}

function* interceptUpdatePurchaseTbl() {
    yield takeLatest(
        [ACTIONS.UPDATE_SHEET_DATA, ACTIONS.CHANGE_PAGE_WHILE_FILTERING, ACTIONS.GET_SHEET_DATA],
        handleUpdatePurchTbl
    )
}

export function* purchaseTableSagas() {
    yield all([call(interceptUpdatePurchaseTbl)])
}
