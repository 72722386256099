import { useMemo } from 'react'
import { randomRGBAColor } from '../../../helpers/utils'
import { AnalyticsTableState, SheetRowAnalytics } from '../../../redux/analytics-table/interfaces'
import CanvasJSReact from '../../canvas/canvasjs.react'
import s from './ChartAnalytics.module.scss'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

type Props = {
	sheetData: AnalyticsTableState["sheetData"],
	showDataType: string,
	isComparison: boolean,
	date1: string,
	date2: string
}

type DataPoints = {
	label: string,
	y: number,
	color?: string
}

const Canvas = ({
	sheetData,
	showDataType,
	isComparison,
	date1,
	date2
}: Props) => {

	let opstionsBar = useMemo(() => {
		let type: string = showDataType === 'Barv' ? 'column' : 'bar'
		let dataPoints: DataPoints[] = []
		let dataPointsComparison: DataPoints[] = []
		let colors: string[] = []
		if (sheetData.length > 0) {
			sheetData.forEach(_ => {
				if (_.selectedFields === 'Total') return
				let color = randomRGBAColor(colors) || ''
				if (color) colors.push(color)
			})

			sheetData.forEach((data: SheetRowAnalytics, i: number) => {
				let label = data.selectedFields
				if (label === 'Total') return
				let y = data?.totalNum || 0
				let yComparison = data?.totalNumComparison || 0

				if (!isComparison) dataPoints.push({ label, y, color: colors[i] })
				else {
					dataPoints.push({ label, y })
					dataPointsComparison.push({ label, y: yComparison })
				}
			})
		}

		let color = randomRGBAColor([]) || ''
		let colorComparison = randomRGBAColor([color])

		let data = isComparison ?
			[
				{
					type,
					name: date1,
					showInLegend: true,
					color,
					dataPoints
				},
				{
					type,
					name: date2,
					showInLegend: true,
					color: colorComparison,
					dataPoints: dataPointsComparison
				}
			]
			:
			[{ type, dataPoints }]

		let axis = {
			labelFontSize: 12,
			labelFontWeight: "lighter",
			lineThickness: 1,
			gridColor: 'grey'
		}

		let options = {
			exportEnabled: true,
			animationEnabled: true,
			height: 600,
			legend: {
				fontSize: 14,
				fontWeight: "normal",
				verticalAlign: "top"
			},
			axisY: { ...axis, gridThickness: 0.2 },
			axisX: { ...axis },
			data
		}

		return options
	}, [isComparison, sheetData, showDataType, date1, date2])

	let optionsPie = useMemo(() => {
		let dataPoints: DataPoints[] = []
		let dataPointsComparison: DataPoints[] = []
		let colors: string[] = []
		if (sheetData.length > 0) {
			sheetData.forEach(_ => {
				if (_.selectedFields === 'Total') return
				let color = randomRGBAColor(colors) || ''
				if (color) colors.push(color)
			})

			sheetData.forEach((data: SheetRowAnalytics, i: number) => {
				let label = data.selectedFields
				if (label === 'Total') return
				let y = data?.totalNum || 0
				let yComparison = data?.totalNumComparison || 0

				dataPoints.push({ label, y, color: colors[i] })
				if (isComparison) dataPointsComparison.push({ label, y: yComparison, color: colors[i] })
			})
		}

		let optionsPie = {
			exportEnabled: isComparison ? false : true,
			animationEnabled: true,
			height: 600,
			data: [{
				type: "pie",
				startAngle: 0,
				toolTipContent: "<b>{label}</b>: {y}",
				legendText: "{label}",
				indexLabelFontSize: 16,
				indexLabel: "{label} - {y}",
				dataPoints
			}]
		}

		let optionsPieComparison = {
			exportEnabled: false,
			animationEnabled: true,
			height: 600,
			data: [{
				type: "pie",
				startAngle: 0,
				toolTipContent: "<b>{label}</b>: {y}",
				legendText: "{label}",
				indexLabelFontSize: 16,
				indexLabel: "{label} - {y}",
				dataPoints: dataPointsComparison
			}]
		}

		let options = isComparison ? [optionsPie, optionsPieComparison] : [optionsPie]
		return options
	}, [sheetData, isComparison])

	if (showDataType.startsWith('Bar'))
		return <CanvasJSChart options={opstionsBar} />

	return (
		<div style={{ width: '100%' }}>
			{isComparison &&
				<div className={s.dates}>
					<p className={s.date}>{date1}</p>
					<p className={s.date}>{date2}</p>
				</div>
			}
			<div className={s.pieCharts}>
				<div style={{ width: isComparison ? '50%' : '100%' }}>
					<CanvasJSChart options={optionsPie[0]} />
				</div>
				{isComparison &&
					<div style={{ width: '50%' }}>
						<CanvasJSChart options={optionsPie[1]} />
					</div>
				}
			</div>
			<div className={s.containerlegend}>
				{optionsPie && optionsPie[0]?.data[0]?.dataPoints?.map(data => (
					<div>
						<div className={s.circle} style={{ backgroundColor: data.color }} />
						<p>{data.label}</p>
					</div>
				))}
			</div>
		</div>
	)
}

export default Canvas
