import AnalyticsDbSearchOptions from '../reusable/DbSearchOptions/AnalyticsDbSearchOptions'
import s from './AnalyticsPage.module.scss'
import AnalyticsTable from './AnalyticsPage/AnalyticsTable'

function AnalyticsPage() {
    return (
        <div className={s.wrap}>
            <h1>Detailed Analytics</h1>
            <AnalyticsDbSearchOptions />
            <AnalyticsTable />
        </div>
    )
}

export default AnalyticsPage
