import addDays from 'date-fns/addDays'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Dropdown } from 'semantic-ui-react'
import { PurchasePageState } from '../../../../../redux/purchase-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'
import s from './EndDatePicker.module.scss'

export type EndDatePickerProps = {
    toDate: PurchasePageState["toDate"],
    endYear: PurchasePageState["endYear"],
    quikChoice: PurchasePageState["quikChoice"],
    initLoading: PurchasePageState["initLoading"],
    yearChoices: PurchasePageState["yearChoices"],
    setEndYear: (yr: string) => RdxActionPayload,
    setToDate: (date: Date | string) => RdxActionPayload
}

const EndDatePicker = ({
    toDate,
    endYear,
    initLoading,
    yearChoices,
    quikChoice,
    setToDate,
    setEndYear
}: EndDatePickerProps) => {

    function resetEndDate() {
        setToDate("")
        setEndYear("")
    }

    function handleEndYear(event: React.SyntheticEvent) {
        let { currentTarget } = event
        if (currentTarget.tagName === 'DIV') {
            const yr = currentTarget.textContent
            if (yr?.length === 4) {
                setEndYear(yr)
                const currYear = new Date().getFullYear()
                if (currYear.toString() === yr) setToDate(new Date())
                else setToDate(new Date(`${yr}-01-01T00:00:00.000Z`))
            }
        } else resetEndDate()
    }

    return (
        <section style={{ width: "120px" }}>
            <h3>End Date</h3>
            <div style={{ width: '100px' }}>
                <Dropdown
                    placeholder='Select year'
                    selection
                    options={yearChoices}
                    onChange={handleEndYear}
                    selectOnBlur={false}
                    clearable
                    loading={initLoading}
                    disabled={initLoading || (quikChoice !== undefined && quikChoice.length > 0)}
                    value={endYear}
                    fluid
                />
            </div>
            <ReactDatePicker
                className={!endYear || quikChoice ? s.pickerInputDisabled : s.pickerInput}
                selected={toDate ? new Date(toDate) : null}
                onChange={(date: Date) => setToDate(date)}
                disabled={!endYear || quikChoice ? true : false}
                maxDate={parseInt(endYear) === 2024 ? addDays(new Date(parseInt(endYear), 0, 1), 365) : addDays(new Date(parseInt(endYear), 0, 1), 364)}
                minDate={new Date(parseInt(endYear), 0, 1)}
                placeholderText="select month and day"
                showTimeSelect
            />
            <div style={{ fontWeight: 'bold' }}>
                {
                    !quikChoice && toDate && new Date(toDate).toString().split('(')[0]
                }
            </div>
        </section>
    )
}

export default EndDatePicker
