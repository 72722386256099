import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL } from '../../../helpers/constants'
import { setDateRange_adDataUtil } from './adUtilActions'
import { SAGA_ACTIONS } from './interfaces'

function* handleInitAdUtil() {
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
    }

    try {
        const res: AxiosResponse = yield call(axios.get, (BASE_URL + '/data/marketing/adutil/init'), config)
        yield put(setDateRange_adDataUtil(res.data))
    } catch (e) {
        yield console.log(e)
    }
}


function* interceptInitState() {
    yield takeLatest(
        [SAGA_ACTIONS.adDataUtil_INIT_STATE],
        handleInitAdUtil
    )
}

export function* adUtilSagas() {
    yield all([call(interceptInitState)])
}
