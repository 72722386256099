import { connect } from 'react-redux'
import { PAGES, TABLES } from '../../helpers/constants'
import { PurchasePageState } from '../../redux/purchase-page/interfaces'
import { RootState } from '../../redux/reduxTypes'
import DbSearchOptions from '../reusable/DbSearchOptions/DbSearchOptions'
import LookupOptsAccordion from '../reusable/LookupOptsAccordion/LookupOptsAccordion'
import s from './TestPurchasePage.module.scss'
import TestPurchaseTable from "./TestPurchasePage/TestPurchaseTable"

type Props = { columns: PurchasePageState["columns"] }
function TestPurchasePage({ columns }: Props) {
    return (
        <div className={s.wrap}>
            <h1>Search Test Purchases</h1>
            <DbSearchOptions table={TABLES.TEST_PURCHASE_TBL} />
            <LookupOptsAccordion columns={columns} page={PAGES.TEST_PURCHASE} />
            <TestPurchaseTable columns={columns} />
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    const { columns } = state.testPurchasePage
    return { columns }
}

export default connect(mapStateToProps)(TestPurchasePage)
