import { useCallback } from 'react'
import { AnalyticsTableState } from '../../../redux/analytics-table/interfaces'
import Canvas from './Canvas'
import s from './ChartAnalytics.module.scss'

type Props = {
    sheetData: AnalyticsTableState["sheetData"],
    dataLoading: AnalyticsTableState["dataLoading"],
    showDataType: string,
    date1: string,
    date2: string
    Title: any
}

const ChartAnalytics = ({
    sheetData,
    dataLoading,
    showDataType,
    date1,
    date2,
    Title
}: Props) => {

    let isComparison = useCallback((): boolean => {
        if (sheetData[sheetData.length - 1]?.totalNumComparison) return true
        else return false
    }, [sheetData])

    const Count = () => {
        let count1 = sheetData.length > 0 ? sheetData[sheetData.length - 1]?.totalNum : 0
        let count2 = sheetData.length > 0 ? sheetData[sheetData.length - 1]?.totalNumComparison : 0
        return (
            <div className={s.count}>
                <h5>Total count: </h5>
                {isComparison() ?
                    <p>{`${date1}: ${count1} / ${date2}: ${count2}`}</p>
                    :
                    <p>{count1}</p>
                }
            </div>
        )
    }

    if (!sheetData || sheetData.length === 0)
        return <h3 style={{ textAlign: 'center' }}>No data found</h3>

    return (
        <div style={{ width: '100%' }}>
            <Title />
            <Count />
            <div className={s.canvas}>
                <Canvas
                    sheetData={sheetData}
                    showDataType={showDataType}
                    isComparison={isComparison()}
                    date1={date1}
                    date2={date2}
                />
            </div>
        </div>
    )
}

export default ChartAnalytics
