import axios, { AxiosResponse } from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { BASE_URL, toastWarningConfig } from '../../helpers/constants'
import { setToast } from '../admin-state/adminActions'
import { Purchase, TestPurchQueryParams, UnformattedSheetRow } from '../purchase-table/interfaces'
import { selectUpdateTestTableProps } from '../test-purchase-page/testPurchasePageSelectors'
import { ACTIONS } from './interfaces'
import { setDataLoadingTestTbl, setSheetDataTestTbl } from './testPurchaseTableActions'

function* handleUpdateTestTable() {
    yield put(setDataLoadingTestTbl(true))
    try {
        const data: TestPurchQueryParams = yield select(selectUpdateTestTableProps);
        const url = BASE_URL + '/data/querypurchases';
        const res: AxiosResponse = yield call(axios.post, url, data);

        if (res) {
            if (res.data.purchases.length > 0) {
                const sheetData: UnformattedSheetRow[] = res.data.purchases.map((p: Purchase) => ({ id: p._id, ...p }));
                yield put(setSheetDataTestTbl(sheetData));
            } else yield put(setToast({
                msg: "No purchases found.",
                config: toastWarningConfig
            }))
        }

    } catch (e: any) {
        yield console.log(e)
        yield put(setToast({
            msg: e.message,
            config: toastWarningConfig
        }))
    }
    yield put(setDataLoadingTestTbl(false))
}

function* interceptGetTestSheetData() {
    yield takeLatest(
        [ACTIONS.TEST_GET_SHEET_DATA],
        handleUpdateTestTable
    )
}

export function* testPurchaseTableSagas() {
    yield all([
        call(interceptGetTestSheetData),
    ])
}
