import s from './WelcomePage.module.scss'

const WelcomePage = () => {
    return (
        <div className={s.wrap}>
            <h1>Welcome</h1>
            <h3>Click a button on the left to get started</h3>
        </div>
    )
}

export default WelcomePage
