import { createSelector } from 'reselect'
import { RootState } from '../reduxTypes'

const analyticsPage = (rootState: RootState) => rootState.analyticsPage

export const selectQuickChoice = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.quikChoice
)

export const selectVersions = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.appVersions
)

export const selectFromDate = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.fromDate
)

export const selectToDate = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.toDate
)

export const selectChosenApps = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.chosenApps
)

export const selectStartYear = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.startYear
)

export const selectEndYear = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.endYear
)

export const selectTables = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.chosenTable
)

export const selectTableDistinctFields = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.chosenTableDistinctFields
)

export const selectGetDataLoading = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.getDataLoading
)

export const selectVersionChoices = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.appVersionChoices
)

export const selectInitLoading = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.initLoading
)

export const selectYearChoices = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.yearChoices
)

export const selectAppChoices = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.appChoices
)

export const selectBrandChoices = createSelector(
    [analyticsPage],
    analyticsPage => analyticsPage.tableDropdownChoices
)

export const selectAllChosenApps = createSelector(
    [analyticsPage],
    (analyticsPage) => {
        return {
            realChosenApps: analyticsPage.chosenApps,
        }
    }
)

export const selectAllAppChoices = createSelector(
    [analyticsPage],
    (analyticsPage) => {
        return {
            realAppChoices: analyticsPage.appChoices,
        }
    }
)

export const selectSelectedOptions = createSelector(
    [analyticsPage],
    (analyticsPage) => {
        return {
            chosenApps: analyticsPage.chosenApps,
            chosenTable: analyticsPage.chosenTable,
            chosenTableFields: analyticsPage.chosenTableFields,
        }
    }
)

export const selectDates = createSelector(
    [analyticsPage],
    (analyticsPage) => {
        return {
            endYear: analyticsPage.endYear,
            startYear: analyticsPage.startYear,
            toDate: analyticsPage.toDate,
            fromDate: analyticsPage.fromDate,
            endYearSecond: analyticsPage.endYearSecond,
            startYearSecond: analyticsPage.startYearSecond,
            toDateSecond: analyticsPage.toDateSecond,
            fromDateSecond: analyticsPage.fromDateSecond
        }
    }
)

export const selectUpdateAnalyticsTblProps = (rootState: RootState) => {
    const {
        endYear,
        startYear,
        toDate,
        fromDate,
        chosenApps,
        appVersions,
        chosenTable,
        chosenTableFields,
        endYearSecond,
        startYearSecond,
        toDateSecond,
        fromDateSecond
    } = rootState.analyticsPage

    return {
        endYear,
        startYear,
        toDate,
        fromDate,
        chosenApps,
        appVersions,
        chosenTable,
        chosenTableFields,
        endYearSecond,
        startYearSecond,
        toDateSecond,
        fromDateSecond
    }
}
