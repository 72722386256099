import { Route, Switch } from 'react-router'
import s from './MainSection.module.scss'
import AnalyticsPage from './pages/AnalyticsPage'
import DataUtilPage from './pages/DataUtilPage'
import PurchasePage from './pages/PurchasePage'
import SalesPage from './pages/SalesPage'
import TestPurchasePage from './pages/TestPurchasePage'
import WelcomePage from './pages/WelcomePage'

const MainSection = () => {
    return (
        <div className={s.wrap} style={{ paddingBottom: 100 }}>
            <Switch>
                <Route exact path="/home" component={WelcomePage} />
                <Route path="/home/purchases" component={PurchasePage} />
                <Route path="/home/analytics" component={AnalyticsPage} />
                <Route path="/home/testpurchases" component={TestPurchasePage} />
                <Route path="/home/datautils" component={DataUtilPage} />
                <Route path="/home/sales" component={SalesPage}/>
            </Switch>
        </div>
    )
}

export default MainSection
