import { Dropdown, DropdownMenuProps } from 'semantic-ui-react'
import { AnalyticsPageState } from '../../../../../redux/analytics-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'
import s from './TableSelector.module.scss'

type Props = {
    tableDropdownChoices: AnalyticsPageState["tableDropdownChoices"],
    initLoading: AnalyticsPageState["initLoading"],
    chosenTable: AnalyticsPageState["chosenTable"],
    setTable: (data: string) => RdxActionPayload,
    setManyTableFields: (data: string) => RdxActionPayload
}

const TableSelector = ({
    tableDropdownChoices,
    initLoading,
    chosenTable,
    setTable,
    setManyTableFields
}: Props) => {

    return (
        <div className={s.wrap}>
            <Dropdown
                placeholder='select table'
                selection
                options={tableDropdownChoices}
                onChange={(e, data: DropdownMenuProps) => {
                    data.value && setTable(data.value)
                    setManyTableFields('')
                }}
                selectOnBlur={false}
                loading={initLoading}
                disabled={initLoading}
                value={chosenTable}
                fluid
            />
        </div>
    )
}

export default TableSelector
