import { Dropdown, DropdownMenuProps } from 'semantic-ui-react'
import { getNameApps } from '../../../../../helpers/utils'
import { PurchasePageState } from '../../../../../redux/purchase-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'
import s from './AppSelector.module.scss'

type Props = {
    setChosenApps: (data: string[]) => RdxActionPayload,
    appChoices: PurchasePageState["appChoices"],
    initLoading: PurchasePageState["initLoading"],
    chosenApps: PurchasePageState["chosenApps"]
}

const AppSelector = ({
    setChosenApps,
    appChoices,
    initLoading,
    chosenApps,
}: Props) => {

    return (
        <div className={s.wrap}>
            <Dropdown
                placeholder='Select app'
                selection
                options={getNameApps(appChoices)}
                onChange={(e, data: DropdownMenuProps) => data.value && setChosenApps(data.value)}
                selectOnBlur={false}
                multiple
                loading={initLoading}
                disabled={initLoading}
                value={chosenApps || []}
                fluid
            />
        </div>
    )
}

export default AppSelector
