import addDays from 'date-fns/addDays'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Dropdown } from 'semantic-ui-react'
import { PurchasePageState } from '../../../../../redux/purchase-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'
import s from './StartDatePicker.module.scss'

export type StartDatePickerProps = {
    fromDate: PurchasePageState["fromDate"],
    startYear: PurchasePageState["startYear"],
    quikChoice: PurchasePageState["quikChoice"],
    initLoading: PurchasePageState["initLoading"],
    yearChoices: PurchasePageState["yearChoices"],
    setFromDate: (date: Date | string) => RdxActionPayload,
    setStartYear: (yr: string) => RdxActionPayload
}

const StartDatePicker = ({
    fromDate,
    startYear,
    initLoading,
    yearChoices,
    quikChoice,
    setFromDate,
    setStartYear
}: StartDatePickerProps) => {

    function resetStartDate() {
        setFromDate("")
        setStartYear("")
    }

    function handleStartYear(event: React.SyntheticEvent) {
        let { currentTarget } = event
        if (currentTarget.tagName === 'DIV') {
            const yr = currentTarget.textContent
            if (yr?.length === 4) {
                setFromDate(new Date(`${yr}-01-01T00:00:00.000Z`))
                setStartYear(yr)
            }
        } else resetStartDate()
    }

    return (
        <section style={{ width: "120px" }}>
            <h3>Start Date</h3>
            <div style={{ width: '100px' }}>
                <Dropdown
                    placeholder='Select year'
                    selection
                    options={yearChoices}
                    onChange={handleStartYear}
                    selectOnBlur={false}
                    clearable
                    loading={initLoading}
                    disabled={initLoading || (quikChoice !== undefined && quikChoice.length > 0)}
                    value={startYear}
                    fluid
                />
            </div>
            <ReactDatePicker
                className={!startYear || quikChoice ? s.pickerInputDisabled : s.pickerInput}
                selected={fromDate ? new Date(fromDate) : null}
                onChange={(date: Date) => setFromDate(date)}
                disabled={!startYear || quikChoice ? true : false}
                maxDate={parseInt(startYear) === 2024 ? addDays(new Date(parseInt(startYear), 0, 1), 365) : addDays(new Date(parseInt(startYear), 0, 1), 364)}
                minDate={new Date(parseInt(startYear), 0, 1)}
                placeholderText="select month and day"
                showTimeSelect
            />
            <div style={{ fontWeight: 'bold' }}>
                {!quikChoice && fromDate && new Date(fromDate).toString().split('(')[0]}
            </div>
        </section>
    )
}

export default StartDatePicker
