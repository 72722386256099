import { useState } from 'react'
import { Accordion, Button, Icon, Loader } from 'semantic-ui-react';
import DbSkuRecordsTable, { SkuDbRecord } from '../../reusable/DbSkuRecordsTable';
import s from './CurrentSkuRecords.module.scss'
import SkuSelection from './CurrentSkuRecords/SkuSelection';
import SkuTblViewSwitch from './CurrentSkuRecords/SkuTblViewSwitch';
import { useSalesContext } from './SalesPageContextProvider';

type SkuAccordionProps = {
    index: number
    date: string
    records: SkuDbRecord[]
}

// the "key" here will be the date in the format MM-YYYY
export type SkuDbRecordsByDate = {
    [key: string]: SkuDbRecord[]
}

const CurrentSkuRecords = () => {
    const { dbSkuRecordsByDate, getSkuRecordsLoading, deleteDbSkuDateRecords } = useSalesContext()!;
    const [activeIndex, setActiveIndex] = useState(-1);
    const [viewMode, setViewMode] = useState<"dropdown" | "full">("dropdown");
    const dates = Object.keys(dbSkuRecordsByDate);


    const SkuAccordion = ({index, date, records}: SkuAccordionProps) => {
        const [month, year] = date.split('-');
        return (
            <>
                <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    className={s.accordionTitle}
                >
                    <div onClick={() => setActiveIndex(index === activeIndex ? -1 : index)} >
                        <Icon name="dropdown" />
                        {date + " sku data"}
                    </div>
                    <Button size="tiny" color="red" onClick={() => deleteDbSkuDateRecords(parseInt(month), parseInt(year))}>Delete In DB</Button>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                    <SkuTblViewSwitch viewMode={viewMode} setViewMode={setViewMode}/>
                    {
                        viewMode === "full" ? (
                            <DbSkuRecordsTable records={records}/>
                        ) : (
                            <SkuSelection skuRecords={records}/>
                        )
                    }
                </Accordion.Content>
            </>
        )
    }
    
    return (
        <div className={s.wrap}>
            {
                getSkuRecordsLoading ? (
                    <Loader active inline/>
                ) : (
                    <>
                        <div className={s.topSection}>
                            <h3>Saved SKU Data From DB</h3>
                        </div>
                        <Accordion styled className={s.accordion} >
                        {
                            dates.map((date, idx) => (
                                <SkuAccordion key={idx} index={idx} date={date} records={dbSkuRecordsByDate[date]}/>
                            ))
                        }
                        </Accordion>
                    </>
                    
                )
            }
            
            
        
        </div>
    );
};

export default CurrentSkuRecords;