import { ACTIONS, AdminActionPayload, AdminState, ADMIN_INITIAL_STATE, PersistPayload, Toast } from './interfaces'

export const adminReducer = (
    state: AdminState = ADMIN_INITIAL_STATE,
    action: AdminActionPayload
): AdminState => {

    switch (action.type) {
        case ACTIONS.SET_AUTHED:
            return {
                ...state,
                authed: (action.payload as boolean)
            }
        case ACTIONS['persist/REHYDRATE']:
            return {
                ...state,
                authed: action.payload ? (action.payload as PersistPayload).admin.authed : state.authed
            }
        case ACTIONS.SET_TOAST:
            return {
                ...state,
                toast: (action.payload as Toast)
            }
        default:
            return {
                ...state
            }
    }
}
