import React, { Dispatch, SetStateAction } from 'react'
import { Checkbox, Form } from 'semantic-ui-react';
type Props = {
    viewMode: "dropdown" | "full"
    setViewMode: Dispatch<SetStateAction<"dropdown" | "full">>
}



const SkuTblViewSwitch = ({ viewMode, setViewMode } : Props) => {

    return (
        <Form>
            <Form.Field>
                <Checkbox
                    radio
                    label="dropdown mode"
                    checked={viewMode === "dropdown"}
                    onChange={(e, data) => setViewMode("dropdown")}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    radio
                    label="full table mode"
                    checked={viewMode === "full"}
                    onChange={(e, data) => setViewMode("full")}
                />
            </Form.Field>
            
        </Form>
    );
};

export default SkuTblViewSwitch;