import { createSelector } from 'reselect'
import { RootState } from '../reduxTypes'

const analyticsTable = (rootState: RootState) => rootState.analyticsTable

export const selectDataLoadingAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.dataLoading
)

export const selectSheetDataAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.sheetData
)

export const selectTotalRowsAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.totalRows
)

export const selectRowsPerPageAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.rowsPerPage
)

export const selectCurrPageAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.currPage
)

export const selectCurrFiltersAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.currFilters
)

export const selectSearchByFilterAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.searchByFilter
)

export const selectHasNextAnalyticsTbl = createSelector(
    [analyticsTable],
    analyticsTable => analyticsTable.hasNext
)
