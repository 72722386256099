import React, { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Dropdown } from "semantic-ui-react"
import { quickChoices } from "../../../../helpers/constants"
import { getQuickDates } from "../../../../helpers/utils"
import { setEndYear_adDataUtil, setFromDate_adDataUtil, setStartYear_adDataUtil, setToDate_adDataUtil } from "../../../../redux/data-utils/ad-util/adUtilActions"
import { selectChosenQuery_adDataUtil, selectDateRange_adDataUtil, selectEndYear_adDataUtil, selectFromDate_adDataUtil, selectStartYear_adDataUtil, selectToDate_adDataUtil, selectYearChoices_adDataUtil } from "../../../../redux/data-utils/ad-util/adUtilSelectors"
import { AdUtilState } from '../../../../redux/data-utils/ad-util/interfaces'
import { PurchasePageState } from "../../../../redux/purchase-page/interfaces"
import { selectInitLoading } from "../../../../redux/purchase-page/purchasePageSelectors"
import { RdxActionPayload, RootState } from "../../../../redux/reduxTypes"
import s from "./AdDataPicker.module.scss"

type Props = {
    fromDate: string,
    toDate: string,
    startYear: AdUtilState["startYear"],
    endYear: AdUtilState["endYear"],
    initLoading: PurchasePageState["initLoading"],
    yearChoices: AdUtilState["yearChoices"],
    chosenQuery: AdUtilState["chosenQuery"],
    dateRange: AdUtilState["dateRange"],
    setFromDate: (data: Date | string) => RdxActionPayload,
    setToDate: (data: Date | string) => RdxActionPayload,
    setStartYear: (data: string) => RdxActionPayload,
    setEndYear: (data: string) => RdxActionPayload
}

function AdDataPicker({
    fromDate,
    toDate,
    startYear,
    endYear,
    initLoading,
    yearChoices,
    chosenQuery,
    dateRange,
    setFromDate,
    setToDate,
    setStartYear,
    setEndYear
}: Props) {

    const { dayBefore, seven, thirty } = getQuickDates()
    const [customChoiceActive, setCustomChoiceActive] = useState<boolean>(false)
    const rangeStart = new Date(dateRange.earliest)
    const rangeEnd = new Date(dateRange.mostRecent)

    function resetStartDate() {
        setFromDate("")
        setStartYear("")
    }

    function resetEndDate() {
        setToDate("")
        setEndYear("")
    }

    function handleStartYear(event: React.SyntheticEvent) {
        let { currentTarget } = event
        if (currentTarget.tagName === "DIV") {
            const yr = currentTarget.textContent
            if (yr?.length === 4) {
                setFromDate(new Date(`${yr}-01-01T00:00:00.000Z`))
                setStartYear(yr)
            }
        } else resetStartDate()
    }

    function handleEndYear(event: React.SyntheticEvent) {
        let { currentTarget } = event
        if (currentTarget.tagName === "DIV") {
            const yr = currentTarget.textContent
            if (yr?.length === 4) {
                setEndYear(yr);
                setToDate(new Date(dateRange.mostRecent))
            }
        } else resetEndDate()
    }

    function handleQuickChoice(event: React.SyntheticEvent) {
        let { currentTarget } = event;
        if (currentTarget.textContent) {
            setCustomChoiceActive(true);
        } else {
            setCustomChoiceActive(false);
        }
        switch (currentTarget.textContent) {
            case "Yesterday":
                setFromDate(dayBefore[0]);
                setToDate(dayBefore[1]);
                break;
            case "Last 30 days":
                setFromDate(thirty[0]);
                setToDate(thirty[1]);
                break;
            case "Last 7 days":
                setFromDate(seven[0]);
                setToDate(seven[1]);
                break;
            default:
                setFromDate("");
                setToDate("");
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.innerWrap}>
                <section>
                    <h3>Choose Start Date</h3>
                    <Dropdown
                        placeholder="Select year"
                        selection
                        options={yearChoices}
                        onChange={handleStartYear}
                        selectOnBlur={false}
                        clearable
                        loading={rangeStart ? false : true}
                        disabled={
                            initLoading || customChoiceActive || !chosenQuery
                        }
                        value={startYear}
                    />

                    <DatePicker
                        className={
                            !startYear || customChoiceActive
                                ? s.pickerInputDisabled
                                : s.pickerInput
                        }
                        selected={fromDate ? new Date(fromDate) : null}
                        onChange={(date: Date) => setFromDate(date)}
                        disabled={
                            !startYear || customChoiceActive || !chosenQuery
                                ? true
                                : false
                        }
                        maxDate={rangeEnd}
                        minDate={rangeStart}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        placeholderText="select month and day"
                        showTimeSelect
                    />


                    <div style={{ fontWeight: "bold" }}>
                        {!customChoiceActive &&
                            fromDate &&
                            new Date(fromDate).toString().split("(")[0]}
                    </div>
                </section>

                <section>
                    <h3>Choose End Date</h3>
                    <Dropdown
                        placeholder="Select year"
                        selection
                        options={yearChoices}
                        onChange={handleEndYear}
                        selectOnBlur={false}
                        clearable
                        loading={rangeEnd ? false : true}
                        disabled={
                            initLoading || customChoiceActive || !chosenQuery
                        }
                        value={endYear}
                    />
                    <DatePicker
                        className={
                            !endYear || customChoiceActive
                                ? s.pickerInputDisabled
                                : s.pickerInput
                        }
                        selected={toDate ? new Date(toDate) : null}
                        onChange={(date: Date) => setToDate(date)}
                        disabled={
                            !startYear || customChoiceActive || !chosenQuery
                                ? true
                                : false
                        }
                        maxDate={rangeEnd}
                        minDate={rangeStart}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        placeholderText="select month and day"
                        showTimeSelect
                    />
                    <div style={{ fontWeight: "bold" }}>
                        {!customChoiceActive &&
                            toDate &&
                            new Date(toDate).toString().split("(")[0]}
                    </div>
                </section>

                <section>
                    <h3>Quick choices:</h3>
                    <Dropdown
                        placeholder="quick choice"
                        selection
                        options={quickChoices}
                        onChange={handleQuickChoice}
                        selectOnBlur={false}
                        clearable
                        loading={initLoading}
                        disabled={
                            startYear || endYear || !chosenQuery ? true : false
                        }
                    />
                    <div style={{ fontWeight: "bold" }}>
                        <p>
                            {customChoiceActive &&
                                fromDate &&
                                fromDate &&
                                new Date(fromDate).toString().split("(")[0]}
                        </p>
                        <p>
                            {customChoiceActive && fromDate && toDate && "TO"}
                        </p>
                        <p>
                            {customChoiceActive &&
                                toDate &&
                                toDate &&
                                new Date(toDate).toString().split("(")[0]}
                        </p>
                    </div>
                </section>
            </div>
        </div>
    )
}

type SelectorProps = Pick<Props, "fromDate" | "toDate" | "startYear" | "endYear" | "yearChoices" | "chosenQuery" | "dateRange" | "initLoading">
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    toDate: selectToDate_adDataUtil,
    fromDate: selectFromDate_adDataUtil,
    startYear: selectStartYear_adDataUtil,
    endYear: selectEndYear_adDataUtil,
    initLoading: selectInitLoading,
    yearChoices: selectYearChoices_adDataUtil,
    chosenQuery: selectChosenQuery_adDataUtil,
    dateRange: selectDateRange_adDataUtil
})

const mapDispatch = {
    setFromDate: (date: Date | string) => setFromDate_adDataUtil(date),
    setToDate: (date: Date | string) => setToDate_adDataUtil(date),
    setStartYear: (yr: string) => setStartYear_adDataUtil(yr),
    setEndYear: (yr: string) => setEndYear_adDataUtil(yr)
}

export default connect(mapStateToProps, mapDispatch)(AdDataPicker)
