import { AnalyticsPageState } from '../analytics-page/interfaces'

export interface AnalyticsTableState {
    sheetData: SheetRowAnalytics[],
    dataLoading?: boolean,
    totalRows: number | undefined,
    rowsPerPage?: number,
    currPage?: number | undefined,
    searchByFilter?: boolean,
    hasNext: boolean,
}

export const ANALYTICS_TBL_INITIAL_STATE = {
    sheetData: [],
    dataLoading: false,
    totalRows: undefined,
    rowsPerPage: 20,
    currPage: 0,
    currFilters: [],
    searchByFilter: true,
    hasNext: false,
}

export const ACTIONS = {
    SET_SHEET_DATA_ANALYTICS: "SET_SHEET_DATA_ANALYTICS",
    SET_DATA_LOADING_ANALYTICS: "SET_DATA_LOADING_ANALYTICS",
    ADD_LOOKUP_OPTS_ANALYTICS: "ADD_LOOKUP_OPTS_ANALYTICS",
    SET_SEARCH_BY_FILTER_ANALYTICS: "SET_SEARCH_BY_FILTER_ANALYTICS",
    SET_ROWS_PER_PAGE_ANALYTICS: "SET_ROWS_PER_PAGE_ANALYTICS",
    SET_CURR_PAGE_ANALYTICS: "SET_CURR_PAGE_ANALYTICS",
    UPDATE_SHEET_DATA_ANALYTICS: "UPDATE_SHEET_DATA_ANALYTICS",
    GET_SHEET_DATA_ANALYTICS: "GET_SHEET_DATA_ANALYTICS"
}

export const SAGA_ACTIONS = {
    SET_CURR_FILTERS_ANALYTICS: "SET_CURR_FILTERS_ANALYTICS",
    SET_NEW_SHEET_DATA_ANALYTICS: "SET_NEW_SHEET_DATA_ANALYTICS",
    HANDLE_PAGE_CHANGE_ANALYTICS: "HANDLE_PAGE_CHANGE_ANALYTICS"
}

export const DUAL_ACTIONS = {
    SET_TOTAL_ROWS_ANALYTICS: "SET_TOTAL_ROWS_ANALYTICS",
    CLEAR_TABLE_ANALYTICS: "CLEAR_TABLE_ANALYTICS"
}

export interface SheetRowAnalytics {
    selectedFields: string,
    totalNum: number | undefined | null,
    totalNumComparison?: number | undefined | null,
}

export interface TableColumnAnalytics {
    width?: number | null,
    maxWidth?: number,
    field: string,
    title: string,
    searchable?: boolean,
    hiddenByColumnsButton?: boolean,
    removable?: boolean,
    cellStyle?: any,
    type?: string,
    filterPlaceholder?: string,
    lookup?: string[],
    hidden?: boolean,
    filterCellStyle?: any,
    align?: string,
    isAddedColumn: boolean,
    fromRawData?: boolean,
    fromUser?: boolean,
    fromPurchase?: boolean,
    dbType: string,
    headerStyle?: any,
    tableData?: any,
    customFilterAndSearch?: (term: string, rowData: SheetRowAnalytics) => boolean
}

export type QueryParams = {
    endYear: AnalyticsPageState["endYear"],
    startYear: AnalyticsPageState["startYear"],
    toDate: AnalyticsPageState["toDate"],
    fromDate: AnalyticsPageState["fromDate"],
    endYearSecond: AnalyticsPageState["endYearSecond"],
    startYearSecond: AnalyticsPageState["startYearSecond"],
    toDateSecond: AnalyticsPageState["toDateSecond"],
    fromDateSecond: AnalyticsPageState["fromDateSecond"],
    chosenApps: AnalyticsPageState["chosenApps"],
    appVersions: AnalyticsPageState["appVersions"],
    chosenTable: AnalyticsPageState["chosenTable"],
    chosenTableFields: AnalyticsPageState["chosenTableFields"]
    getTests?: boolean
}

export type PaginationData = {
    rowsPerPage: AnalyticsTableState["rowsPerPage"],
    currPage: AnalyticsTableState["currPage"]
}
