import { to } from 'await-to-js'
import axios from 'axios'
import React, { SyntheticEvent, useState } from 'react'
import { Button, Input, Segment } from 'semantic-ui-react'
import { BASE_URL } from '../../../helpers/constants'
import s from './ValidateUtil.module.scss'

const ValidateUtil: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [valid, setValid] = useState<boolean>(false)
    const [received, setReceived] = useState<boolean>(false)
    const [userId, setUserId] = useState<string>("")
    const [receiptId, setReceiptId] = useState<string>("")
    const [appName, setAppName] = useState<string>("")
    const [apiMsg, setApiMsg] = useState<string>("")

    async function handleClick(e: SyntheticEvent) {
        setLoading(true);
        const url = BASE_URL + '/util/validate'
        const data = { userId, receiptId, appName }
        const [err, res] = await to(axios.post(url, data))
        if (err) { }
        setLoading(false)
        setReceived(true)
        if (res) {
            if (res.data) {
                const { isValid, amazonData } = res.data
                setValid(isValid)
                if (!isValid) {
                    const parsed = JSON.parse(amazonData)
                    setApiMsg(parsed.message)
                } else setApiMsg("purchase is valid")
            }
        }
    }

    function handleClear(e: SyntheticEvent) {
        setUserId("")
        setReceiptId("")
        setReceived(false)
        setApiMsg("")
    }

    return (
        <div className={s.wrap}>
            <h2>Check Validation Status</h2>
            <div className={s.inputs}>
                <Input
                    placeholder="amazonUserId field"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                />
                <Input
                    placeholder="receiptId field"
                    onChange={(e) => setReceiptId(e.target.value)}
                    value={receiptId}
                />
                <Input
                    placeholder="appName field"
                    onChange={(e) => setAppName(e.target.value)}
                    value={appName}
                />
            </div>

            <div className={s.checkResult}>
                {
                    received ?
                        <Segment color={valid ? "green" : "red"}>
                            {apiMsg}
                        </Segment>
                        :
                        <Segment color="grey">
                            No response yet
                        </Segment>
                }
            </div>

            <div className={s.btn}>
                <Button
                    color="yellow"
                    onClick={handleClick}
                    loading={loading}
                    disabled={!userId || !appName || !receiptId}
                >
                    Check Validation
                </Button>
                <Button
                    color="blue"
                    onClick={handleClear}
                >
                    Reset
                </Button>
            </div>
        </div>
    )
}

export default ValidateUtil
