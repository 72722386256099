import React from 'react'
import s from './DataUtilPage.module.scss'
import AdDataUtil from './DataUtilPage/AdDataUtil'

const DataUtilPage: React.FC = () => {
    return (
        <div className={s.wrap}>
            <AdDataUtil />
        </div>
    )
}

export default DataUtilPage
