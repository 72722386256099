import { formatDBSheetRow } from '../../helpers/utils'
import { NewDataDbResponse } from '../../ts/generic/interfaces'
import { ACTIONS, DUAL_ACTIONS, PurchaseTableRow, SAGA_ACTIONS, TableFilter, UnformattedSheetRow } from './interfaces'

export function setSheetDataPurchTbl(data: UnformattedSheetRow[]) {
    const newSheetData: PurchaseTableRow[] = formatDBSheetRow(data)
    return {
        type: ACTIONS.SET_SHEET_DATA,
        payload: newSheetData
    }
}

export function setDataLoadingPurchTbl(bool: boolean) {
    return {
        type: ACTIONS.SET_DATA_LOADING,
        payload: bool
    }
}

export function setRowsPerPagePurchTbl(n: number) {
    return {
        type: ACTIONS.SET_ROWS_PER_PAGE,
        payload: n
    }
}

export function resetLastReceivedDatePurchTbl() {
    return {
        type: ACTIONS.RESET_LAST_RECEIVED_DATE,
    }
}

export function setCurrPagePurchTbl(n: number) {
    return {
        type: ACTIONS.SET_CURR_PAGE,
        payload: n
    }
}

export function setGoingForwardPurchTbl(bool: boolean) {
    return {
        type: ACTIONS.SET_GOING_FORWARD,
        payload: bool
    }
}

export function updateSheetDataPurchTbl(filters: TableFilter[]) {
    return {
        type: ACTIONS.UPDATE_SHEET_DATA,
        payload: filters
    }
}

export function changePageWhileFilteringPurchTbl() {
    return {
        type: ACTIONS.CHANGE_PAGE_WHILE_FILTERING,
    }
}

export function setSearchByFilterPurchTbl(bool: boolean) {
    return {
        type: ACTIONS.SET_SEARCH_BY_FILTER,
        payload: bool
    }
}

export function getSheetData() {
    return {
        type: ACTIONS.GET_SHEET_DATA,
    }
}

export function setCurrFiltersPurchTbl(filters: TableFilter[]) {
    return {
        type: SAGA_ACTIONS.SET_CURR_FILTERS,
        payload: filters
    }
}

export function setNewSheetDataPurchTbl(dbData: NewDataDbResponse) {
    return {
        type: SAGA_ACTIONS.SET_NEW_SHEET_DATA,
        payload: {
            totalRows: dbData.totalRows,
            sheetData: formatDBSheetRow(dbData.sheetData),
            hasNext: dbData.hasNext,
            totalLTV: dbData.totalLTV
        }
    }
}

export function handleChangePagePurchTbl(dbData: NewDataDbResponse) {
    let _lastReceivedDate = undefined
    let sheetData = dbData.sheetData

    if (sheetData.length > 0) _lastReceivedDate = sheetData[sheetData.length - 1].date

    return {
        type: SAGA_ACTIONS.HANDLE_PAGE_CHANGE,
        payload: {
            totalRows: dbData.totalRows,
            sheetData: formatDBSheetRow(sheetData),
            lastReceivedDate: _lastReceivedDate,
            hasNext: dbData.hasNext,
            totalLTV:dbData.totalLTV
        }
    }
}

export function setTotalRowsPurchTbl(n: number) {
    return {
        type: DUAL_ACTIONS.SET_TOTAL_ROWS,
        payload: n
    }
}

export function clearPurchTable() {
    return {
        type: DUAL_ACTIONS.CLEAR_TABLE,
    }
}
