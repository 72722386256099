import { RdxActionPayload } from '../reduxTypes'
import { ACTIONS, AnalyticsPageState, ANALYTICS_PAGE_INITIAL_STATE, SAGA_ACTIONS } from './interfaces'

export const analyticsPageReducer = (
    state: AnalyticsPageState = ANALYTICS_PAGE_INITIAL_STATE,
    action: RdxActionPayload
) => {

    switch (action.type) {
        case ACTIONS.ANALYTICS_SET_QUIK_CHOICE:
            return {
                ...state,
                quikChoice: action.payload
            }
        case ACTIONS.ANALYTICS_SET_QUIK_CHOICE_SECOND:
            return {
                ...state,
                quikChoiceSecond: action.payload
            }
        case ACTIONS.ANALYTICS_SET_TABLE:
            return {
                ...state,
                chosenTable: action.payload
            }
        case ACTIONS.ANALYTICS_SET_TABLE_FIELDS:
            return {
                ...state,
                chosenTableFields: action.payload
            }
        case ACTIONS.ANALYTICS_SET_MANY_TABLE_FIELDS:
            return {
                ...state,
                chosenTableFields: action.payload
            }
        case ACTIONS.ANALYTICS_SET_TABLE_DISTINCT_FIELDS:
            return {
                ...state,
                chosenTableDistinctFields: action.payload
            }
        case ACTIONS.ANALYTICS_SET_APP_VERSIONS:
            return {
                ...state,
                appVersions: action.payload
            }
        case ACTIONS.ANALYTICS_SET_FROM_DATE:
            return {
                ...state,
                fromDate: action.payload
            }
        case ACTIONS.ANALYTICS_SET_FROM_DATE_SECOND:
            return {
                ...state,
                fromDateSecond: action.payload
            }
        case ACTIONS.ANALYTICS_SET_TO_DATE:
            return {
                ...state,
                toDate: action.payload
            }
        case ACTIONS.ANALYTICS_SET_TO_DATE_SECOND:
            return {
                ...state,
                toDateSecond: action.payload
            }
        case ACTIONS.ANALYTICS_SET_CHOSEN_APPS:
            return {
                ...state,
                chosenApps: action.payload,
                appVersions: state.appVersions.length ? state.appVersions.filter((v: string) => {
                    let name = v.substring(v.indexOf('(') + 1, v.length - 1)
                    return action.payload.includes(name)
                }) : state.appVersions
            }
        case ACTIONS.ANALYTICS_SET_START_YEAR:
            return {
                ...state,
                startYear: action.payload
            }
        case ACTIONS.ANALYTICS_SET_START_YEAR_SECOND:
            return {
                ...state,
                startYearSecond: action.payload
            }
        case ACTIONS.ANALYTICS_SET_END_YEAR:
            return {
                ...state,
                endYear: action.payload
            }
        case ACTIONS.ANALYTICS_SET_END_YEAR_SECOND:
            return {
                ...state,
                endYearSecond: action.payload
            }
        case ACTIONS.ANALYTICS_UPDATE_COLS:
            return {
                ...state,
            }
        case ACTIONS.ANALYTICS_SET_GET_DATA_LOADING:
            return {
                ...state,
                getDataLoading: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_APP_VERSION_CHOICES:
            return {
                ...state,
                appVersionChoices: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_INIT_LOADING:
            return {
                ...state,
                initLoading: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_YEAR_CHOICES:
            return {
                ...state,
                yearChoices: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_APP_CHOICES:
            return {
                ...state,
                appChoices: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_TABLE_CHOICES:
            return {
                ...state,
                tableDropdownChoices: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_TABLE_FIELD_CHOICES:
            return {
                ...state,
                tableFieldChoices: action.payload
            }
        case SAGA_ACTIONS.ANALYTICS_SET_TABLE_DISTINCT_FIELD_CHOICES:
            return {
                ...state,
                tableDistinctFieldChoices: action.payload
            }
        default:
            return { ...state }
    }
}
