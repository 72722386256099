import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import { persistor, store } from "./redux/store"

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <ToastProvider placement="top-center">
                    {store && <App />}
                </ToastProvider>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
)
