import MaterialTable, { Column } from 'material-table'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { adDataUtilColumns } from '../../../../helpers/constants'
import { camel2title } from '../../../../helpers/utils'
import { AdDataRow, AdUtilState } from '../../../../redux/data-utils/ad-util/interfaces'
import { TableColumn } from '../../../../redux/purchase-table/interfaces'
import { RootState } from '../../../../redux/reduxTypes'
import s from './AdDataDisplay.module.scss'

type Props = {
    data: AdDataRow[],
    chosenQuery: AdUtilState["chosenQuery"]
}

const AdDataDisplay = ({ data, chosenQuery }: Props) => {
    const fileName = `Marketing_Report_${chosenQuery}${new Date().toLocaleDateString('en-GB').replace(/\//g, '_')}`
    const [cols, setCols] = useState<any[]>(adDataUtilColumns)

    useEffect(() => {
        const newCols = adDataUtilColumns.map((col) => {
            if (col.field === "queryId") col.title = camel2title(chosenQuery)
            return col
        })
        setCols(newCols)
    }, [data, chosenQuery])

    return (
        <div className={s.wrap}>
            <MaterialTable
                title="Vungle App Data"
                columns={cols.map(c => ({ ...c })) as Column<TableColumn>[]}
                data={data ? data : [] as any}
                options={{
                    filtering: true,
                    tableLayout: "fixed",
                    columnsButton: true,
                    exportButton: { csv: true, pdf: false },
                    exportFileName: fileName,
                    exportAllData: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    search: true,
                }}
            />
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    const { data, chosenQuery } = state.dataUtils.adData
    return { data, chosenQuery }
}

export default connect(mapStateToProps)(AdDataDisplay)
