import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Dropdown, DropdownMenuProps } from 'semantic-ui-react'
import { setChosenQuery_adDataUtil } from '../../../../redux/data-utils/ad-util/adUtilActions'
import { selectChosenQuery_adDataUtil, selectQueryChoices_adDataUtil } from '../../../../redux/data-utils/ad-util/adUtilSelectors'
import { AdUtilState } from '../../../../redux/data-utils/ad-util/interfaces'
import { RdxActionPayload, RootState } from '../../../../redux/reduxTypes'
import s from './AdDataQueryDropdown.module.scss'

type Props = {
    queryChoices: AdUtilState["queryChoices"],
    chosenQuery: AdUtilState["chosenQuery"],
    setQueryChoice: (data: string) => RdxActionPayload
}

const AdDataUtilQueryDropdown = ({
    queryChoices,
    chosenQuery,
    setQueryChoice
}: Props) => {

    function handleAppDropdownChange(e: React.SyntheticEvent, data: DropdownMenuProps) {
        if (data.value) return setQueryChoice(data.value)
    }

    return (
        <div className={s.wrap}>
            <h3>Query</h3>
            <Dropdown
                placeholder='select query'
                selection
                options={queryChoices}
                onChange={handleAppDropdownChange}
                selectOnBlur={false}
                value={chosenQuery}
                fluid
            />
        </div>
    )
}

type SelectorProps = Omit<Props, "setQueryChoice">
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    queryChoices: selectQueryChoices_adDataUtil,
    chosenQuery: selectChosenQuery_adDataUtil
})

const mapDispatch = {
    setQueryChoice: (data: string) => setChosenQuery_adDataUtil(data)
}

export default connect(mapStateToProps, mapDispatch)(AdDataUtilQueryDropdown)
