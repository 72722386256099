import React, { useState } from 'react'
import { Button, Dropdown, DropdownMenuProps } from 'semantic-ui-react'
import { AnalyticsPageState } from '../../../../../redux/analytics-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'
import { SemanticDropdownOpts } from '../../../../../ts/generic/interfaces'
import s from './TableFieldsSelector.module.scss'

type Props = {
    initLoading: AnalyticsPageState["initLoading"],
    chosenTable: AnalyticsPageState["chosenTable"],
    chosenTableFields: AnalyticsPageState["chosenTableFields"],
    setTableFields: (data: string) => RdxActionPayload,
    tableFieldChoices: AnalyticsPageState["tableFieldChoices"],
    setManyTableFields: (data: string) => RdxActionPayload
}

const TableFieldsSelector = ({
    initLoading,
    chosenTable,
    chosenTableFields,
    setTableFields,
    tableFieldChoices,
    setManyTableFields
}: Props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    function handleFieldsDropdownChange(e: React.SyntheticEvent, data: DropdownMenuProps) {
        setIsOpen(false)
        if (data.value) return setManyTableFields(data.value)
    }

    let allFieldChoices: SemanticDropdownOpts[] = []

    if (chosenTable) {
        const opts = tableFieldChoices[chosenTable].map(field => {
            let text = `${field}`
            let newKey = Math.floor(Math.random() * (10000 - 1) + 1)
            return {
                key: newKey.toString(),
                text,
                value: text
            }
        })
        allFieldChoices = [...allFieldChoices, ...opts]
    }

    return (
        <div className={s.wrap}>
            <Dropdown
                open={isOpen}
                placeholder='select fields'
                selection
                options={allFieldChoices}
                onChange={handleFieldsDropdownChange}
                onClick={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                selectOnBlur={false}
                loading={initLoading}
                disabled={chosenTable.length <= 0}
                value={chosenTableFields}
                fluid
                multiple
            />
            <div style={{ marginTop: 20 }}>
                {chosenTableFields.length > 0 ?
                    <Button onClick={() => setIsOpen(!isOpen)} color="vk">
                        Add Field
                    </Button>
                    : null
                }
            </div>
        </div>
    )
}

export default TableFieldsSelector
