import { createSelector } from 'reselect'
import { RootState } from '../reduxTypes'

const purchaseTable = (rootState: RootState) => rootState.purchaseTable

export const selectDataLoadingPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.dataLoading
)

export const selectSheetDataPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.sheetData
)

export const selectTotalRowsPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.totalRows
)
export const selectTotalLTVPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.totalLTV
)

export const selectRowsPerPagePurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.rowsPerPage
)

export const selectCurrPagePurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.currPage
)

export const selectLastReceivedDatePurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.lastReceivedDate
)

export const selectGoingForwardPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.goingForward
)

export const selectCurrFiltersPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.currFilters
)

export const selectSearchByFilterPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.searchByFilter
)

export const selectHasNextPurchTbl = createSelector(
    [purchaseTable],
    purchaseTable => purchaseTable.hasNext
)

export const selectPaginationDataPurchTbl = (state: RootState) => {
    const { rowsPerPage, totalRows, currPage, lastReceivedDate } = state.purchaseTable
    return { rowsPerPage, totalRows, currPage, lastReceivedDate }
}
