import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { PAGES } from '../../../helpers/constants'
import { camel2title, getLookupPanels } from '../../../helpers/utils'
import { AllSheetData, selectAllSheetData } from '../../../redux/multiStateSelectors'
import { PurchaseTableRow, TableColumn } from '../../../redux/purchase-table/interfaces'
import { RootState } from '../../../redux/reduxTypes'

type Props = {
    allSheetData: AllSheetData,
    columns: TableColumn[],
    page: string
}
const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            width: '40%',
            marginTop: '2rem'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular as number
        }
    })
})

const LookupOptsAccordion = ({ allSheetData, columns, page }: Props) => {

    let sheetData: PurchaseTableRow[] = [];
    switch (page) {
        case PAGES.PURCHASE:
            sheetData = allSheetData.purchaseTblData;
            break;
        case PAGES.TEST_PURCHASE:
            sheetData = allSheetData.testPurchaseTblData;
            break;
        default:
            sheetData = [];
    }

    const accordionOptions = getLookupPanels(columns, sheetData)
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <h3>Lookup Options</h3>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>View Lookup options</Typography>
                </AccordionSummary>
                {
                    Object.keys(accordionOptions).map((key: string, i: number) => (
                        <Accordion key={i}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={classes.heading}>{camel2title(key)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {
                                        accordionOptions[key].map((option, idx) => (
                                            <ListItem key={idx} component="li">{option}</ListItem>
                                        ))
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Accordion>
        </div>
    )
}

type SelectorProps = Pick<Props, "allSheetData">
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    allSheetData: selectAllSheetData
})

export default connect(mapStateToProps)(LookupOptsAccordion)
