import { testPurchaseTblCols } from "../../helpers/constants"
import { PurchasePageState } from "../purchase-page/interfaces"

export const ACTIONS = {
    TEST_SET_FROM_DATE: "TEST_SET_FROM_DATE",
    TEST_SET_TO_DATE: "TEST_SET_TO_DATE",
    TEST_SET_CHOSEN_APPS: "TEST_SET_CHOSEN_APPS",
    TEST_SET_START_YEAR: "TEST_SET_START_YEAR",
    TEST_SET_END_YEAR: "TEST_SET_END_YEAR",
    TEST_SET_APP_VERSIONS: "TEST_SET_APP_VERSIONS",
    TEST_SET_BRANDS: "TEST_SET_BRANDS",
    TEST_SET_QUIK_CHOICE: "TEST_SET_QUIK_CHOICE",
}

export const SAGA_ACTIONS = {
    TEST_INIT_PURCHASE_PAGE_STATE: "TEST_INIT_PURCHASE_PAGE_STATE",
    TEST_SET_AVAILABLE_APPS: "TEST_SET_AVAILABLE_APPS",
    TEST_SET_YEAR_CHOICES: "TEST_SET_YEAR_CHOICES",
    TEST_SET_INIT_LOADING: "TEST_SET_INIT_LOADING",
    TEST_SET_APP_VERSION_CHOICES: "TEST_SET_APP_VERSION_CHOICES",
    TEST_SET_BRAND_CHOICES: "TEST_SET_BRAND_CHOICES"
}

export const TEST_PURCHASE_PAGE_INIT_STATE: PurchasePageState = {
    fromDate: "",
    toDate: "",
    chosenApps: [],
    startYear: "",
    endYear: "",
    appVersions: [],
    brands: [],
    quikChoice: "",
    appVersionChoices: {},
    initLoading: true,
    yearChoices: [],
    appChoices: [],
    brandDropdownChoices: [],
    columns: testPurchaseTblCols,
    getDataLoading: false
}
