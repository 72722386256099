import { combineReducers } from 'redux'
import { adminReducer } from './admin-state/adminReducer'
import { analyticsPageReducer } from './analytics-page/analyticsPageReducer'
import { analyticsTableReducer } from './analytics-table/analyticsTableReducer'
import { dataUtilReducer } from './data-utils/dataUtilReducer'
import { purchasePageReducer } from './purchase-page/purchasePageReducer'
import { purchaseTableReducer } from './purchase-table/purchaseTableReducer'
import { testPurchasePageReducer } from './test-purchase-page/testPurchasePageReducer'
import { testPurchaseTableReducer } from './test-purchase-table/testPurchaseTableReducer'

export const rootReducer = combineReducers({
    purchasePage: purchasePageReducer,
    purchaseTable: purchaseTableReducer,
    analyticsPage: analyticsPageReducer,
    analyticsTable: analyticsTableReducer,
    testPurchasePage: testPurchasePageReducer,
    testPurchaseTable: testPurchaseTableReducer,
    admin: adminReducer,
    dataUtils: dataUtilReducer
})
