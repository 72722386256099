import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL } from '../../helpers/constants'
import { createYearOptions, getDropdownOpts } from '../../helpers/utils'
import { SAGA_ACTIONS } from './interfaces'
import { testSetAppDropdownChoices, testSetAppVersionChoices, testSetBrandChoices, testSetEndYear, testSetFromDate, testSetInitLoading, testSetStartYear, testSetToDate, testSetYearOptions } from './testPurchasePageActions'

function* handleTestPageInitState() {
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type'
        },
        params: { testPurchases: true }
    }

    try {
        const res: AxiosResponse = yield call(axios.get, (BASE_URL + '/data/dboptions'), config)
        const { appNames, recentYear, oldestYear, appVersionsData, brands } = res.data
        const now = new Date()
        const earlyToday = new Date(parseInt(recentYear), now.getMonth(), now.getDate(), 0, 0, 1)
        yield put(testSetAppVersionChoices(appVersionsData))
        yield put(testSetYearOptions(createYearOptions(oldestYear, recentYear)))
        yield put(testSetBrandChoices(getDropdownOpts(brands)))
        yield put(testSetAppDropdownChoices(getDropdownOpts(appNames)))
        yield put(testSetFromDate(earlyToday))
        yield put(testSetToDate(now))
        yield put(testSetStartYear(recentYear))
        yield put(testSetEndYear(recentYear))
        yield put(testSetInitLoading(false))
    } catch (e) {
        yield console.log(e)

    }
}

function* interceptGetState() {
    yield takeLatest(
        [SAGA_ACTIONS.TEST_INIT_PURCHASE_PAGE_STATE],
        handleTestPageInitState
    )
}

export function* testPurchasePageSagas() {
    yield all([call(interceptGetState)])
}
