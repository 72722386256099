import { TableColumn } from '../purchase-table/interfaces'
import { RdxActionPayload } from '../reduxTypes'
import { ACTIONS, PurchasePageState, PURCHASE_PAGE_INITIAL_STATE, SAGA_ACTIONS } from './interfaces'

export const purchasePageReducer = (
    state: PurchasePageState = PURCHASE_PAGE_INITIAL_STATE,
    action: RdxActionPayload
) => {

    switch (action.type) {
        case ACTIONS.SET_QUIK_CHOICE:
            return {
                ...state,
                quikChoice: action.payload
            }
        case ACTIONS.SET_BRANDS:
            return {
                ...state,
                brands: action.payload
            }
        case ACTIONS.SET_APP_VERSIONS:
            return {
                ...state,
                appVersions: action.payload
            }
        case ACTIONS.SET_FROM_DATE:
            return {
                ...state,
                fromDate: action.payload
            }
        case ACTIONS.SET_TO_DATE:
            return {
                ...state,
                toDate: action.payload
            }
        case ACTIONS.SET_CHOSEN_APPS:
            return {
                ...state,
                chosenApps: action.payload,
                appVersions: state.appVersions.length ? state.appVersions.filter((v: string) => {
                    let name = v.substring(v.indexOf('(') + 1, v.length - 1)
                    return action.payload.includes(name)
                }) : state.appVersions
            }
        case ACTIONS.SET_START_YEAR:
            return {
                ...state,
                startYear: action.payload
            }
        case ACTIONS.SET_END_YEAR:
            return {
                ...state,
                endYear: action.payload
            }
        case ACTIONS.UPDATE_COLS:
            let { field, hidden } = action.payload;
            return {
                ...state,
                columns: hidden ? state.columns.map((c: TableColumn) => {
                    if (c.field === field) {
                        let newCol = { ...c };
                        let { tableData } = newCol;
                        if (tableData && tableData.filterValue) {
                            newCol.tableData.filterValue = '';
                        }
                        return newCol;
                    } else {
                        return c
                    }
                }) : state.columns
            }
        case ACTIONS.SET_GET_DATA_LOADING:
            return {
                ...state,
                getDataLoading: action.payload
            }
        case SAGA_ACTIONS.SET_APP_VERSION_CHOICES:
            return {
                ...state,
                appVersionChoices: action.payload
            }
        case SAGA_ACTIONS.SET_INIT_LOADING:
            return {
                ...state,
                initLoading: action.payload
            }
        case SAGA_ACTIONS.SET_YEAR_CHOICES:
            return {
                ...state,
                yearChoices: action.payload
            }
        case SAGA_ACTIONS.SET_APP_CHOICES:
            return {
                ...state,
                appChoices: action.payload
            }
        case SAGA_ACTIONS.SET_BRAND_CHOICES:
            return {
                ...state,
                brandDropdownChoices: action.payload
            }
        default:
            return { ...state }
    }
}
