import to from 'await-to-js'
import axios from 'axios'
import { SyntheticEvent, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Form } from 'semantic-ui-react'
import { HtmlInputrops } from 'semantic-ui-react/dist/commonjs/generic'
import { BASE_URL } from '../../helpers/constants'
import { setAuthed } from '../../redux/admin-state/adminActions'
import { RdxActionPayload } from '../../redux/reduxTypes'
import s from './LoginPage.module.scss'

type Props = {
    setAuthed: (data: boolean) => RdxActionPayload
}

const LoginPage = ({ setAuthed }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    async function handleSubmit(e: SyntheticEvent) {
        e.preventDefault()
        const route = BASE_URL + '/admin/login'
        const [err, res] = await to(axios.post(route, { username, password }))

        if (err) return
        let _id = res?.data._id
        let token = res?.data.token
        localStorage.setItem('_id', String(_id))
        localStorage.setItem('token', String(token))
        if (res?.data.success) setAuthed(true)
        setLoading(false)
    }

    function handleInputChange(e: HtmlInputrops) {
        let { name, value } = e.target
        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                break;
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.form}>
                <h1 className={s.title}>Login</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Username</label>
                        <input value={username} type='text' name='username' placeholder='username' onChange={handleInputChange} required />
                    </Form.Field>
                    <Form.Field>
                        <label>Password</label>
                        <input value={password} type='password' name='password' placeholder='password' onChange={handleInputChange} required />
                    </Form.Field>
                    <Button type='submit' loading={loading} onClick={() => setLoading(true)}>
                        Submit
                    </Button>
                </Form>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    setAuthed: (b: boolean) => setAuthed(b)
}

export default connect(null, mapDispatchToProps)(LoginPage)
