import { RdxActionPayload } from '../../reduxTypes'
import { ACTIONS, AdUtilState, AD_UTIL_INITIAL_STATE, SAGA_ACTIONS } from './interfaces'

export const adUtilReducer = (
    state: AdUtilState = AD_UTIL_INITIAL_STATE,
    action: RdxActionPayload
) => {

    switch (action.type) {
        case ACTIONS.adDataUtil_SET_CHOSEN_QUERY:
            return {
                ...state,
                chosenQuery: action.payload
            }
        case ACTIONS.adDataUtil_SET_CHOSEN_APPS:
            return {
                ...state,
                chosenApps: action.payload
            }
        case ACTIONS.adDataUtil_SET_START_YEAR:
            return {
                ...state,
                startYear: action.payload
            }
        case ACTIONS.adDataUtil_SET_END_YEAR:
            return {
                ...state,
                endYear: action.payload
            }
        case ACTIONS.adDataUtil_SET_FROM_DATE:
            return {
                ...state,
                fromDate: action.payload
            }
        case ACTIONS.adDataUtil_SET_TO_DATE:
            return {
                ...state,
                toDate: action.payload
            }
        case ACTIONS.adDataUtil_SET_DATA:
            return {
                ...state,
                data: action.payload
            }
        case SAGA_ACTIONS.adDataUtil_SET_APP_CHOICES:
            return {
                ...state,
                appChoices: action.payload
            }
        case SAGA_ACTIONS.adDataUtil_SET_YEAR_CHOICES:
            return {
                ...state,
                yearChoices: action.payload
            }
        case SAGA_ACTIONS.adDataUtil_SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
