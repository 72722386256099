import axios from 'axios'
import { applyMiddleware, bindActionCreators, createStore } from 'redux'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleWare from 'redux-saga'
import { formatAndCheckDates } from '../components/reusable/DbSearchOptions/supplement'
import { BASE_URL } from '../helpers/constants'
import { formatAllDataForCsv } from '../helpers/utils'
import { setAppVersionsAnalyticsTbl, setChosenAppsAnalyticsTbl, setEndYearAnalyticsTbl, setEndYearSecondAnalyticsTbl, setFromDateAnalyticsTbl, setFromDateSecondAnalyticsTbl, setManyTableFieldsAnalyticsTbl, setQuikChoiceAnalyticsTbl, setQuikChoiceSecondAnalyticsTbl, setStartYearAnalyticsTbl, setStartYearSecondAnalyticsTbl, setTableAnalyticsTbl, setTableDistinctFieldsAnalyticsTbl, setTableFieldsAnalyticsTbl, setToDateAnalyticsTbl, setToDateSecondAnalyticsTbl } from './analytics-page/analyticsPageActions'
import { setSearchByFilterAnalyticsTbl } from './analytics-table/analyticsTableActions'
import { setAppVersions, setBrands, setChosenApps, setEndYear, setFromDate, setQuikChoice, setStartYear, setToDate } from './purchase-page/purchasePageActions'
import { selectUpdatePurchTblProps } from './purchase-page/purchasePageSelectors'
import { QueryParams, TableColumn, TableFilter, UnformattedSheetRow } from './purchase-table/interfaces'
import { setSearchByFilterPurchTbl, setSheetDataPurchTbl } from './purchase-table/purchaseTableActions'
import { AppNamesAndVersions, GetAllPurchaseQueryData, PurchaseQueryOptionsParams } from './purchase-table/purchaseTableSagas'
import { RdxActionPayload } from './reduxTypes'
import { rootReducer } from './rootReducer'
import rootSaga from './rootSaga'
import { testSetAppVersions, testSetBrands, testSetChosenApps, testSetEndYear, testSetFromDate, testSetQuikChoice, testSetStartYear, testSetToDate } from './test-purchase-page/testPurchasePageActions'
import { setSheetDataTestTbl } from './test-purchase-table/testPurchaseTableActions'

const sagaMiddlewares = createSagaMiddleWare();
let middlewares: any[] = [sagaMiddlewares];

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['admin']
}

if (process.env.NODE_ENV === "development") middlewares.push(logger)

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, applyMiddleware(...middlewares))
const persistor = persistStore(store)
sagaMiddlewares.run(rootSaga)

export type SearchOptionsActionsNeeded = {
    setEndYear__analyticsTbl: (yr: string) => RdxActionPayload,
    setToDate__analyticsTbl: (date: Date | string) => RdxActionPayload,
    setFromDate__analyticsTbl: (date: Date | string) => RdxActionPayload,
    setStartYear__analyticsTbl: (yr: string) => RdxActionPayload,
    setAppVersions__analyticsTbl: (data: string[]) => RdxActionPayload,
    setChosenApps__analyticsTbl: (data: string[]) => RdxActionPayload,
    setTable__analyticsTbl: (data: string) => RdxActionPayload,
    setTableFields__analyticsTbl: (data: string) => RdxActionPayload,
    setManyTableFields__analyticsTbl: (data: string) => RdxActionPayload,
    setTableDistinctFields__analyticsTbl: (data: string[]) => RdxActionPayload,
    setQuikChoice__analyticsTbl: (s: string) => RdxActionPayload,

    setEndYearSecond__analyticsTbl: (yr: string) => RdxActionPayload,
    setToDateSecond__analyticsTbl: (date: Date | string) => RdxActionPayload,
    setFromDateSecond__analyticsTbl: (date: Date | string) => RdxActionPayload,
    setStartYearSecond__analyticsTbl: (yr: string) => RdxActionPayload,
    setQuikChoiceSecond__analyticsTbl: (s: string) => RdxActionPayload,

    setSheetData: (data: UnformattedSheetRow[]) => RdxActionPayload,
    setSheetTestData: (data: UnformattedSheetRow[]) => RdxActionPayload,

    setEndYear__purchTbl: (yr: string) => RdxActionPayload,
    setEndYear__testPurchTbl: (yr: string) => RdxActionPayload,

    setToDate__purchTbl: (date: Date | string) => RdxActionPayload,
    setToDate__testPurchTbl: (date: Date | string) => RdxActionPayload,

    setFromDate__purchTbl: (date: Date | string) => RdxActionPayload,
    setFromDate__testPurchTbl: (date: Date | string) => RdxActionPayload,

    setStartYear__purchTbl: (yr: string) => RdxActionPayload,
    setStartYear__testPurchTbl: (yr: string) => RdxActionPayload,

    setAppVersions__purchTbl: (data: string[]) => RdxActionPayload,
    setAppVersions__testPurchTbl: (data: string[]) => RdxActionPayload,

    setChosenApps__purchTbl: (data: string[]) => RdxActionPayload,
    setChosenApps__testPurchTbl: (data: string[]) => RdxActionPayload,

    setBrands__purchTbl: (data: string[]) => RdxActionPayload,
    setBrands_testPurchTbl: (data: string[]) => RdxActionPayload,

    setQuikChoice__purchTbl: (s: string) => RdxActionPayload,
    setQuikChoice__testPurchTbl: (s: string) => RdxActionPayload
}

const dbSearchActionsNeeded = bindActionCreators(
    {
        setEndYear__analyticsTbl: setEndYearAnalyticsTbl,
        setToDate__analyticsTbl: setToDateAnalyticsTbl,
        setFromDate__analyticsTbl: setFromDateAnalyticsTbl,
        setStartYear__analyticsTbl: setStartYearAnalyticsTbl,
        setAppVersions__analyticsTbl: setAppVersionsAnalyticsTbl,
        setChosenApps__analyticsTbl: setChosenAppsAnalyticsTbl,
        setTable__analyticsTbl: setTableAnalyticsTbl,
        setTableFields__analyticsTbl: setTableFieldsAnalyticsTbl,
        setManyTableFields__analyticsTbl: setManyTableFieldsAnalyticsTbl,
        setTableDistinctFields__analyticsTbl: setTableDistinctFieldsAnalyticsTbl,
        setQuikChoice__analyticsTbl: setQuikChoiceAnalyticsTbl,

        setEndYearSecond__analyticsTbl: setEndYearSecondAnalyticsTbl,
        setToDateSecond__analyticsTbl: setToDateSecondAnalyticsTbl,
        setFromDateSecond__analyticsTbl: setFromDateSecondAnalyticsTbl,
        setStartYearSecond__analyticsTbl: setStartYearSecondAnalyticsTbl,
        setQuikChoiceSecond__analyticsTbl: setQuikChoiceSecondAnalyticsTbl,

        setSheetData: setSheetDataPurchTbl,
        setSheetTestData: setSheetDataTestTbl,

        setEndYear__purchTbl: setEndYear,
        setEndYear__testPurchTbl: testSetEndYear,

        setToDate__purchTbl: setToDate,
        setToDate__testPurchTbl: testSetToDate,

        setFromDate__purchTbl: setFromDate,
        setFromDate__testPurchTbl: testSetFromDate,

        setStartYear__purchTbl: setStartYear,
        setStartYear__testPurchTbl: testSetStartYear,

        setAppVersions__purchTbl: setAppVersions,
        setAppVersions__testPurchTbl: testSetAppVersions,

        setChosenApps__purchTbl: setChosenApps,
        setChosenApps__testPurchTbl: testSetChosenApps,

        setBrands__purchTbl: setBrands,
        setBrands_testPurchTbl: testSetBrands,

        setQuikChoice__purchTbl: setQuikChoice,
        setQuikChoice__testPurchTbl: testSetQuikChoice
    },
    store.dispatch
)

export type TblTitlesActionsNeeded = {
    setSearchByFilterTestTbl: (b: boolean) => RdxActionPayload,
    setSearchByFilterPurchTbl: (b: boolean) => RdxActionPayload,
    setSearchByFilterAnalyticsTbl: (b: boolean) => RdxActionPayload
}

const tblTitlesActionsNeeded = bindActionCreators(
    { setSearchByFilterPurchTbl: setSearchByFilterPurchTbl, setSearchByFilterAnalyticsTbl },
    store.dispatch
)

export async function exportDefaultCsv(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
        try {
            const rdxState = store.getState()
            let filters: TableFilter[] = rdxState.purchaseTable.currFilters

            let neededParams: QueryParams = selectUpdatePurchTblProps(rdxState)
            const { startYear, endYear, fromDate, toDate } = neededParams
            let appNamesAndVersionsArray: AppNamesAndVersions[] = []

            Array.isArray(neededParams.chosenApps) &&
                neededParams.chosenApps.forEach((item) => {
                    let currAppName = item;
                    let newEntry: AppNamesAndVersions = { appName: currAppName, appVersions: [] };
                    appNamesAndVersionsArray.push(newEntry);
                });

            Array.isArray(neededParams.appVersions) &&
                neededParams.appVersions.forEach((item) => {
                    let currAppName = item.substring(item.indexOf('(') + 1, item.length - 1);
                    let currAppVersion = item.substring(0, item.indexOf('('));

                    let existingAppNameIndex =
                        appNamesAndVersionsArray.findIndex((entry) => {
                            if (entry.appName === currAppName) return true;
                            return false;
                        })

                    if (existingAppNameIndex !== -1) appNamesAndVersionsArray[existingAppNameIndex].appVersions.push(currAppVersion);
                });

            const dates = formatAndCheckDates(startYear, endYear, fromDate, toDate);
            let appNamesAndVersions = JSON.stringify(appNamesAndVersionsArray);
            const optionsParams: PurchaseQueryOptionsParams = { ...neededParams, ...dates, getTests: true };

            const data: GetAllPurchaseQueryData = {
                optionsParams,
                filters,
                testTable: false,
                goingForward: false,
                appNamesAndVersions
            }

            const url = BASE_URL + '/data/querypurchases/allpurchases';
            const response = await axios.post<{ sheetData: UnformattedSheetRow[] }>(url, data);
            const resSheetRows = formatAllDataForCsv(response.data.sheetData);
            let columns: TableColumn[] = rdxState.purchasePage.columns
            let sheetRows: any[] = []
            Array.isArray(resSheetRows) &&
                resSheetRows.forEach(rsr => {
                    let newRow: { [key: string]: any } = {}
                    columns.forEach((c: TableColumn) => {
                        if (c.hidden === undefined || c.hidden === false)
                            newRow[c.field] = rsr[c.field] || rsr.user[c.field?.split('.')[1] || c.field]
                    })
                    sheetRows.push(newRow)
                })

            resolve(sheetRows);
        } catch (e: any) {
            reject(e.message);
        }
    })
}

export {
    store,
    persistor,
    dbSearchActionsNeeded,
    tblTitlesActionsNeeded
}
