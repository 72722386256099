import isEqual from 'lodash.isequal'
import { memo } from 'react'
import { PurchasePageState } from '../../../../redux/purchase-page/interfaces'
import { RdxActionPayload } from '../../../../redux/reduxTypes'
import s from './AppSection.module.scss'
import AppSelector from './Dropdowns/AppSelector'
import AppVersionSelector from './Dropdowns/AppVersionSelector'

export type AppSelectorProps = {
    appChoices: PurchasePageState["appChoices"],
    initLoading: PurchasePageState["initLoading"],
    chosenApps: PurchasePageState["chosenApps"],
    setChosenApps: (data: string[]) => RdxActionPayload
}

export type AppVersionSelectorProps = {
    initLoading: PurchasePageState["initLoading"],
    chosenApps: PurchasePageState["chosenApps"],
    appVersions: PurchasePageState["appVersions"],
    appVersionChoices: PurchasePageState["appVersionChoices"]
    setAppVersions: (data: string[]) => RdxActionPayload
}

interface Props {
    appSelector: AppSelectorProps,
    appVersionSelector: AppVersionSelectorProps
}

const AppSection = (props: Props) => {
    return (
        <div className={s.wrap}>
            <h3>Application</h3>
            <AppSelector {...props.appSelector} />
            <AppVersionSelector {...props.appVersionSelector} />
        </div>
    )
}

const comparison = (prevProps: Props, nextState: Props) => isEqual(prevProps, nextState)
export default memo(AppSection, comparison)
