import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL } from '../../helpers/constants'
import { setAuthed } from './adminActions'
import { SAGA_ACTIONS } from './interfaces'

function* handleCheckAdmin() {

    let _id = localStorage.getItem('_id')
    let token = localStorage.getItem('token')

    try {
        let config = {
            headers: {
                'x-user-id': _id,
                'x-user-token': token
            }
        }
        console.log(config);

        const res: AxiosResponse = yield call(axios.get, (BASE_URL + '/admin/checktoken'), config)
        let isAuthed = res?.data.success ? true : false
        yield put(setAuthed(isAuthed))
    } catch (e) {
        yield console.log(e)
    }
}

function* interceptGetState() {
    yield takeLatest(
        [SAGA_ACTIONS.CHECK_ADMIN],
        handleCheckAdmin
    )
}

export function* loginPageSagas() {
    yield all([call(interceptGetState)])
}
