import { AppearanceTypes } from "react-toast-notifications"
import { PersistState } from "redux-persist/lib/types"
import { ToastOptions } from "../../ts/generic/interfaces"

export const ACTIONS = {
    SET_AUTHED: "SET_AUTHED",
    "persist/REHYDRATE": "persist/REHYDRATE",
    SET_TOAST: "SET_TOAST"
}

export const SAGA_ACTIONS = {
    CHECK_ADMIN: "CHECK_ADMIN"
}

export type Toast = {
    msg: string,
    config: ToastOptions
}

export interface AdminState {
    authed: boolean,
    toast: Toast
}

export const ADMIN_INITIAL_STATE = {
    authed: false,
    toast: {
        msg: "",
        config: {
            appearance: ('info' as AppearanceTypes),
            autoDismiss: true
        }
    }
}

export interface AdminActionPayload {
    type: string,
    payload: PersistPayload | boolean | Toast ,
    key?: string,
    err?: any
}

export interface PersistPayload {
    admin: AdminState,
    _persist?: PersistState,
}
