import { PAGES } from '../../../helpers/constants'
import s from './TableTitleWithToggle.module.scss'

type Props = {
    page: string
}

const TableTitleWithToggle = ({ page }: Props) => {
    let title
    switch (page) {
        case PAGES.PURCHASE:
            title = PAGES.PURCHASE.replace(/_/g, " ");
            break;
        case PAGES.ANALYTICS:
            title = PAGES.ANALYTICS.replace(/_/g, " ");
            break;
        case PAGES.TEST_PURCHASE:
            title = PAGES.TEST_PURCHASE.replace(/_/g, " ");
            break;
        default:
            title = "NO TABLE NAME ASSIGNED"
    }
    return (
        <div className={s.wrap}>
            <h4>{title + " TABLE"}</h4>
            {/* <Toggle page={page}/> */}
        </div>
    )
}

export default TableTitleWithToggle
