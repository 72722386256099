import { analyticsTblCols } from "../../helpers/constants"
import { SemanticDropdownOpts } from "../../ts/generic/interfaces"
import { TableColumnAnalytics } from "../analytics-table/interfaces"

export interface AnalyticsPageState {
    fromDate: string | Date,
    toDate: string | Date,
    fromDateSecond: string | Date,
    toDateSecond: string | Date,
    chosenApps: string[],
    startYear: string,
    endYear: string,
    startYearSecond: string,
    endYearSecond: string,
    appVersions: string[],
    chosenTable: string,
    chosenTableFields: string,
    chosenTableDistinctFields: string[],
    tableFieldChoices: TableFieldChoices,
    tableDistinctFieldChoices: TableFieldChoices,
    quikChoice: string,
    quikChoiceSecond: string,
    appVersionChoices: AppVersionChoices,
    initLoading: boolean,
    yearChoices: SemanticDropdownOpts[],
    appChoices: SemanticDropdownOpts[],
    tableDropdownChoices: SemanticDropdownOpts[],
    columns: TableColumnAnalytics[],
    getDataLoading: boolean
}

export const ANALYTICS_PAGE_INITIAL_STATE = {
    fromDate: "",
    toDate: "",
    fromDateSecond: "",
    toDateSecond: "",
    chosenApps: [],
    startYear: "",
    endYear: "",
    startYearSecond: "",
    endYearSecond: "",
    appVersions: [],
    chosenTable: "",
    chosenTableFields: "",
    chosenTableDistinctFields: [],
    tableFieldChoices: {},
    tableDistinctFieldChoices: {},
    quikChoice: "",
    quikChoiceSecond: "",
    appVersionChoices: {},
    initLoading: true,
    yearChoices: [],
    appChoices: [],
    tableDropdownChoices: [],
    columns: analyticsTblCols,
    getDataLoading: false
}

export const ACTIONS = {
    ANALYTICS_SET_FROM_DATE: "ANALYTICS_SET_FROM_DATE",
    ANALYTICS_SET_TO_DATE: "ANALYTICS_SET_TO_DATE",
    ANALYTICS_SET_CHOSEN_APPS: "ANALYTICS_SET_CHOSEN_APPS",
    ANALYTICS_SET_START_YEAR: "ANALYTICS_SET_START_YEAR",
    ANALYTICS_SET_END_YEAR: "ANALYTICS_SET_END_YEAR",
    ANALYTICS_SET_APP_VERSIONS: "ANALYTICS_SET_APP_VERSIONS",
    ANALYTICS_SET_TABLE: "ANALYTICS_SET_TABLE",
    ANALYTICS_SET_TABLE_FIELDS: "ANALYTICS_SET_TABLE_FIELDS",
    ANALYTICS_SET_MANY_TABLE_FIELDS:"ANALYTICS_SET_MANY_TABLE_FIELDS",
    ANALYTICS_SET_TABLE_DISTINCT_FIELDS: "ANALYTICS_SET_TABLE_DISTINCT_FIELDS",
    ANALYTICS_SET_QUIK_CHOICE: "ANALYTICS_SET_QUIK_CHOICE",
    ANALYTICS_UPDATE_COLS: "ANALYTICS_UPDATE_COLS",
    ANALYTICS_SET_GET_DATA_LOADING: "ANALYTICS_SET_GET_DATA_LOADING",
    ANALYTICS_SET_QUIK_CHOICE_SECOND: "ANALYTICS_SET_QUIK_CHOICE_SECOND",
    ANALYTICS_SET_FROM_DATE_SECOND: "ANALYTICS_SET_FROM_DATE_SECOND",
    ANALYTICS_SET_TO_DATE_SECOND: "ANALYTICS_SET_TO_DATE_SECOND",
    ANALYTICS_SET_START_YEAR_SECOND: "ANALYTICS_SET_START_YEAR_SECOND",
    ANALYTICS_SET_END_YEAR_SECOND: "ANALYTICS_SET_END_YEAR_SECOND",
    ANALYTICS_SET_INIT_SECOND_DATE: "ANALYTICS_SET_INIT_SECOND_DATE",
}

export const SAGA_ACTIONS = {
    INIT_ANALYTICS_PAGE_STATE: "INIT_ANALYTICS_PAGE_STATE",
    ANALYTICS_SET_APP_CHOICES: "ANALYTICS_SET_APP_CHOICES",
    ANALYTICS_SET_YEAR_CHOICES: "ANALYTICS_SET_YEAR_CHOICES",
    ANALYTICS_SET_INIT_LOADING: "ANALYTICS_SET_INIT_LOADING",
    ANALYTICS_SET_APP_VERSION_CHOICES: "ANALYTICS_SET_APP_VERSION_CHOICES",
    ANALYTICS_SET_TABLE_CHOICES: "ANALYTICS_SET_TABLE_CHOICES",
    ANALYTICS_SET_TABLE_FIELD_CHOICES: "ANALYTICS_SET_TABLE_FIELD_CHOICES",
    ANALYTICS_SET_TABLE_DISTINCT_FIELD_CHOICES: "ANALYTICS_SET_TABLE_DISTINCT_FIELD_CHOICES"
}

interface AppVersionChoices {
    [key: string]: string[]
}

interface TableFieldChoices {
    [key: string]: string[][]
}
