import { formatDBSheetRow } from '../../helpers/utils'
import { PurchaseTableRow, UnformattedSheetRow } from '../purchase-table/interfaces'
import { ACTIONS } from './interfaces'

export function setSheetDataTestTbl(data: UnformattedSheetRow[]) {
    const newSheetData: PurchaseTableRow[] = formatDBSheetRow(data);
    return {
        type: ACTIONS.TEST_SET_SHEET_DATA,
        payload: newSheetData
    }
}

export function setDataLoadingTestTbl(bool: boolean) {
    return {
        type: ACTIONS.TEST_SET_DATA_LOADING,
        payload: bool
    }
}

export function getTestSheetData() {
    return {
        type: ACTIONS.TEST_GET_SHEET_DATA
    }
}
