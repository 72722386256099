import { TABLES } from '../../../helpers/constants'
import { AnalyticsPageState, ANALYTICS_PAGE_INITIAL_STATE } from '../../../redux/analytics-page/interfaces'
import { PurchasePageState, PURCHASE_PAGE_INITIAL_STATE } from '../../../redux/purchase-page/interfaces'
import { SearchOptionsActionsNeeded } from '../../../redux/store'

const BLANK_SEARCH_OPTS_PROPS = {
    appSectionProps: {
        appSelector: {
            appChoices: PURCHASE_PAGE_INITIAL_STATE.appChoices,
            initLoading: PURCHASE_PAGE_INITIAL_STATE.initLoading,
            chosenApps: PURCHASE_PAGE_INITIAL_STATE.chosenApps,
            setChosenApps: (data: string[]) => ({ type: '' })
        },
        appVersionSelector: {
            initLoading: PURCHASE_PAGE_INITIAL_STATE.initLoading,
            chosenApps: PURCHASE_PAGE_INITIAL_STATE.chosenApps,
            appVersions: PURCHASE_PAGE_INITIAL_STATE.appVersions,
            appVersionChoices: PURCHASE_PAGE_INITIAL_STATE.appVersionChoices,
            setAppVersions: (data: string[]) => ({ type: '' })
        },
    },
    brandSectionProps: {
        brandDropdownChoices: PURCHASE_PAGE_INITIAL_STATE.brandDropdownChoices,
        initLoading: PURCHASE_PAGE_INITIAL_STATE.initLoading,
        brands: PURCHASE_PAGE_INITIAL_STATE.brands,
        setBrands: (data: string[]) => ({ type: '' })
    },
    dateSectionProps: {
        startDatePicker: {
            fromDate: PURCHASE_PAGE_INITIAL_STATE.fromDate,
            startYear: PURCHASE_PAGE_INITIAL_STATE.startYear,
            initLoading: PURCHASE_PAGE_INITIAL_STATE.initLoading,
            yearChoices: PURCHASE_PAGE_INITIAL_STATE.yearChoices,
            quikChoice: PURCHASE_PAGE_INITIAL_STATE.quikChoice,
            setFromDate: (date: Date | string) => ({ type: '' }),
            setStartYear: (yr: string) => ({ type: '' })
        },
        endDatePicker: {
            toDate: PURCHASE_PAGE_INITIAL_STATE.toDate,
            endYear: PURCHASE_PAGE_INITIAL_STATE.endYear,
            initLoading: PURCHASE_PAGE_INITIAL_STATE.initLoading,
            yearChoices: PURCHASE_PAGE_INITIAL_STATE.yearChoices,
            quikChoice: PURCHASE_PAGE_INITIAL_STATE.quikChoice,
            setToDate: (date: Date | string) => ({ type: '' }),
            setEndYear: (yr: string) => ({ type: '' })
        },
        quikChoice: {
            fromDate: PURCHASE_PAGE_INITIAL_STATE.fromDate,
            toDate: PURCHASE_PAGE_INITIAL_STATE.toDate,
            startYear: PURCHASE_PAGE_INITIAL_STATE.startYear,
            endYear: PURCHASE_PAGE_INITIAL_STATE.endYear,
            quikChoice: PURCHASE_PAGE_INITIAL_STATE.quikChoice,
            initLoading: PURCHASE_PAGE_INITIAL_STATE.initLoading,
            setToDate: (date: Date | string) => ({ type: '' }),
            setEndYear: (yr: string) => ({ type: '' }),
            setFromDate: (date: Date | string) => ({ type: '' }),
            setStartYear: (yr: string) => ({ type: '' }),
            setQuikChoice: (s: string) => ({ type: '' })
        }
    },
    tableSectionProps: {
        tableSelector: {
            tableDropdownChoices: ANALYTICS_PAGE_INITIAL_STATE.tableDropdownChoices,
            initLoading: ANALYTICS_PAGE_INITIAL_STATE.initLoading,
            chosenTable: ANALYTICS_PAGE_INITIAL_STATE.chosenTable,
            setTableAnalyticsTbl: (data: string) => ({ type: '' })
        },
        tableFieldsSelector: {
            initLoading: ANALYTICS_PAGE_INITIAL_STATE.initLoading,
            chosenTable: ANALYTICS_PAGE_INITIAL_STATE.chosenTable,
            chosenTableFields: ANALYTICS_PAGE_INITIAL_STATE.chosenTableFields,
            setTableFieldsAnalyticsTbl: (data: string) => ({ type: '' }),
            tableFieldChoices: ANALYTICS_PAGE_INITIAL_STATE.tableFieldChoices
        }
    }
}

export function formatChildProps(table: string, purchasePage: PurchasePageState, testPurchasePage: PurchasePageState, actions: SearchOptionsActionsNeeded) {
    let appSectionProps, brandSectionProps, dateSectionProps;
    switch (table) {
        case TABLES.PURCHASE_TBL:
            appSectionProps = {
                appSelector: {
                    appChoices: purchasePage.appChoices,
                    initLoading: purchasePage.initLoading,
                    chosenApps: purchasePage.chosenApps,
                    setChosenApps: actions.setChosenApps__purchTbl
                },
                appVersionSelector: {
                    initLoading: purchasePage.initLoading,
                    chosenApps: purchasePage.chosenApps,
                    appVersions: purchasePage.appVersions,
                    appVersionChoices: purchasePage.appVersionChoices,
                    setAppVersions: actions.setAppVersions__purchTbl
                }
            }
            brandSectionProps = {
                brandDropdownChoices: purchasePage.brandDropdownChoices,
                initLoading: purchasePage.initLoading,
                brands: purchasePage.brands,
                setBrands: actions.setBrands__purchTbl
            };
            dateSectionProps = {
                startDatePicker: {
                    fromDate: purchasePage.fromDate,
                    startYear: purchasePage.startYear,
                    initLoading: purchasePage.initLoading,
                    yearChoices: purchasePage.yearChoices,
                    quikChoice: purchasePage.quikChoice,
                    setFromDate: actions.setFromDate__purchTbl,
                    setStartYear: actions.setStartYear__purchTbl
                },
                endDatePicker: {
                    toDate: purchasePage.toDate,
                    endYear: purchasePage.endYear,
                    initLoading: purchasePage.initLoading,
                    yearChoices: purchasePage.yearChoices,
                    quikChoice: purchasePage.quikChoice,
                    setEndYear: actions.setEndYear__purchTbl,
                    setToDate: actions.setToDate__purchTbl,
                },
                quikChoice: {
                    fromDate: purchasePage.fromDate,
                    toDate: purchasePage.toDate,
                    startYear: purchasePage.startYear,
                    endYear: purchasePage.endYear,
                    quikChoice: purchasePage.quikChoice,
                    initLoading: purchasePage.initLoading,
                    setFromDate: actions.setFromDate__purchTbl,
                    setStartYear: actions.setStartYear__purchTbl,
                    setEndYear: actions.setEndYear__purchTbl,
                    setToDate: actions.setToDate__purchTbl,
                    setQuikChoice: actions.setQuikChoice__purchTbl
                }
            }
            break;
        case TABLES.TEST_PURCHASE_TBL:
            appSectionProps = {
                appSelector: {
                    appChoices: testPurchasePage.appChoices,
                    initLoading: testPurchasePage.initLoading,
                    chosenApps: testPurchasePage.chosenApps,
                    setChosenApps: actions.setChosenApps__testPurchTbl
                },
                appVersionSelector: {
                    initLoading: testPurchasePage.initLoading,
                    chosenApps: testPurchasePage.chosenApps,
                    appVersions: testPurchasePage.appVersions,
                    appVersionChoices: testPurchasePage.appVersionChoices,
                    setAppVersions: actions.setAppVersions__testPurchTbl
                }
            }
            brandSectionProps = {
                brandDropdownChoices: testPurchasePage.brandDropdownChoices,
                initLoading: testPurchasePage.initLoading,
                brands: testPurchasePage.brands,
                setBrands: actions.setBrands_testPurchTbl

            };
            dateSectionProps = {
                startDatePicker: {
                    fromDate: testPurchasePage.fromDate,
                    startYear: testPurchasePage.startYear,
                    initLoading: testPurchasePage.initLoading,
                    yearChoices: testPurchasePage.yearChoices,
                    quikChoice: testPurchasePage.quikChoice,
                    setFromDate: actions.setFromDate__testPurchTbl,
                    setStartYear: actions.setStartYear__testPurchTbl
                },
                endDatePicker: {
                    toDate: testPurchasePage.toDate,
                    endYear: testPurchasePage.endYear,
                    initLoading: testPurchasePage.initLoading,
                    yearChoices: testPurchasePage.yearChoices,
                    quikChoice: testPurchasePage.quikChoice,
                    setEndYear: actions.setEndYear__testPurchTbl,
                    setToDate: actions.setToDate__testPurchTbl,
                },
                quikChoice: {
                    fromDate: testPurchasePage.fromDate,
                    toDate: testPurchasePage.toDate,
                    startYear: testPurchasePage.startYear,
                    endYear: testPurchasePage.endYear,
                    quikChoice: testPurchasePage.quikChoice,
                    initLoading: testPurchasePage.initLoading,
                    setFromDate: actions.setFromDate__testPurchTbl,
                    setStartYear: actions.setStartYear__testPurchTbl,
                    setEndYear: actions.setEndYear__testPurchTbl,
                    setToDate: actions.setToDate__testPurchTbl,
                    setQuikChoice: actions.setQuikChoice__testPurchTbl
                }
            }
            break;
        default:
            return BLANK_SEARCH_OPTS_PROPS
    }
    return { appSectionProps, brandSectionProps, dateSectionProps };
}

export function formatChildPropsAnalytics(analyticsPage: AnalyticsPageState, actions: SearchOptionsActionsNeeded) {
    let appSectionProps, tableSectionProps, tableFieldSectionProps, tableDistinctFieldSectionProps, dateSectionProps, dateSectionSecondProps;
    appSectionProps = {
        appSelector: {
            appChoices: analyticsPage.appChoices,
            initLoading: analyticsPage.initLoading,
            chosenApps: analyticsPage.chosenApps,
            setChosenApps: actions.setChosenApps__analyticsTbl
        },
        appVersionSelector: {
            initLoading: analyticsPage.initLoading,
            chosenApps: analyticsPage.chosenApps,
            appVersions: analyticsPage.appVersions,
            appVersionChoices: analyticsPage.appVersionChoices,
            setAppVersions: actions.setAppVersions__analyticsTbl
        }
    }
    tableSectionProps = {
        tableSelector: {
            tableDropdownChoices: analyticsPage.tableDropdownChoices,
            initLoading: analyticsPage.initLoading,
            chosenTable: analyticsPage.chosenTable,
            setTable: actions.setTable__analyticsTbl
        }
    }
    tableFieldSectionProps = {
        initLoading: analyticsPage.initLoading,
        chosenTable: analyticsPage.chosenTable,
        chosenTableFields: analyticsPage.chosenTableFields,
        setTableFields: actions.setTableFields__analyticsTbl,
        tableFieldChoices: analyticsPage.tableFieldChoices,
        setManyTableFields: actions.setManyTableFields__analyticsTbl
    }
    tableDistinctFieldSectionProps = {
        initLoading: analyticsPage.initLoading,
        chosenTable: analyticsPage.chosenTable,
        chosenTableFields: analyticsPage.chosenTableFields,
        chosenTableDistinctFields: analyticsPage.chosenTableDistinctFields,
        tableFieldChoices: analyticsPage.tableFieldChoices,
        setTableDistinctFields: actions.setTableDistinctFields__analyticsTbl,
        tableDistinctFieldChoices: analyticsPage.tableDistinctFieldChoices
    }
    dateSectionProps = {
        startDatePicker: {
            fromDate: analyticsPage.fromDate,
            startYear: analyticsPage.startYear,
            initLoading: analyticsPage.initLoading,
            yearChoices: analyticsPage.yearChoices,
            quikChoice: analyticsPage.quikChoice,
            setFromDate: actions.setFromDate__analyticsTbl,
            setStartYear: actions.setStartYear__analyticsTbl
        },
        endDatePicker: {
            toDate: analyticsPage.toDate,
            endYear: analyticsPage.endYear,
            initLoading: analyticsPage.initLoading,
            yearChoices: analyticsPage.yearChoices,
            quikChoice: analyticsPage.quikChoice,
            setEndYear: actions.setEndYear__analyticsTbl,
            setToDate: actions.setToDate__analyticsTbl,
        },
        quikChoice: {
            fromDate: analyticsPage.fromDate,
            toDate: analyticsPage.toDate,
            startYear: analyticsPage.startYear,
            endYear: analyticsPage.endYear,
            quikChoice: analyticsPage.quikChoice,
            initLoading: analyticsPage.initLoading,
            setFromDate: actions.setFromDate__analyticsTbl,
            setStartYear: actions.setStartYear__analyticsTbl,
            setEndYear: actions.setEndYear__analyticsTbl,
            setToDate: actions.setToDate__analyticsTbl,
            setQuikChoice: actions.setQuikChoice__analyticsTbl
        },
    }
    dateSectionSecondProps = {
        startDatePicker: {
            fromDate: analyticsPage.fromDateSecond,
            startYear: analyticsPage.startYearSecond,
            initLoading: analyticsPage.initLoading,
            yearChoices: analyticsPage.yearChoices,
            quikChoice: analyticsPage.quikChoiceSecond,
            setFromDate: actions.setFromDateSecond__analyticsTbl,
            setStartYear: actions.setStartYearSecond__analyticsTbl
        },
        endDatePicker: {
            toDate: analyticsPage.toDateSecond,
            endYear: analyticsPage.endYearSecond,
            initLoading: analyticsPage.initLoading,
            yearChoices: analyticsPage.yearChoices,
            quikChoice: analyticsPage.quikChoiceSecond,
            setEndYear: actions.setEndYearSecond__analyticsTbl,
            setToDate: actions.setToDateSecond__analyticsTbl,
        },
        quikChoice: {
            fromDate: analyticsPage.fromDateSecond,
            toDate: analyticsPage.toDateSecond,
            startYear: analyticsPage.startYearSecond,
            endYear: analyticsPage.endYearSecond,
            quikChoice: analyticsPage.quikChoiceSecond,
            initLoading: analyticsPage.initLoading,
            setFromDate: actions.setFromDateSecond__analyticsTbl,
            setStartYear: actions.setStartYearSecond__analyticsTbl,
            setEndYear: actions.setEndYearSecond__analyticsTbl,
            setToDate: actions.setToDateSecond__analyticsTbl,
            setQuikChoice: actions.setQuikChoiceSecond__analyticsTbl
        }
    }

    return { appSectionProps, tableSectionProps, tableFieldSectionProps, tableDistinctFieldSectionProps, dateSectionProps, dateSectionSecondProps }
}

export type FormattedDates = {
    startDate: string,
    endDate: string
}

export function formatAndCheckDates(
    startYear: PurchasePageState["endYear"],
    endYear: PurchasePageState["endYear"],
    fromDate: PurchasePageState["fromDate"],
    toDate: PurchasePageState["toDate"]
): FormattedDates {

    let startDate = "", endDate = ""
    let err = { startDate: "err", endDate: "err" }

    if (endYear && startYear) {
        if (endYear < startYear) return err
        if (toDate && fromDate && +toDate < +fromDate) return err
    }

    if (fromDate) {
        if (typeof (fromDate) === 'object') startDate = (fromDate as Date).toISOString()
        else startDate = fromDate
    }

    if (toDate) {
        if (typeof (toDate) === 'object') endDate = (toDate as Date).toISOString()
        else endDate = toDate
    }

    return { startDate, endDate }
}

export function formatAndCheckDatesAnalytics(
    startYear: AnalyticsPageState["endYear"],
    endYear: AnalyticsPageState["endYear"],
    fromDate: AnalyticsPageState["fromDate"],
    toDate: AnalyticsPageState["toDate"]
): FormattedDates {

    let startDate = "", endDate = ""
    let err = { startDate: "err", endDate: "err" }

    if (endYear && startYear) {
        if (endYear < startYear) return err
        if (toDate && fromDate && +toDate < +fromDate) return err
    }

    if (fromDate) {
        if (typeof (fromDate) === 'object') startDate = (fromDate as Date).toISOString()
        else startDate = fromDate
    }

    if (toDate) {
        if (typeof (toDate) === 'object') endDate = (toDate as Date).toISOString()
        else endDate = toDate
    }

    return { startDate, endDate }
}
