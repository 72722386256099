import isEqual from 'lodash.isequal'
import { memo } from 'react'
import { AnalyticsPageState } from '../../../../redux/analytics-page/interfaces'
import { RdxActionPayload } from '../../../../redux/reduxTypes'
import TableSelector from './Dropdowns/TableSelector'
import s from './TableSection.module.scss'

export type TableSelectorProps = {
    tableDropdownChoices: AnalyticsPageState["tableDropdownChoices"],
    initLoading: AnalyticsPageState["initLoading"],
    chosenTable: AnalyticsPageState["chosenTable"],
    setTable: (data: string) => RdxActionPayload
}

interface Props {
    tableSelector: TableSelectorProps,
    setManyTableFields: (data: string) => RdxActionPayload
}

const TableSection = (props: Props) => {

    return (
        <div className={s.wrap}>
            <h3>Table</h3>
            <TableSelector {...props.tableSelector} setManyTableFields={props.setManyTableFields} />
        </div>
    )
}

const comparison = (prevProps: Props, nextState: Props) => isEqual(prevProps, nextState)
export default memo(TableSection, comparison)
