import { connect } from 'react-redux'
import { PAGES, TABLES } from '../../helpers/constants'
import { PurchasePageState } from '../../redux/purchase-page/interfaces'
import { RootState } from '../../redux/reduxTypes'
import DbSearchOptions from '../reusable/DbSearchOptions/DbSearchOptions'
import LookupOptsAccordion from '../reusable/LookupOptsAccordion/LookupOptsAccordion'
import s from './PurchasePage.module.scss'
import PurchaseTable from './PurchasePage/PurchaseTable'
import ValidateUtil from './PurchasePage/ValidateUtil'

type Props = {
    columns: PurchasePageState["columns"]
}

function PurchasePage({ columns }: Props) {

    return (
        <div className={s.wrap}>
            <h1>Search Valid Purchases</h1>
            <DbSearchOptions table={TABLES.PURCHASE_TBL} />
            <LookupOptsAccordion columns={columns} page={PAGES.PURCHASE} />
            <PurchaseTable columns={columns} />
            <ValidateUtil />
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    const { columns } = state.purchasePage
    return { columns }
}

export default connect(mapStateToProps)(PurchasePage)
