import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL } from '../../helpers/constants'
import { createYearOptions, getDropdownOpts } from '../../helpers/utils'
import { setAppChoices_adDataUtil, setYearChoices_adDataUtil } from '../data-utils/ad-util/adUtilActions'
import { SAGA_ACTIONS } from './interfaces'
import { setAppChoices, setAppVersionChoices, setBrandChoices, setEndYear, setFromDate, setInitLoading, setStartYear, setToDate, setYearOptions } from './purchasePageActions'

function* handleInitState() {

    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
    }

    try {
        const res: AxiosResponse = yield call(axios.get, (BASE_URL + '/data/dboptions'), config)
        const { appNames, recentYear, oldestYear, appVersionsData, brands } = res.data
        const now = new Date()
        const earlyToday = new Date(parseInt(recentYear), now.getMonth(), now.getDate(), 0, 0, 1)
        const appDropdownOpts = getDropdownOpts(appNames)
        const yearDropdownOpts = createYearOptions(oldestYear, recentYear)

        yield put(setAppVersionChoices(appVersionsData))
        yield put(setYearOptions(createYearOptions(oldestYear, recentYear)))
        yield put(setBrandChoices(getDropdownOpts(brands)))
        yield put(setAppChoices(appDropdownOpts))
        yield put(setFromDate(earlyToday))
        yield put(setToDate(now))
        yield put(setStartYear(recentYear))
        yield put(setEndYear(recentYear))
        yield put(setInitLoading(false))
        yield put(setAppChoices_adDataUtil(appDropdownOpts.filter(choice => choice.text === "com.slimesmash.android" ? true : false)))
        yield put(setYearChoices_adDataUtil(yearDropdownOpts))
    } catch (e) {
        yield console.log(e)
    }
}

function* interceptGetState() {
    yield takeLatest(
        [SAGA_ACTIONS.INIT_PURCHASE_PAGE_STATE],
        handleInitState
    )
}

export function* purchasePageSagas() {
    yield all([call(interceptGetState)])
}
