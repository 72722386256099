import React, { ChangeEvent, useState } from 'react'
import { useToasts } from 'react-toast-notifications';
import { Input } from 'semantic-ui-react';
import { BASE_URL, toastErrConfig, toastInfoConfig } from '../../../helpers/constants';
import s from './SalesCSVUploader.module.scss'
import axios from 'axios';
import { SkuDbRecord } from '../../reusable/DbSkuRecordsTable';
import { parseSalesCsv } from '../../../helpers/utils';
import { useSalesContext } from './SalesPageContextProvider';


// represents a record in the CSV sales report download from the aws developer site
export type SalesRecord = {
    Marketplace: string,
    'Country/Region Code': string,
    'Invoice ID': string,
    'Transaction ID': string,
    'Transaction Time': string,
    'Transaction Type': string,
    'Adjustment (Yes / No)': 'No' | 'Yes',
    Asin: string,
    'Vendor SKU': string // main sku we want
    Title: string,
    'Item Name': string,
    'Item Type': string,
    'In-App Subscription Term': string,
    'In-App Subscription Status': string,
    Units: string,
    'Usage Time for Underground Apps (seconds)': string,
    'Marketplace Currency': string,
    'Sales Price (Marketplace Currency)': string,
    'Estimated Earnings (Marketplace Currency)': string,
    'Digital Order Id': string,
    'App User ID': string,
    'Receipt ID': string
};


const CSVSalesReportChecker = () => {
    const [key, setKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const { getDbSkuRecords } = useSalesContext()!;

    async function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        setLoading(true);
        try {
            if (e.target.files![0]) {
                const [reportType, yearStr, monthStr] = e.target.files![0].name.split('-');
    
                if (reportType !== "sales") {
                    throw new Error("file name didn't start with 'sales'");
                } else {
                    const year = parseInt(yearStr);
                    const month = parseInt(monthStr);
                    if (!(year in { 2022: true, 2021: true, 2020: true, 2019: true, 2023: true, 2024: true, 2025: true, 2026: true})) {
                        throw new Error(`The year was parsed as ${year} for the file name.`)
                    }
                    if (!(1 <= month && month <= 12)) {
                        throw new Error(`The month was parsed as ${month} for the file name`);
                    }
                    const finalResults = await parseFile(e.target.files![0], month, year);
                    console.log(finalResults);
                    await addToDb(finalResults, year, month);

                }
            }
        } catch (e: any) {
            addToast("There was a problem adding the file: " + e.message, toastErrConfig);
        }
        setKey(key + 1);
        setLoading(false);
    }

    async function parseFile(file: File, month: number, year: number) {
        const results = await parseSalesCsv(file);
        const uniqueSkus: {[key: string]: any} = {};
        const csvColumnNames = Object.keys(results.data[0]);

        // make sure relevant columns are present
        const relevantCols: {[key: string]: boolean} = {"Vendor SKU": true, "Marketplace Currency": true, "Estimated Earnings (Marketplace Currency)": true};
        for (const columnName of csvColumnNames) {
            if (columnName in relevantCols) {
                delete relevantCols[columnName]
            }
        }
        if (Object.keys(relevantCols).length !== 0) throw new Error("Sheet did not contain Vendor SKU, and currency data");


        // calculate estimated earnings (ee)
        for (const record of results.data) {
            const sku = record["Vendor SKU"];
            const currency = record["Marketplace Currency"];
            const estimatedEarnings = parseFloat(parseFloat(record["Estimated Earnings (Marketplace Currency)"]).toFixed(2));
            if (estimatedEarnings) {
                if (!(sku in uniqueSkus)) {
                    const currencies: {[key: string]: number } = {};
                    currencies[currency] = estimatedEarnings;
                    uniqueSkus[sku] = currencies
                } else if (currency in uniqueSkus[sku]) {
                    uniqueSkus[sku][currency] += estimatedEarnings;
                } else {
                    uniqueSkus[sku][currency] = estimatedEarnings;
                }
            }
        }
        console.log(uniqueSkus);

        // format data
        const skuEarningsData: SkuDbRecord[] = [];
        for (const uniqueSku in uniqueSkus) {
            skuEarningsData.push({
                sku: uniqueSku,
                ee: uniqueSkus[uniqueSku],
                month, year
            })
        }

        // return sorted SkuDbRecords
        return skuEarningsData.sort((a, b) => a.sku.localeCompare(b.sku));
    }


    async function addToDb(data: SkuDbRecord[], year: number, month: number){
        try {
            const response = await axios.post(BASE_URL + '/aws/sku', {
                year,
                month,
                data
            });
            const { error, msg } = response.data;
            if (error) throw new Error(error);

            // update context to get new records we just added
            await getDbSkuRecords();
            addToast(msg, toastInfoConfig);

        } catch (e: any) {
            addToast(e.message, toastErrConfig);
        }
    }
    return (
        <div className={s.wrap}>
            <div className={s.innerWrap}>
                <h3>Upload CSV File</h3>
                <div className={s.topInputWrap}>
                    <Input loading={loading} key={key} name="csv upload" accept=".csv" type="file" onChange={handleFileChange} placeholder="csv file"/>
                </div>
            </div>
        </div>
    );
};

export default CSVSalesReportChecker;