import axios from "axios"
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { BASE_URL, toastErrConfig } from "../../../helpers/constants"
import { SkuDbRecord } from "../../reusable/DbSkuRecordsTable"
import { SkuDbRecordsByDate } from "./CurrentSkuRecords"

type SalesPageContextFields = {
    currSkuRecords: SkuDbRecord[]
    comparisonRecords: SkuDbRecord[]
    dbSkuRecordsByDate:  SkuDbRecordsByDate,
    getSkuRecordsLoading: boolean
    setCurrSkuRecords: Dispatch<SetStateAction<SkuDbRecord[]>>
    setComparisonRecords: Dispatch<SetStateAction<SkuDbRecord[]>>
    setDbSkuRecordsByDate: Dispatch<SetStateAction<SkuDbRecord[]>>
    addComparisonRecord: (record: SkuDbRecord) => void
    delComparisonRecord: (record: SkuDbRecord) => void
    getDbSkuRecords: () => Promise<void>
    deleteDbSkuDateRecords: (month: number, year: number) => Promise<void>

}
export const SalesPageContext = createContext<SalesPageContextFields | null>(null);
export function useSalesContext() {
    return useContext(SalesPageContext);
}

const SalesPageContextProvider = ({ children }: { children: React.ReactNode}) => {
    const [currSkuRecords, setCurrSkuRecords]       = useState<SkuDbRecord[]>([]);
    const [comparisonRecords, setComparisonRecords] = useState<SkuDbRecord[]>([]);
    const [dbSkuRecordsByDate, setDbSkuRecordsByDate]           = useState<SkuDbRecordsByDate | {}>({});
    const [getSkuRecordsLoading, setGetSkuRecordsLoading] = useState(false);
    const { addToast }                              = useToasts();



    function addComparisonRecord(record: SkuDbRecord) {
        setComparisonRecords(prev => [...prev, record])
    }

    function delComparisonRecord(record: SkuDbRecord) {
        setComparisonRecords(prev => prev.filter(r => r.sku !== record.sku));
    }

    function organizeByDate(records: SkuDbRecord[]) {
        const allByDate: SkuDbRecordsByDate = {}

        for (const record of records) {
            const dateString = `${record.month < 10 ? '0' + record.month.toString() : record.month}-${record.year}`
            if (!(dateString in allByDate)) allByDate[dateString] = [];
            allByDate[dateString].push(record);
        }
        return allByDate;
    }

    async function getDbSkuRecords() {
        setGetSkuRecordsLoading(true);
        try {
            const response = await axios.get(BASE_URL + '/aws/sku');
            if (!response) throw new Error("no response given from get sku records from db");
            console.log(response);
            const { error, success, msg, records } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error("unsuccessful operation");
            setDbSkuRecordsByDate(organizeByDate(records as SkuDbRecord[]));
            //addToast("successfully reloaded sku records")
        } catch (e: any) {
            addToast(e.message, toastErrConfig)
        }
        setGetSkuRecordsLoading(false);
    }


    async function deleteDbSkuDateRecords(month: number, year: number) {
        setGetSkuRecordsLoading(true);
        try {
            const response = await axios.delete(BASE_URL + '/aws/sku', { data: { month, year } });
            if (!response) throw new Error("no response given from delete sku records from db");
            const { error, success, msg, records } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error("unsuccessful operation");
            setDbSkuRecordsByDate(organizeByDate(records as SkuDbRecord[]));
            addToast("successfully reloaded sku records")
        } catch (e: any) {
            addToast(e.message, toastErrConfig)
        }
        setGetSkuRecordsLoading(false);


    }

    useEffect(() => {

        async function getDbSkuRecords() {
            setGetSkuRecordsLoading(true);
            try {
                const response = await axios.get(BASE_URL + '/aws/sku');
                if (!response) throw new Error("no response given from get sku records from db");
                const { error, success, msg, records } = response.data;
                if (error) throw new Error(error);
                if (!success) throw new Error("unsuccessful operation");
                setDbSkuRecordsByDate(organizeByDate(records as SkuDbRecord[]));
            } catch (e: any) {
                addToast(e.message, toastErrConfig)
            }
            setGetSkuRecordsLoading(false);
        }
        getDbSkuRecords();
        return () => {
            setCurrSkuRecords([]);
            setComparisonRecords([]);
            setDbSkuRecordsByDate({});
            setGetSkuRecordsLoading(false);
        }
    }, [])

    const providerValue: SalesPageContextFields = {
        currSkuRecords, setCurrSkuRecords,
        comparisonRecords, setComparisonRecords,
        dbSkuRecordsByDate, setDbSkuRecordsByDate,
        getSkuRecordsLoading,
        addComparisonRecord,
        delComparisonRecord,
        getDbSkuRecords,
        deleteDbSkuDateRecords
    }

    return (
        <SalesPageContext.Provider value={providerValue}>
            {children}
        </SalesPageContext.Provider>
    )
}

export default SalesPageContextProvider;