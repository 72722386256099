import { SemanticDropdownOpts } from "../../../ts/generic/interfaces"
import { ACTIONS, AdDataRowFromDB, DateRange, SAGA_ACTIONS } from './interfaces'

export function setAppChoices_adDataUtil(appChoices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.adDataUtil_SET_APP_CHOICES,
        payload: appChoices
    }
}

export function setYearChoices_adDataUtil(yearChoices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.adDataUtil_SET_YEAR_CHOICES,
        payload: yearChoices
    }
}

export function setDateRange_adDataUtil(range: DateRange) {
    return {
        type: SAGA_ACTIONS.adDataUtil_SET_DATE_RANGE,
        payload: range
    }
}

export function initAdDataUtil() {
    return {
        type: SAGA_ACTIONS.adDataUtil_INIT_STATE
    }
}

export function setChosenQuery_adDataUtil(queryChoices: string) {
    return {
        type: ACTIONS.adDataUtil_SET_CHOSEN_QUERY,
        payload: queryChoices
    }
}

export function setChosenApps_adDataUtil(appChoices: string[]) {
    return {
        type: ACTIONS.adDataUtil_SET_CHOSEN_APPS,
        payload: appChoices
    }
}

export function setFromDate_adDataUtil(date: Date | string) {
    return {
        type: ACTIONS.adDataUtil_SET_FROM_DATE,
        payload: date
    }
}

export function setToDate_adDataUtil(date: Date | string) {
    return {
        type: ACTIONS.adDataUtil_SET_TO_DATE,
        payload: date
    }
}

export function setStartYear_adDataUtil(appChoices: string) {
    return {
        type: ACTIONS.adDataUtil_SET_START_YEAR,
        payload: appChoices
    }
}

export function setEndYear_adDataUtil(appChoices: string) {
    return {
        type: ACTIONS.adDataUtil_SET_END_YEAR,
        payload: appChoices
    }
}

export function setData_adDataUtil(data: AdDataRowFromDB[]) {
    return {
        type: ACTIONS.adDataUtil_SET_DATA,
        payload: data
    }
}
