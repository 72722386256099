import axios from "axios"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { formatAndCheckDates } from "../../components/reusable/DbSearchOptions/supplement"
import { BASE_URL, toastErrConfig } from "../../helpers/constants"
import { formatDBSheetRowAnalyticsResponse } from "../../helpers/utils"
import { setToast } from "../admin-state/adminActions"
import { setGetDataLoadingAnalyticsTbl } from "../analytics-page/analyticsPageActions"
import { selectUpdateAnalyticsTblProps } from "../analytics-page/analyticsPageSelectors"
import { RdxActionPayload } from "../reduxTypes"
import { handleChangePageAnalyticsTbl, setDataLoadingAnalyticsTbl, setNewSheetDataAnalyticsTbl } from "./analyticsTableActions"
import { selectCurrPageAnalyticsTbl, selectRowsPerPageAnalyticsTbl } from "./analyticsTableSelectors"
import { ACTIONS, AnalyticsTableState, PaginationData, QueryParams, SheetRowAnalytics } from "./interfaces"


function* TostError(error: string) {
    yield put(setToast({ msg: error, config: toastErrConfig }))
    yield put(setDataLoadingAnalyticsTbl(false))
    yield put(setGetDataLoadingAnalyticsTbl(false))
}

function* handleUpdateAnalyticsTbl(action: RdxActionPayload) {
    let paginationData: PaginationData;

    try {
        if (action.type === ACTIONS.UPDATE_SHEET_DATA_ANALYTICS)
            paginationData = {
                currPage: 0,
                rowsPerPage: yield select(selectRowsPerPageAnalyticsTbl)
            }
        else {
            paginationData = {
                currPage: yield select(selectCurrPageAnalyticsTbl),
                rowsPerPage: yield select(selectRowsPerPageAnalyticsTbl)
            }

            if (action.type === ACTIONS.GET_SHEET_DATA_ANALYTICS)
                yield put(setGetDataLoadingAnalyticsTbl(true))
        }

        try {
            let neededParams: QueryParams = yield select(selectUpdateAnalyticsTblProps)
            const { startYear, endYear, fromDate, toDate,
                endYearSecond, startYearSecond, fromDateSecond, toDateSecond,
                chosenTableFields, chosenTable
            } = neededParams

            interface AppNamesAndVersions {
                appName: string,
                appVersions: string[]
            }

            let appNamesAndVersionsArray: AppNamesAndVersions[] = []

            neededParams.chosenApps.forEach((item) => {
                let currAppName = item
                let newEntry: AppNamesAndVersions = { appName: currAppName, appVersions: [] }
                appNamesAndVersionsArray.push(newEntry)
            })

            neededParams.appVersions.forEach((item) => {
                let currAppName = item.substring(item.indexOf('(') + 1, item.length - 1)
                let currAppVersion = item.substring(0, item.indexOf('('))

                let existingAppNameIndex =
                    appNamesAndVersionsArray.findIndex((entry) => {
                        if (entry.appName === currAppName) return true
                        return false
                    })

                if (existingAppNameIndex !== -1)
                    appNamesAndVersionsArray[existingAppNameIndex].appVersions.push(currAppVersion)
            })

            if (!chosenTable || chosenTable === '') {
                yield TostError('Please select a table')
                return
            }

            if (!chosenTableFields || chosenTableFields.length === 0) {
                yield TostError('Please select a table field')
                return
            }

            const dates = formatAndCheckDates(startYear, endYear, fromDate, toDate)

            const secondDates = formatAndCheckDates(startYearSecond, endYearSecond, fromDateSecond, toDateSecond) || {}
            if (dates.endDate === 'err' || secondDates.endDate === 'err') {
                yield put(setToast({
                    msg: "Please make sure years are in correct order, and start date is before end date.",
                    config: toastErrConfig
                }))
                return
            }

            let appNamesAndVersions = JSON.stringify(appNamesAndVersionsArray)
            const url = BASE_URL + '/data/queryanalytics/aggregateanalytics'

            let queries = []
            for (let i = 0; i < chosenTableFields.length; i++) {
                let ch = chosenTableFields[i]
                if (!ch) return
                const optionsParams = { ...neededParams, ...dates, secondDates, getTests: true, chosenTableFields: ch }
                const data = {
                    optionsParams,
                    appNamesAndVersions,
                    ...paginationData
                }
                queries.push(axios.post(url, data))
            }

            yield put(setDataLoadingAnalyticsTbl(true))

            let DBDATA = {
                totalRows: 0,
                sheetData: [] as SheetRowAnalytics[],
                hasNext: false
            }

            yield Promise.all(queries).then((responses) => {
                let Total = 0
                let TotalComparison = 0
                for (let x = 0; x < responses.length; x++) {
                    let field: string = chosenTableFields.length > 1 ? chosenTableFields[x] : ''
                    let res = responses[x]
                    if (!res) return
                    if (res.data) {
                        if (res.data.err || res.data.error) TostError('There was an error getting purchases. Contact developer.')

                        let dbData: AnalyticsTableState = formatDBSheetRowAnalyticsResponse(res.data, field)
                        let { totalRows, sheetData } = dbData

                        if (sheetData.length > 0) {
                            let t = sheetData[sheetData.length - 1]?.totalNum || 0
                            let tc = sheetData[sheetData.length - 1]?.totalNumComparison || 0
                            Total = Total + Number(t)
                            TotalComparison = TotalComparison + Number(tc)
                            sheetData.splice(-1)
                            totalRows = Number(totalRows) - 1
                            DBDATA = {
                                totalRows: DBDATA.totalRows + totalRows,
                                sheetData: [...DBDATA.sheetData, ...sheetData],
                                hasNext: false
                            }
                        }
                    }
                }

                if (DBDATA.sheetData.length > 0)
                    DBDATA = {
                        ...DBDATA,
                        sheetData: [
                            ...DBDATA.sheetData,
                            {
                                selectedFields: 'Total',
                                totalNum: Total,
                                totalNumComparison: TotalComparison
                            }
                        ]
                    }
            })

            if (action.type === ACTIONS.UPDATE_SHEET_DATA_ANALYTICS)
                yield put(setNewSheetDataAnalyticsTbl(DBDATA))
            else yield put(handleChangePageAnalyticsTbl(DBDATA))

            yield put(setDataLoadingAnalyticsTbl(false))
            yield put(setGetDataLoadingAnalyticsTbl(false))
        } catch (e) {
            TostError('There was an error getting purchases. Contact developer.')
        }
    } catch (e) {
        TostError('There was an error getting purchases. Contact developer.')
    }
}

function* interceptUpdateAnalyticsTbl() {
    yield takeLatest(
        [ACTIONS.UPDATE_SHEET_DATA_ANALYTICS, ACTIONS.GET_SHEET_DATA_ANALYTICS],
        handleUpdateAnalyticsTbl
    )
}

export function* analyticsTableSagas() {
    yield all([call(interceptUpdateAnalyticsTbl)])
}
