import { purchaseTblCols } from "../../helpers/constants"
import { SemanticDropdownOpts } from "../../ts/generic/interfaces"
import { TableColumn } from "../purchase-table/interfaces"

export interface PurchasePageState {
    fromDate: string | Date,
    toDate: string | Date,
    chosenApps: string[],
    startYear: string,
    endYear: string,
    appVersions: string[],
    brands: string[],
    quikChoice: string,
    appVersionChoices: AppVersionChoices,
    initLoading: boolean,
    yearChoices: SemanticDropdownOpts[],
    appChoices: SemanticDropdownOpts[],
    brandDropdownChoices: SemanticDropdownOpts[],
    columns: TableColumn[],
    getDataLoading: boolean
}

export const PURCHASE_PAGE_INITIAL_STATE = {
    fromDate: "",
    toDate: "",
    chosenApps: [],
    startYear: "",
    endYear: "",
    appVersions: [],
    brands: [],
    quikChoice: "",
    appVersionChoices: {},
    initLoading: true,
    yearChoices: [],
    appChoices: [],
    brandDropdownChoices: [],
    columns: purchaseTblCols,
    getDataLoading: false
}

export const ACTIONS = {
    SET_FROM_DATE: "SET_FROM_DATE",
    SET_TO_DATE: "SET_TO_DATE",
    SET_CHOSEN_APPS: "SET_CHOSEN_APPS",
    SET_START_YEAR: "SET_START_YEAR",
    SET_END_YEAR: "SET_END_YEAR",
    SET_APP_VERSIONS: "SET_APP_VERSIONS",
    SET_BRANDS: "SET_BRANDS",
    SET_QUIK_CHOICE: "SET_QUIK_CHOICE",
    UPDATE_COLS: "UPDATE_COLS",
    SET_GET_DATA_LOADING: "SET_GET_DATA_LOADING"
}

export const SAGA_ACTIONS = {
    INIT_PURCHASE_PAGE_STATE: "INIT_PURCHASE_PAGE_STATE",
    SET_APP_CHOICES: "SET_APP_CHOICES",
    SET_YEAR_CHOICES: "SET_YEAR_CHOICES",
    SET_INIT_LOADING: "SET_INIT_LOADING",
    SET_APP_VERSION_CHOICES: "SET_APP_VERSION_CHOICES",
    SET_BRAND_CHOICES: "SET_BRAND_CHOICES"
}

interface AppVersionChoices {
    [key: string]: string[]
}
