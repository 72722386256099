import { AppearanceTypes } from 'react-toast-notifications'
import { TableColumnAnalytics } from '../redux/analytics-table/interfaces'
import { PurchaseTableRow, PurchaseTableRowWithLookup, TableColumn } from '../redux/purchase-table/interfaces'
import { AdData, OperatorsHash, ToastOptions } from '../ts/generic/interfaces'
import { splitExpr } from './utils'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REQ_BODY = {
    'device_name': 'test',
    'device_brand': 'test_brand'
}

const comparisonOperatorsHash: OperatorsHash = {
    '<': function (a: number, b: number) { return a < b; },
    '>': function (a: number, b: number) { return a > b; },
    '>=': function (a: number, b: number) { return a >= b; },
    '<=': function (a: number, b: number) { return a <= b; },
    '=': function (a: number, b: number) { return a === b; },
    '<>': function (a: number, b: number) { return a !== b; },
}

const comparisonHashFmtDbQuery: { [key: string]: (a: number) => any } = {
    '<': function (a: number) { return { $lt: a } },
    '>': function (a: number) { return { $gt: a } },
    '>=': function (a: number) { return { $gte: a } },
    '<=': function (a: number) { return { $lte: a } },
    '=': function (a: number) { return { ltv: a } },
    '<>': function (a: number) { return { $ne: a } },
}

const purchaseTblCols: TableColumn[] = [
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "appName",
        "title": "App Name",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "date",
        "title": "Purchase Date",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "cancelDate",
        "title": "Cancel Date",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 110,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "price",
        "title": "Price",
        customFilterAndSearch: (term: string, rowData: PurchaseTableRow): boolean => (rowData.price as number).toString().includes(term),
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 110
        },
        "align": "left",
        "type": "numeric",
        "headerStyle": {
            "width": 110
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 90,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "receiptId",
        "title": "Receipt ID",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "maxWidth": "325px",
            "width": 90
        },
        "headerStyle": {
            "width": 90
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "user.amazonUserId",
        "title": "Amazon User ID",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "itemType",
        "title": "Purchase Type",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 90,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "appVersion",
        "title": "App Version",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 90
        },
        "headerStyle": {
            "width": 90
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 110,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "orig_cost",
        "title": "Ad Cost",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 110
        },
        customSort: (a: PurchaseTableRowWithLookup, b: PurchaseTableRowWithLookup) => {
            if (a.orig_cost === 'n/a' && b.orig_cost !== 'n/a')
                return 0 - parseFloat(b.orig_cost)
            else if (b.orig_cost === 'n/a' && a.orig_cost !== 'n/a')
                return parseFloat(a.orig_cost) - 0;
            else if (b.orig_cost === 'n/a' && a.orig_cost === 'n/a')
                return 0
            else return parseFloat(a.orig_cost) - parseFloat(b.orig_cost)
        },
        customFilterAndSearch: (term: string, rowData: PurchaseTableRow): boolean => {
            if (rowData.rawData && rowData.rawData.includes(term)) return true
            else return false
        },
        "align": "left",
        "type": "numeric",
        "headerStyle": {
            "width": 110
        },
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "sku",
        "title": "SKU",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 90,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "marketplace",
        "title": "Marketplace",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 90
        },
        "headerStyle": {
            "width": 90
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "user.idfa",
        "title": "Amazon IDFA",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 100
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "user.uniqueId",
        "title": "Unique ID of associated user",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 100
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "string"
    },
    {
        "width": 190,
        "field": "user.ltv",
        "title": "LTV of associated user",
        "removable": true,
        "searchable": false,
        "filterPlaceholder": "(<,>,<=,>=,<>,=) ex: <2",
        customFilterAndSearch: (term: string, rowData: any): boolean => {
            const re = /^(<[=>]?|=|>=?)\d+(\.\d{1,3})?$/;
            // do nothing if the regex doesn't match
            if (!re.test(term)) return true
            const [exp, num] = splitExpr(term);
            if (rowData.user) {
                return comparisonOperatorsHash[exp](rowData.user.ltv, num);
            } else {
                return false
            }
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 190
        },
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 190
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "number"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "trafficSource",
        "title": "Traffic Source",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": false,
        "dbType": "string",
    },
    {
        "width": 80,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "trafficName",
        "title": "Traffic Name",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 80
        },
        "headerStyle": {
            "width": 80
        },
        "isAddedColumn": false,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "deviceName",
        "title": "Device Name",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "deviceBrand",
        "title": "Device Brand",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string",
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "autoRenewing",
        "title": "Auto Renewing",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "boolean"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "cancelReason",
        "title": "Cancel Reason",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "number"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "freeTrialEndDate",
        "title": "Free Trial End Date",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "term",
        "title": "Term",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "termSku",
        "title": "Term SKU",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 80,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "isCancelled",
        "title": "Is Cancelled",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 80
        },
        "type": "boolean",
        "headerStyle": {
            "width": 80
        },
        "isAddedColumn": true,
        "fromPurchase": true,
        "dbType": ""
    },
    {
        "width": 80,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "validPurchase",
        "title": "Is Valid Purchase",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 80
        },
        "type": "boolean",
        "headerStyle": {
            "width": 80
        },
        "isAddedColumn": false,
        "dbType": "boolean"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "error",
        "title": "Validation Info",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "hidden": true,
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "totalPurchases",
        "title": "Total Purchases",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "hidden": true,
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": false,
        "dbType": "number"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "mediaSource",
        "title": "Media Source",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": false,
        "dbType": "string",
    },
    {
        "width": 300,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "rawData",
        "title": "Raw Data",
        "removable": true,
        customFilterAndSearch: (term: string, rowData: PurchaseTableRow): boolean => {
            const searchVals = term.split(',');
            if (searchVals.length > 0) {
                let shouldSort = false;
                for (let keyValPair of searchVals) {
                    if (rowData.rawData && rowData.rawData.includes(keyValPair.trim())) {
                        shouldSort = true;
                    } else {
                        shouldSort = false;
                    }
                }
                return shouldSort
            }
            return true
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 300
        },
        "hidden": true,
        "headerStyle": {
            "width": 300
        },
        "isAddedColumn": false,
        "dbType": "rawData"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "campaign_id",
        "title": "Campaign ID",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string",
        hidden: true
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "agency",
        "title": "Ad Agency",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "hidden": true,
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "af_ad_id",
        "title": "Ad Id",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "hidden": false,
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string"
    },
    {
        "width": 100,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "af_siteid",
        "title": "Site Id",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 100
        },
        "hidden": false,
        "headerStyle": {
            "width": 100
        },
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string"
    }
]

const analyticsTblCols: TableColumnAnalytics[] = [
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "selectedFields",
        "title": "Results",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "field": "totalNum",
        "title": "Total",
        "removable": true,
        "searchable": false,
        "filterPlaceholder": "(<,>,<=,>=,<>,=) ex: <2",
        customFilterAndSearch: (term: string, rowData: any): boolean => {
            const re = /^(<[=>]?|=|>=?)\d+(\.\d{1,3})?$/
            if (!re.test(term)) return true
            const [exp, num] = splitExpr(term)
            if (rowData.totalNum) {
                let number = rowData.totalNum
                return comparisonOperatorsHash[exp](number, num)
            } else return false
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    }
]

const analyticsComparisonTblCols: TableColumnAnalytics[] = [
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "selectedFields",
        "title": "Results",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120
        },
        "headerStyle": {
            "width": 120
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "field": "totalNum",
        "title": "date1",
        "removable": true,
        "searchable": false,
        "filterPlaceholder": "(<,>,<=,>=,<>,=) ex: <2",
        customFilterAndSearch: (term: string, rowData: any): boolean => {
            const re = /^(<[=>]?|=|>=?)\d+(\.\d{1,3})?$/
            if (!re.test(term)) return true
            const [exp, num] = splitExpr(term)
            if (rowData.totalNum) {
                let number = rowData.totalNum
                return comparisonOperatorsHash[exp](number, num)
            } else return false
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120,
            "backgroundColor": 'rgba(120,205,254,0.2)'
        },
        "filterCellStyle": {
            "border": "1px solid #cccccc",
            "backgroundColor": 'rgba(120,205,254,0.2)'
        },
        "headerStyle": {
            "width": 120,
            "border": "1px solid #cccccc",
            "backgroundColor": 'rgba(120,205,254,0.2)'
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 120,
        "hiddenByColumnsButton": true,
        "field": "totalNumComparison",
        "title": "date2",
        "removable": true,
        "searchable": false,
        "filterPlaceholder": "(<,>,<=,>=,<>,=) ex: <2",
        customFilterAndSearch: (term: string, rowData: any): boolean => {
            const re = /^(<[=>]?|=|>=?)\d+(\.\d{1,3})?$/
            if (!re.test(term)) return true
            const [exp, num] = splitExpr(term)
            if (rowData.totalNumComarasion) {
                let number = rowData.totalNumComarasion
                return comparisonOperatorsHash[exp](number, num)
            } else return false
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 120,
            "backgroundColor": 'rgba(219,40,40,0.2)'
        },
        "filterCellStyle": {
            "border": "1px solid #cccccc",
            "backgroundColor": 'rgba(219,40,40,0.2)'
        },
        "headerStyle": {
            "width": 120,
            "border": "1px solid #cccccc",
            "backgroundColor": 'rgba(219,40,40,0.2)'
        },
        "isAddedColumn": false,
        "dbType": "string"
    }
]

const testPurchaseTblCols: TableColumn[] = [
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "appName",
        "title": "App Name",
        "removable": true,
        "headerStyle": {
            "width": 125
        },
        "cellStyle": {
            "width": 125,
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc"
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "date",
        "title": "Purchase Date",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "cancelDate",
        "title": "Cancel Date",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "price",
        "title": "Price",
        customSort: (a: number, b: number) => a - b,
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "align": "left",
        "type": "numeric",
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "receiptId",
        "title": "Receipt ID",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "maxWidth": "325px",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "user.amazonUserId",
        "title": "Amazon User ID",
        "removable": true,
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 125
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125,
            "maxWidth": 300
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "itemType",
        "title": "Purchase Type",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "appVersion",
        "title": "App Version",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "orig_cost",
        "title": "Ad Cost",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        customSort: (a: PurchaseTableRowWithLookup, b: PurchaseTableRowWithLookup) => {
            if (a.orig_cost === 'n/a' && b.orig_cost !== 'n/a') {
                return 0 - parseFloat(b.orig_cost);
            } else if (b.orig_cost === 'n/a' && a.orig_cost !== 'n/a') {
                return parseFloat(a.orig_cost) - 0;
            } else if (b.orig_cost === 'n/a' && a.orig_cost === 'n/a') {
                return 0
            } else {
                return parseFloat(a.orig_cost) - parseFloat(b.orig_cost)
            }
        },
        "align": "left",
        "type": "numeric",
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "sku",
        "title": "SKU",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "marketplace",
        "title": "Marketplace",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "user.idfa",
        "title": "Amazon IDFA",
        "removable": true,
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 125
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "user.uniqueId",
        "title": "Unique ID of associated user",
        "removable": true,
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 125
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "string"
    },
    {
        "width": 125,
        "field": "user.ltv",
        "title": "LTV of associated user",
        "removable": true,
        "searchable": false,
        "filterPlaceholder": "(<,>,<=,>=,<>,=) ex: <2",
        customFilterAndSearch: (term: string, rowData: any): boolean => {
            const re = /^(<[=>]?|=|>=?)\d+(\.\d{1,3})?$/;
            // do nothing if the regex doesn't match
            if (!re.test(term)) return true
            const [exp, num] = splitExpr(term);
            if (rowData.user) {
                return comparisonOperatorsHash[exp](rowData.user.ltv, num);
            } else {
                return false
            }
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "headerStyle": {
            "fontStyle": "italic",
            "color": "red",
            "width": 125
        },
        "isAddedColumn": true,
        "fromUser": true,
        "dbType": "number"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "trafficSource",
        "title": "Traffic Source",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "trafficName",
        "title": "Traffic Name",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "deviceName",
        "title": "Device Name",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": false,
        "field": "deviceBrand",
        "title": "Device Brand",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "autoRenewing",
        "title": "Auto Renewing",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "boolean"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "cancelReason",
        "title": "Cancel Reason",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "number"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "freeTrialEndDate",
        "title": "Free Trial End Date",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "term",
        "title": "Term",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "termSku",
        "title": "Term SKU",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "isCancelled",
        "title": "Is Cancelled",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "type": "boolean",
        "isAddedColumn": true,
        "fromPurchase": true,
        "dbType": ""
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "testTransaction",
        "title": "Is Test",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "type": "boolean",
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "boolean"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "validPurchase",
        "title": "Is Valid Purchase",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "type": "boolean",
        "isAddedColumn": false,
        "dbType": "boolean",
        "hidden": true
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "error",
        "title": "Validation Info",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "string"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "totalPurchases",
        "title": "Total Purchases",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },

        "hidden": true,
        "isAddedColumn": false,
        "dbType": "number"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "mediaSource",
        "title": "Media Source",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": false,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 300,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "rawData",
        "title": "Raw Data",
        "removable": true,
        customFilterAndSearch: (term: string, rowData: PurchaseTableRow): boolean => {
            return true
        },
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 300
        },
        "hidden": true,
        "isAddedColumn": false,
        "dbType": "rawData"
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "campaign_id",
        "title": "Campaign ID",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string",
        "hidden": true
    },
    {
        "width": 125,
        "hiddenByColumnsButton": true,
        "searchable": true,
        "field": "agency",
        "title": "Ad Agency",
        "removable": true,
        "cellStyle": {
            "wordWrap": "anywhere",
            "whiteSpace": "normal",
            "border": "1px solid #cccccc",
            "width": 125
        },
        "hidden": true,
        "isAddedColumn": true,
        "fromRawData": true,
        "dbType": "string"
    }
]

const adDataUtilColumns = [
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "appName",
        title: "App Name",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 50,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "queryId",
        title: "ID",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "roi",
        title: "ROI",
        removable: true,
        customSort: (a: AdData, b: AdData) => {
            let first = 0, second = 0;
            if (a.roi && a.roi !== "0.00%") {
                first = parseFloat(a.roi.split("%")[0]);
            }
            if (b.roi && b.roi !== "0.00%") {
                second = parseFloat(b.roi.split("%")[0]);
            }

            return first - second
        },
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 50,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "totalInstalls",
        title: "Total Installs",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        type: 'numeric'
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "costPerInstall",
        title: "Cost Per Install",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        type: 'numeric'
    },
    {
        width: 50,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "totalTrials",
        title: "Total # of Trials",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        type: 'numeric'

    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "trialsPerInstall",
        title: "Trials / Install",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "installCost",
        title: "Install Cost",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 45,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "totalRevenue",
        title: "Total Revenue",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "conversionRate",
        title: "Conversion Rate",
        customSort: (a: AdData, b: AdData) => {
            let first = 0, second = 0;
            if (a.roi && a.roi !== "0.00%") {
                first = parseFloat(a.roi.split("%")[0]);
            }
            if (b.roi && b.roi !== "0.00%") {
                second = parseFloat(b.roi.split("%")[0]);
            }

            return first - second
        },
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "profit",
        title: "Profit/Loss",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },

    {
        width: 35,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "currentTrials",
        title: "Current Trials",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        hidden: true
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "conversions",
        title: "Conversions",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "cancellationsDuringTrial",
        title: "# of cancellations during trial",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        hidden: true
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "cancelledAfterConversion",
        title: "# of cancellations after conversion",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        hidden: true
    },
    {
        width: 40,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "currentConvertedSubscriptions",
        title: "# of current converted subscriptions",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
        hidden: true
    },

    {
        width: 35,
        hiddenByColumnsButton: true,
        searchable: true,
        field: "totalCancelled",
        title: "Total Cancellations",
        removable: true,
        cellStyle: {
            wordWrap: "anywhere",
            whiteSpace: "normal",
            border: "1px solid #cccccc",
        },
    }
]

const lookupDropdowns = [
    "appName",
    "marketplace",
    "appVersion",
    "deviceBrand",
    "itemType",
    "sku",
    "trafficName",
    "trafficSource"
]

const adDataQueryOptions = [
    { key: "1", text: "By Site ID (app containing ad)", value: "siteId" },
    { key: "2", text: "By campaign ID", value: "campaignId" },
    { key: "3", text: "By Ad ID", value: "adId" }
]

const TABLES = {
    PURCHASE_TBL: 'PURCHASE_TBL',
    TEST_PURCHASE_TBL: 'TEST_PURCHASE_TBL'
}

const PAGES = {
    PURCHASE: "PURCHASE",
    ANALYTICS: "ANALYTICS",
    TEST_PURCHASE: "TEST_PURCHASE"
}

const quickChoices = [
    { key: 1, text: 'Last 30 days', value: 'Last 30 days' },
    { key: 2, text: 'Last 7 days', value: 'Last 7 days' },
    { key: 3, text: 'Yesterday', value: 'Yesterday' }
]

const toastErrConfig: ToastOptions = { appearance: 'error', autoDismiss: true }
const toastWarningConfig: ToastOptions = { appearance: 'error', autoDismiss: true }
const toastInfoConfig: ToastOptions = { appearance: 'info', autoDismiss: true }
const blankToast = { msg: "", config: { appearance: ('info' as AppearanceTypes), autoDismiss: true } }

export {
    BASE_URL, REQ_BODY,
    purchaseTblCols,
    analyticsTblCols,
    analyticsComparisonTblCols,
    testPurchaseTblCols,
    lookupDropdowns,
    quickChoices,
    adDataUtilColumns,
    adDataQueryOptions,
    toastErrConfig,
    toastWarningConfig,
    toastInfoConfig,
    blankToast,
    TABLES, PAGES,
    comparisonHashFmtDbQuery
}
