import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Button } from 'semantic-ui-react'
import { blankToast } from '../helpers/constants'
import { setAuthed, setToast } from '../redux/admin-state/adminActions'
import { Toast } from '../redux/admin-state/interfaces'
import { RdxActionPayload, RootState } from '../redux/reduxTypes'
import s from './ActionPanel.module.scss'

type ActionPanelProps = {
    setAuthed: (bool: boolean) => RdxActionPayload,
    setToast: (t: Toast) => RdxActionPayload,
    toast: Toast
}

function ActionPanel({ toast, setAuthed, setToast }: ActionPanelProps) {
    const history = useHistory()
    const location = useLocation()
    const activebg = { backgroundColor: '#1a0080' }
    const { pathname } = location

    function logout() {
        setAuthed(false)
        localStorage.removeItem('_id')
        localStorage.removeItem('token')
        history.push('/login')
    }

    const { addToast } = useToasts()

    useEffect(() => {
        if (toast.msg) {
            addToast(toast.msg, toast.config)
            setToast(blankToast);
        }
    }, [toast, addToast, setToast])

    return (
        <div className={s.wrap}>
            <div className={s.btnWrap}>
                <Button
                    style={pathname === '/home' ? activebg : {}}
                    onClick={() => history.push('/home')}
                >
                    Home
                </Button>
                <Button
                    name='/daterange'
                    onClick={() => history.push('/home/purchases')}
                    style={pathname === '/home/purchases' ? activebg : {}}
                >
                    Query Purchases
                </Button>
                <Button
                    onClick={() => history.push('/home/analytics')}
                    style={pathname === '/home/analytics' ? activebg : {}}
                >
                    Analytics
                </Button>
                <Button
                    onClick={() => history.push('/home/testpurchases')}
                    style={pathname === '/home/testpurchases' ? activebg : {}}

                >
                    Query Test Purchases
                </Button>
                <Button
                    onClick={() => history.push('/home/datautils')}
                    style={pathname === '/home/datautils' ? activebg : {}}
                >
                    Data Utilities
                </Button>
                <Button
                    onClick={() => history.push('/home/sales')}
                    style={pathname === '/home/sales' ? activebg : {}}
                >
                    AWS Direct Data
                </Button>
                <Button onClick={logout} >
                    Logout
                </Button>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    setAuthed: (bool: boolean) => setAuthed(bool),
    setToast: (t: Toast) => setToast(t)
}

const mapStateToProps = (rootState: RootState) => {
    const { toast } = rootState.admin
    return { toast }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPanel)
