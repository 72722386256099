import Papa from 'papaparse'
import { SalesRecord } from "../components/pages/SalesPage/SalesCSVUploader"
import { AnalyticsTableState, SheetRowAnalytics } from "../redux/analytics-table/interfaces"
import { EditableSheetRow, PurchaseTableRow, PurchaseTableRowWithLookup, TableColumn, TableFilter, UnformattedSheetRow } from "../redux/purchase-table/interfaces"
import { NewDataDbResponseAnalytics, SemanticDropdownOpts } from "../ts/generic/interfaces"
import { lookupDropdowns } from "./constants"

export function createYearOptions(oldest: string, newest: string): any[] {
    let opts = []
    for (let i = parseInt(oldest); i <= parseInt(newest); i++) {
        opts.push({ key: i.toString(), text: i.toString(), value: i.toString() })
    }
    return opts
}

export function createAppOptions(appsArray: string[]): any[] {
    return appsArray.sort((a, b) => a.localeCompare(b)).map((app, i) => {
        return {
            key: i.toString(),
            text: app,
            value: app
        }
    })
}

export function getDropdownOpts(appsArray: string[]): SemanticDropdownOpts[] | [] {
    if (appsArray.length > 0) {
        return appsArray
            .filter((app: string) => app)
            .sort((a: string, b: string) => a.localeCompare(b)).map((app, i) => {
                return {
                    key: i.toString(),
                    text: app,
                    value: app
                }
            })
    } else return []
}

export function getQuickDates() {
    let dayBefore, seven, thirty;
    const today = new Date();

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 1);
    const lateYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59);
    dayBefore = [yesterday, lateYesterday];

    const weekAgo = new Date(today);
    weekAgo.setDate(weekAgo.getDate() - 7.0138889);
    const earlyWeekAgoTime = new Date(weekAgo.getFullYear(), weekAgo.getMonth(), weekAgo.getDate(), 0, 0, 1)
    seven = [earlyWeekAgoTime, today];

    const monthAgo = new Date(today);
    monthAgo.setDate(monthAgo.getDate() - 30);
    monthAgo.setHours(0, 0, 1);
    thirty = [monthAgo, today];

    return { dayBefore, seven, thirty }
}

export function formatDBSheetRow(data: UnformattedSheetRow[]): PurchaseTableRow[] {
    let result: PurchaseTableRow[] = []
    if (Array.isArray(data) && data.length > 0)
        result = data.map((purchase: UnformattedSheetRow): PurchaseTableRow => {
            if (purchase.rawData) {
                if (typeof (purchase.rawData) !== 'string' && purchase.rawData.data) {
                    let rawData = purchase.rawData.data;
                    if (rawData.orig_cost) (purchase as any as PurchaseTableRow).orig_cost = rawData.orig_cost;
                    else (purchase as any as PurchaseTableRow).orig_cost = "n/a"
                    if (rawData.campaign_id) (purchase as any as PurchaseTableRow).campaign_id = rawData.campaign_id;
                    if (rawData.agency) (purchase as any as PurchaseTableRow).agency = rawData.agency;
                    if (rawData.af_ad_id) (purchase as any as PurchaseTableRow).af_ad_id = rawData.af_ad_id;
                    if (rawData.af_siteid) (purchase as any as PurchaseTableRow).af_siteid = rawData.af_siteid;
                }
                if (purchase.cancelDate) (purchase as any as PurchaseTableRow).isCancelled = true;
                else (purchase as any as PurchaseTableRow).isCancelled = false;
                (purchase as any as PurchaseTableRow).rawData = JSON.stringify(purchase.rawData.data);
                if (purchase.price && isNumeric(purchase.price))
                    (purchase as any as PurchaseTableRow).price = parseFloat(purchase.price);
            }
            return (purchase as any as PurchaseTableRow);
        })
    return result
}

export function formatAllDataForCsv(data: UnformattedSheetRow[]) {
    const rows = formatDBSheetRow(data);
    let result: any[] = []
    if (Array.isArray(rows) && rows.length > 0)
        result = rows.map(row => {
            const { user, _id, __v, ...restOfRow } = row
            const { amazonUserId, idfa, uniqueId } = user
            let newRow = {
                ...restOfRow,
                amazonUserId, idfa, uniqueId,
                user
            }
            return newRow
        })
    return result
}

export function formatDBSheetRowAnalytics(dbResponse: string): SheetRowAnalytics[] {
    const parsed = JSON.parse(dbResponse)
    const result: SheetRowAnalytics[] = []
    for (let key of Object.keys(parsed[0])) {
        if (parsed[0][key][0]) {
            let n = parsed[0][key][0]._id
            let count = parsed[0][key][0].count
            result.push({ selectedFields: n.replaceAll('__', '.'), totalNum: count })
        }
        else result.push({ selectedFields: key.replaceAll('__', '.'), totalNum: 0 })
    }
    return result
}

export function formatDBSheetRowAnalyticsResponse(dbData: NewDataDbResponseAnalytics, chosenTableField: string): AnalyticsTableState {
    chosenTableField = chosenTableField || ''
    let { totalRows, sheetData = undefined, comparisonSheetData = undefined, hasNext } = dbData
    const sheetDataParsed = sheetData ? JSON.parse(sheetData) : []
    const comparisonParsed = comparisonSheetData ? JSON.parse(comparisonSheetData) : []

    let resultSheet: SheetRowAnalytics[] = []
    let resultComparison: SheetRowAnalytics[] = []

    if (sheetDataParsed.length > 0)
        for (let key of Object.keys(sheetDataParsed[0])) {
            let row = sheetDataParsed[0][key][0] || null
            if (row) {
                let n = row._id
                let count = row.count
                let selectedFields = chosenTableField ? `${n.replaceAll('__', '.')} (${chosenTableField})` : n.replaceAll('__', '.')
                resultSheet.push({ selectedFields, totalNum: count })
            }
            else if (Array.isArray(comparisonParsed) && comparisonParsed.length > 0) {
                let selectedFields = chosenTableField ? `${key.replaceAll('__', '.')} (${chosenTableField})` : key.replaceAll('__', '.')
                resultSheet.push({ selectedFields, totalNum: 0 })
            }
        }

    if (comparisonParsed.length > 0)
        for (let key of Object.keys(comparisonParsed[0])) {
            let row = comparisonParsed[0][key][0] || null
            if (row) {
                let n = row._id
                let count = row.count
                let selectedFields = chosenTableField ? `${n.replaceAll('__', '.')} (${chosenTableField})` : n.replaceAll('__', '.')
                resultComparison.push({ selectedFields, totalNum: count })
            }
            else {
                let selectedFields = chosenTableField ? `${key.replaceAll('__', '.')} (${chosenTableField})` : key.replaceAll('__', '.')
                resultComparison.push({ selectedFields, totalNum: 0 })
            }
        }

    let resultSheetData: SheetRowAnalytics[] = []

    if (resultSheet.length > 0 && resultComparison.length > 0) {
        resultSheet.forEach((r: SheetRowAnalytics) => {
            let key = r.selectedFields
            let c = resultComparison.find(rc => rc.selectedFields === key) || { selectedFields: '__', totalNum: 0 }
            if (Number(r?.totalNum || 0) > 0 || Number(c?.totalNum || 0) > 0)
                resultSheetData.push({ ...r, totalNumComparison: c.totalNum })
        })
    } else resultSheetData = resultSheet

    return {
        totalRows,
        sheetData: resultSheetData,
        hasNext
    }
}

type AccordionOpts = { [x: string]: string[] }
export function getLookupPanels(baseTableColumns: TableColumn[], sheetData: PurchaseTableRow[]): AccordionOpts {
    let opts: AccordionOpts = {}
    baseTableColumns.forEach((col: TableColumn) => {
        if (lookupDropdowns.includes(col.field)) {
            const lookupVals = [...new Set(sheetData.map((p: PurchaseTableRow): any => p[col.field as keyof EditableSheetRow]))]
            opts[col.field] = lookupVals
        }
    })
    return opts
}

export function camel2title(camelCase: string) {
    return camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim()
}

export function insertLookupOpts(baseTableColumns: TableColumn[], sheetData: PurchaseTableRow[]) {
    const cols = baseTableColumns.map((col: TableColumn) => {
        if (lookupDropdowns.includes(col.field)) {
            const lookupVals = [...new Set(sheetData.map((p: PurchaseTableRow): any => p[col.field as keyof EditableSheetRow]))]
            for (let i = 0; i < lookupVals.length; i++) {
                for (let j = 0; j < sheetData.length; j++) {
                    if (sheetData[j][col.field as keyof EditableSheetRow] === lookupVals[i])
                        (sheetData[j] as unknown as PurchaseTableRowWithLookup)[col.field as keyof EditableSheetRow] = i
                }
            }
            col.lookup = { ...lookupVals }
            return col
        }
        return col
    });
    let newSheetData = sheetData.map(row => ({ ...row }))
    return { cols, newSheetData }
}

export function insertLookupOptsTestTbl(baseTableColumns: TableColumn[], sheetData: PurchaseTableRow[]) {
    const cols = baseTableColumns.map((col: TableColumn) => {
        if (lookupDropdowns.includes(col.field)) {
            const lookupVals = [...new Set(sheetData.map((p: PurchaseTableRow): any => p[col.field as keyof EditableSheetRow]))]
            for (let i = 0; i < lookupVals.length; i++) {
                for (let j = 0; j < sheetData.length; j++) {
                    if (sheetData[j][col.field as keyof EditableSheetRow] === lookupVals[i])
                        (sheetData[j] as unknown as PurchaseTableRowWithLookup)[col.field as keyof EditableSheetRow] = i
                }
            }
            col.lookup = { ...lookupVals }
            return col
        }
        return col
    })
    let newSheetData = sheetData.map(row => ({ ...row }))
    return { cols, newSheetData }
}

export function splitExpr(str: string): [string, number] {
    //let list = ['<','>','<=','>=','=','<>'];
    let pos
    for (let i = 0; i < str.length; i++) {
        if (isNumeric(str[i])) {
            pos = i
            break
        }
    }
    if (pos) {
        const arg = parseFloat(str.substring(pos, str.length))
        return [str.substring(0, pos), arg]
    } else return ["", 0]
}

type CheckInputReturn = { hasBadInput: boolean, msg: string }
export function checkInput(filters: TableFilter[]): CheckInputReturn {
    let hasBadInput = false, msg = ""
    let rawDataFilter, totalPurchasesFilter
    for (let filter of filters) {
        if (filter.column.dbType === "rawData") rawDataFilter = filter
        else if (filter.column.field === "totalPurchases") totalPurchasesFilter = filter
    }
    if (rawDataFilter && !isProperKeyVal(rawDataFilter.value)) {
        hasBadInput = true
        msg = "Raw Data search value is formatted incorrectly and/or has duplicate keys."
    }
    if (totalPurchasesFilter) {
        let { value } = totalPurchasesFilter
        if (!isNumeric(value)) {
            if (value !== 'null') {
                let totalPurchasesMsg = "Total purchases filter must be null or a number."
                hasBadInput = true
                msg = msg.length > 0 ? (msg + "\n" + totalPurchasesMsg) : (totalPurchasesMsg)
            }
        }
    }
    return { hasBadInput, msg }
}

function isProperKeyVal(s: string): boolean {
    try {
        let obj = JSON.parse('{' + s + '}')
        for (let key of Object.keys(obj)) {
            let firstIdx = s.indexOf(key)
            let secondIdx = s.lastIndexOf(key)
            if ((firstIdx > -1 && secondIdx > -1) && firstIdx !== secondIdx) return false
        }
        return true
    } catch (e) {
        return false
    }
}

function isNumeric(str: string) {
    if (typeof str != "string") return false
    return !isNaN(str as any) && !isNaN(parseFloat(str))
}

function randomInteger(max: number): number {
    return Math.floor(Math.random() * (max + 1))
}

export function randomRgbColor(): number[] {
    let r = randomInteger(255)
    let g = randomInteger(255)
    let b = randomInteger(255)
    return [r, g, b]
}

export function randomHexColor(colors: String[]) {
    let [r, g, b] = randomRgbColor()

    let hr = r.toString(16).padStart(2, '0')
    let hg = g.toString(16).padStart(2, '0')
    let hb = b.toString(16).padStart(2, '0')
    let color = "#" + hr + hg + hb
    if (colors.includes(color)) randomHexColor(colors)
    else return color;
}







export function parseSalesCsv(file: File): Promise<Papa.ParseResult<SalesRecord>> {
    return new Promise((resolve, reject) => {
        Papa.parse(file, { complete: resolve, error: reject, header: true, skipEmptyLines: true })
    })
}
export function randomRGBAColor(colors: String[]) {
    let [r, g, b] = randomRgbColor()

    let color = `rgba(${r}, ${g}, ${b}, ${0.7})`
    if (colors.includes(color)) randomHexColor(colors)
    else return color;
}

export function getNameApps(apps: SemanticDropdownOpts[]): SemanticDropdownOpts[] {

    let dropdownoptions: SemanticDropdownOpts[] = [];

    dropdownoptions = apps.map((app: SemanticDropdownOpts) => {
        let text = ``

        switch (app.value) {
            case 'com.simlife.simulator':
                text = 'Simulife'
                break;
            case 'com.slimesmash.android':
                text = 'Slime Simulator Games'
                break;
            case 'com.SolidAppsInc.LipstickMakeupGame':
                text = 'Lipstick Makeup Game'
                break;
            case 'com.superslimemultiplayer.techconsolidated':
                text = 'Super Slim Multiplayer'
                break;
            case 'com.techconsolidated.popit3dfidgetgame':
                text = 'Pop It 3D Fidget Game'
                break;
            default:
                text = app.text
                break;
        }
        let newKey = Math.floor(Math.random() * (10000 - 1) + 1);
        return {
            key: newKey.toString(),
            text,
            value: app.value
        }
    })

    return dropdownoptions
}