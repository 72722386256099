import { PurchaseTableState, PURCHASE_TBL_INITIAL_STATE } from '../purchase-table/interfaces'
import { RdxActionPayload } from '../reduxTypes'
import { ACTIONS } from './interfaces'

export const testPurchaseTableReducer = (state: PurchaseTableState = PURCHASE_TBL_INITIAL_STATE, action: RdxActionPayload): PurchaseTableState => {

    switch (action.type) {
        case ACTIONS.TEST_SET_DATA_LOADING:
            return {
                ...state,
                dataLoading: action.payload
            }
        case ACTIONS.TEST_SET_SHEET_DATA:
            return {
                ...state,
                sheetData: action.payload
            }
        default:
            return { ...state }
    }
}
