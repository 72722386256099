import { SemanticDropdownOpts } from '../../ts/generic/interfaces'
import { ACTIONS, SAGA_ACTIONS } from './interfaces'

export function testSetQuikChoice(str: string) {
    return {
        type: ACTIONS.TEST_SET_QUIK_CHOICE,
        payload: str
    }
}

export function testSetBrands(arr: string[]) {
    return {
        type: ACTIONS.TEST_SET_BRANDS,
        payload: arr
    }
}

export function testSetAppVersions(arr: string[]) {
    return {
        type: ACTIONS.TEST_SET_APP_VERSIONS,
        payload: arr
    }
}

export function testSetFromDate(date: Date | string) {
    return {
        type: ACTIONS.TEST_SET_FROM_DATE,
        payload: date
    }
}

export function testSetToDate(date: Date | string) {
    return {
        type: ACTIONS.TEST_SET_TO_DATE,
        payload: date
    }
}

export function testSetChosenApps(arr: string[]) {
    return {
        type: ACTIONS.TEST_SET_CHOSEN_APPS,
        payload: arr
    }
}

export function testSetStartYear(y: string) {
    return {
        type: ACTIONS.TEST_SET_START_YEAR,
        payload: y
    }
}

export function testSetEndYear(y: string) {
    return {
        type: ACTIONS.TEST_SET_END_YEAR,
        payload: y
    }
}

export function initTestPurchasePage() {
    return {
        type: SAGA_ACTIONS.TEST_INIT_PURCHASE_PAGE_STATE
    }
}

export function testSetAppDropdownChoices(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.TEST_SET_AVAILABLE_APPS,
        payload: choices
    }
}

export function testSetYearOptions(options: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.TEST_SET_YEAR_CHOICES,
        payload: options
    }
}

export function testSetAppVersionChoices(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.TEST_SET_APP_VERSION_CHOICES,
        payload: choices
    }
}

export function testSetInitLoading(bool: boolean) {
    return {
        type: SAGA_ACTIONS.TEST_SET_INIT_LOADING,
        payload: bool
    }
}

export function testSetBrandChoices(choices: SemanticDropdownOpts[]) {
    return {
        type: SAGA_ACTIONS.TEST_SET_BRAND_CHOICES,
        payload: choices
    }
}
