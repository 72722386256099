import { createSelector } from 'reselect'
import { RootState } from '../../reduxTypes'

const adData = (rootState: RootState) => rootState.dataUtils.adData

export const selectAppChoices_adDataUtil = createSelector(
    [adData],
    adData => adData.appChoices
)

export const selectYearChoices_adDataUtil = createSelector(
    [adData],
    adData => adData.yearChoices
)

export const selectDateRange_adDataUtil = createSelector(
    [adData],
    adData => adData.dateRange
)

export const selectQueryChoices_adDataUtil = createSelector(
    [adData],
    adData => adData.queryChoices
)

export const selectChosenQuery_adDataUtil = createSelector(
    [adData],
    adData => adData.chosenQuery
)

export const selectChosenApps_adDataUtil = createSelector(
    [adData],
    adData => adData.chosenApps
)

export const selectFromDate_adDataUtil = createSelector(
    [adData],
    (adData): string => {
        if (typeof (adData.fromDate) !== 'string') {
            return adData.fromDate.toISOString()
        }
        return adData.fromDate
    }
)

export const selectToDate_adDataUtil = createSelector(
    [adData],
    (adData): string => {
        if (typeof (adData.toDate) !== 'string') {
            return adData.toDate.toISOString()
        }
        return adData.toDate
    }
)

export const selectStartYear_adDataUtil = createSelector(
    [adData],
    adData => adData.startYear
)

export const selectEndYear_adDataUtil = createSelector(
    [adData],
    adData => adData.endYear
)
