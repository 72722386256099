import { all, call } from 'redux-saga/effects'
import { loginPageSagas } from './admin-state/adminSagas'
import { analyticsPageSagas } from './analytics-page/analyticsPageSagas'
import { analyticsTableSagas } from './analytics-table/analyticsTableSagas'
import { adUtilSagas } from './data-utils/ad-util/adUtilSagas'
import { purchasePageSagas } from './purchase-page/purchasePageSagas'
import { purchaseTableSagas } from './purchase-table/purchaseTableSagas'
import { testPurchasePageSagas } from './test-purchase-page/testPurchasePageSagas'
import { testPurchaseTableSagas } from './test-purchase-table/testPurchaseTableSagas'

export default function* rootSaga() {
    yield all([
        call(loginPageSagas),
        call(purchasePageSagas),
        call(purchaseTableSagas),
        call(analyticsPageSagas),
        call(analyticsTableSagas),
        call(testPurchasePageSagas),
        call(testPurchaseTableSagas),
        call(adUtilSagas)
    ])
}
