import React from 'react'
import { Dropdown, DropdownMenuProps } from 'semantic-ui-react'
import { PurchasePageState } from '../../../../../redux/purchase-page/interfaces'
import { RdxActionPayload } from '../../../../../redux/reduxTypes'
import { SemanticDropdownOpts } from '../../../../../ts/generic/interfaces'
import s from './AppVersionSelector.module.scss'

type Props = {
    initLoading: PurchasePageState["initLoading"],
    chosenApps: PurchasePageState["chosenApps"],
    appVersions: PurchasePageState["appVersions"],
    appVersionChoices: PurchasePageState["appVersionChoices"]
    setAppVersions: (data: string[]) => RdxActionPayload,
}

const AppVersionSelector = ({
    initLoading,
    chosenApps,
    appVersions,
    appVersionChoices,
    setAppVersions
}: Props) => {

    function handleAppVersionDropdownChange(e: React.SyntheticEvent, data: DropdownMenuProps) {
        if (data.value) {
            return setAppVersions(data.value);
        }
    }

    let allVersionChoices: SemanticDropdownOpts[] = [];
    if (chosenApps) {
        for (let i = 0; i < chosenApps.length; i++) {
            const opts = appVersionChoices[chosenApps[i]].map(version => {
                let text = `${version}(${chosenApps[i]})`
                let newKey = Math.floor(Math.random() * (10000 - 1) + 1)
                return {
                    key: newKey.toString(),
                    text,
                    value: text
                }
            })
            allVersionChoices = [...allVersionChoices, ...opts]
        }
    }

    return (
        <div className={s.wrap}>
            <Dropdown
                placeholder='select app version'
                selection
                options={allVersionChoices}
                onChange={handleAppVersionDropdownChange}
                selectOnBlur={false}
                multiple
                loading={initLoading}
                disabled={chosenApps && (chosenApps as string[]).length > 0 ? false : true}
                value={appVersions || []}
                fluid
            />
        </div>
    )
}

export default AppVersionSelector
