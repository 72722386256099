import React, { SyntheticEvent, useState } from 'react'
import { Dropdown } from 'semantic-ui-react';
import DbSkuRecordsTable, { SkuDbRecord } from '../../../reusable/DbSkuRecordsTable';

type Props = {
    skuRecords: SkuDbRecord[]
}

const SkuSelection = ({ skuRecords } : Props) => {
    const [selection, setSelection] = useState<SkuDbRecord | {}>({})

    
    function handleDropdownChange(e: SyntheticEvent<HTMLElement>, dropdownData: any) {
        setSelection(skuRecords.find(record => record.sku === dropdownData.value)!)
    }

    
    return (
        <div>
            <Dropdown
                placeholder="Select sku"
                fluid
                search
                selection
                onChange={handleDropdownChange}
                options={skuRecords.map((skuRecord, idx) => ({
                    key: skuRecord.sku,
                    value: skuRecord.sku,
                    text: skuRecord.sku,
                }))}
            />

            {JSON.stringify(selection) !== "{}" && (
                <DbSkuRecordsTable
                    records={[selection as SkuDbRecord]}
                    titleYear={(selection as SkuDbRecord).year}
                    titleMonth={(selection as SkuDbRecord).month}
                />
            )}
        </div>
    );
};

export default SkuSelection;