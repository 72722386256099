import { Types } from 'mongoose'
import { PurchasePageState } from '../purchase-page/interfaces'

export interface PurchaseTableState {
    sheetData: PurchaseTableRow[],
    dataLoading: boolean,
    totalRows: number | undefined,
    totalLTV?: number,
    rowsPerPage: number,
    currPage: number | undefined,
    lastReceivedDate: string | undefined,
    currFilters: TableFilter[],
    searchByFilter: boolean,
    hasNext: boolean,
    goingForward: boolean
}

export const PURCHASE_TBL_INITIAL_STATE = {
    sheetData: [],
    dataLoading: false,
    totalRows: undefined,
    totalLTV: 0,
    rowsPerPage: 20,
    currPage: 0,
    lastReceivedDate: undefined,
    currFilters: [],
    searchByFilter: true,
    hasNext: false,
    goingForward: true
}

export const ACTIONS = {
    SET_SHEET_DATA: "SET_SHEET_DATA",
    SET_DATA_LOADING: "SET_DATA_LOADING",
    ADD_LOOKUP_OPTS: "ADD_LOOKUP_OPTS",
    SET_SEARCH_BY_FILTER: "SET_SEARCH_BY_FILTER",
    SET_ROWS_PER_PAGE: "SET_ROWS_PER_PAGE",
    SET_CURR_PAGE: "SET_CURR_PAGE",
    UPDATE_SHEET_DATA: "UPDATE_SHEET_DATA",
    CHANGE_PAGE_WHILE_FILTERING: "CHANGE_PAGE_WHILE_FILTERING",
    GET_SHEET_DATA: "GET_SHEET_DATA",
    RESET_LAST_RECEIVED_DATE: "RESET_LAST_RECEIVED_DATE",
    SET_GOING_FORWARD: "SET_GOING_FORWARD"
}

export const SAGA_ACTIONS = {
    SET_CURR_FILTERS: "SET_CURR_FILTERS",
    SET_NEW_SHEET_DATA: "SET_NEW_SHEET_DATA",
    HANDLE_PAGE_CHANGE: "HANDLE_PAGE_CHANGE"
}

export const DUAL_ACTIONS = {
    SET_TOTAL_ROWS: "SET_TOTAL_ROWS",
    CLEAR_TABLE: "CLEAR_TABLE"
}

export interface SheetRow {
    appName: number | null | string,
    appVersion: number | null | string,
    autoRenewing: boolean | null | string,
    cancelDate: string | null,
    cancelReason: string | null,
    date: string,
    deviceBrand: number | null | string,
    deviceName: string | null,
    error: null | string,
    freeTrialEndDate: string | null,
    id: string | undefined | null,
    _id: Types.ObjectId | undefined,
    idfa: string | null,
    itemType: number | null | string,
    price: string | null | number,
    receiptId: string | null,
    renewalDate: string | null,
    sku: number | null | string,
    term: null | string,
    termSku: null | string,
    testTransaction: boolean | null | string,
    totalPurchases: null | number,
    trafficName: number | null | string,
    trafficSource: number | null | string,
    marketplace: number | null | string,
    rawData: RawData | string,
    orig_cost: string,
    campaign_id: string | undefined | null,
    agency: string | undefined | null,
    isCancelled: boolean,
    mediaSource: string | undefined,
    user: User
}

export interface Purchase {
    appName: string,
    appVersion: string,
    autoRenewing: boolean | null,
    cancelDate: string | null,
    cancelReason: number | null,
    date: string,
    deviceBrand: string,
    deviceName: string,
    error: string | undefined,
    freeTrialEndDate: string | null,
    _id: Types.ObjectId,
    idfa: string,
    itemType: string,
    price: string,
    receiptId: string,
    renewalDate: string | null,
    sku: string,
    term: string | null,
    termSku: string | null,
    testTransaction: boolean,
    totalPurchases: number | null,
    trafficName: string,
    trafficSource: string,
    marketplace: string,
    rawData: RawData,
    mediaSource: string,
    user: User,
    validPurchase: boolean
}

export interface User {
    ltv: number,
    purchases: Types.ObjectId[],
    idfa: string,
    amazonUserId: string,
    marketplace: string,
    isTestUser: boolean,
    uniqueId?: string
}

export interface RawData {
    data?: data | any,
    purchaseId: Types.ObjectId,
    createdAt?: string,
    updatedAt?: string
}

interface data {
    orig_cost?: string,
    campaign_id?: string,
    agency?: string | null,
    af_ad_id?: string | null,
    af_siteid?: string | null,
}

export interface UnformattedSheetRow extends Purchase {
    id: Purchase["_id"]
    __v: number
}

export interface PurchaseTableRow extends Omit<UnformattedSheetRow, "rawData" | "price"> {
    orig_cost: string,
    campaign_id?: data["campaign_id"],
    agency?: data["agency"],
    af_ad_id?: data["af_ad_id"],
    af_siteid?: data["af_siteid"],
    isCancelled: boolean,
    rawData: string,
    price: null | number
}

export interface PurchaseTableRowWithLookup extends Omit<PurchaseTableRow, "appName" | "marketplace" | "appVersion" | "deviceBrand" | "itemType" | "sku" | "trafficName" | "trafficSource"> {
    appName: number,
    marketplace: number,
    appVersion: number,
    deviceBrand: number,
    itemType: number,
    sku: number,
    trafficName: number,
    trafficSource: number
}

export interface EditableSheetRow {
    appName: string,
    marketplace: string,
    appVersion: string,
    deviceBrand: string,
    itemType: string,
    sku: string,
    trafficName: string,
    trafficSource: string,
}

export interface TableColumn {
    width?: number | null,
    maxWidth?: number,
    field: string,
    title: string,
    searchable?: boolean,
    hiddenByColumnsButton?: boolean,
    removable?: boolean,
    cellStyle?: any,
    type?: string,
    filterPlaceholder?: string,
    customFilterAndSearch?: (term: string, rowData: PurchaseTableRow) => boolean,
    customSort?: (a: any, b: any) => number,
    lookup?: string[],
    hidden?: boolean,
    filterCellStyle?: any,
    align?: string,
    isAddedColumn: boolean,
    fromRawData?: boolean,
    fromUser?: boolean,
    fromPurchase?: boolean,
    dbType: string,
    headerStyle?: any,
    tableData?: any
}

export interface TableFilter {
    column: TableColumn,
    operator: string,
    value: string
}

export type QueryParams = {
    endYear: PurchasePageState["endYear"],
    startYear: PurchasePageState["startYear"],
    toDate: PurchasePageState["toDate"],
    fromDate: PurchasePageState["fromDate"],
    chosenApps: PurchasePageState["chosenApps"],
    appVersions: PurchasePageState["appVersions"],
    brands: PurchasePageState["brands"],
    getTests?: boolean
}

export type TestPurchQueryParams = {
    startDate: PurchasePageState["toDate"],
    endDate: PurchasePageState["fromDate"],
    chosenApps: PurchasePageState["chosenApps"],
    appVersions: PurchasePageState["appVersions"],
    brands: PurchasePageState["brands"],
    getTests: boolean
}

export type PaginationData = {
    rowsPerPage: PurchaseTableState["rowsPerPage"],
    currPage: PurchaseTableState["currPage"],
    lastReceivedDate: PurchaseTableState["lastReceivedDate"]
}
