import { format } from 'date-fns'
import MaterialTable, { Column } from 'material-table'
import { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Dropdown } from 'semantic-ui-react'
import { analyticsComparisonTblCols, analyticsTblCols } from '../../../helpers/constants'
import { updateColsAnalyticsTbl } from '../../../redux/analytics-page/analyticsPageActions'
import { selectDates, selectSelectedOptions } from '../../../redux/analytics-page/analyticsPageSelectors'
import { updateSheetDataAnalyticsTbl } from '../../../redux/analytics-table/analyticsTableActions'
import { selectDataLoadingAnalyticsTbl, selectHasNextAnalyticsTbl, selectSearchByFilterAnalyticsTbl, selectSheetDataAnalyticsTbl } from '../../../redux/analytics-table/analyticsTableSelectors'
import { AnalyticsTableState, TableColumnAnalytics } from '../../../redux/analytics-table/interfaces'
import { RdxActionPayload, RootState } from '../../../redux/reduxTypes'
import s from './AnalyticsTable.module.scss'
import ChartAnalytics from './ChartAnalytics'

type Props = {
    sheetData: AnalyticsTableState["sheetData"],
    dataLoading: AnalyticsTableState["dataLoading"],
    updateSheetDataAnalyticsTbl: () => RdxActionPayload,
    updateColsAnalyticsTbl: (field: string, hidden: boolean) => RdxActionPayload,
    hasNext: AnalyticsTableState["hasNext"],
    searchByFilter: AnalyticsTableState["searchByFilter"]
    dates: { [key: string]: any },
    selectedOptions: { [key: string]: any }
}

const AnalyticsTable = ({
    sheetData,
    dataLoading,
    dates,
    selectedOptions
}: Props) => {
    let { chosenApps, chosenTable, chosenTableFields } = selectedOptions
    const date1 = `From ${format(dates.fromDate || new Date(), 'd MMMM yyyy')} To ${format(dates.toDate || new Date(), 'd MMMM yyyy')}`
    const date2 = `From ${format(dates.fromDateSecond || new Date(), 'd MMMM yyyy')} To ${format(dates.toDateSecond || new Date(), 'd MMMM yyyy')}`

    const fileName = `analytics_report_${new Date().toLocaleDateString('en-GB').replace(/\//g, '_')}`
    const options = [
        { text: 'Table', value: 'Table' },
        { text: 'Bar Chart Vertical', value: 'Barv' },
        { text: 'Bar Chart Horizontal', value: 'Barh' },
        { text: 'Pie Chart', value: 'Pie' },
    ]
    const [showDataType, setShowDataType] = useState<string>('Table')

    let isComparison = (): boolean => {
        if (sheetData[sheetData.length - 1]?.totalNumComparison) return true
        else return false
    }

    const Title = () => (
        <div className={s.titleContainer}>
            <div className={s.title}>
                <h4>PURCHASES TABLE </h4>
                {!isComparison() && sheetData.length > 0 ? <p style={{ marginLeft: '1rem' }}>{date1}</p> : null}
            </div>
            {sheetData.length > 0 ?
                <div className={s.subTitleContainer}>
                    {Array.isArray(chosenApps) && chosenApps.length > 0 ?
                        <div className={s.subTitles}>
                            <h5>Applications: </h5>
                            {chosenApps.map((a: string) => <p>{a},</p>)}
                        </div>
                        : null}
                    {chosenTable ?
                        <div className={s.subTitles}>
                            <h5>Table: </h5>
                            <p>{chosenTable}</p>
                        </div>
                        : null}
                    {chosenTableFields ?
                        <div className={s.subTitles}>
                            <h5>Field: </h5>
                            {chosenTableFields.map((f: string) => <p>{f},</p>)}
                        </div>
                        : null}
                </div>
                : null}
        </div>
    )

    function getColumns() {
        if (isComparison()) {
            let tblCols = [...analyticsComparisonTblCols]
            tblCols.forEach(c => {
                if (c.title === 'date1') c.title = date1
                if (c.title === 'date2') c.title = date2
            })
            return tblCols as Column<TableColumnAnalytics>[]
        }
        else return analyticsTblCols as Column<TableColumnAnalytics>[]
    }

    return (
        <>
            <div className={s.dropDownType}>
                <Dropdown
                    selection
                    value={showDataType}
                    defaultValue={showDataType}
                    placeholder='Table'
                    options={options}
                    onChange={(e, data: { [key: string]: string }) => data.value && setShowDataType(data.value)}
                    selectOnBlur={false}
                />
            </div>
            {showDataType !== 'Table' ?
                <ChartAnalytics
                    sheetData={sheetData}
                    showDataType={showDataType}
                    date1={date1}
                    date2={date2}
                    Title={Title}
                    dataLoading={dataLoading}
                />
                :
                <div className={s.wrap}>
                    <MaterialTable
                        title={<Title />}
                        columns={getColumns()}
                        data={sheetData as any[]}
                        isLoading={dataLoading}
                        options={{
                            filtering: true,
                            tableLayout: "fixed",
                            columnsButton: false,
                            exportButton: true,
                            exportFileName: fileName,
                            exportAllData: true,
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            search: true,
                            debounceInterval: 750,
                            loadingType: "overlay"
                        }}
                    />
                </div>
            }
        </>
    )
}

type SelectorProps = Pick<Props, "sheetData" | "dataLoading" | "hasNext" | "searchByFilter" | "dates" | "selectedOptions">

const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    sheetData: selectSheetDataAnalyticsTbl,
    dataLoading: selectDataLoadingAnalyticsTbl,
    hasNext: selectHasNextAnalyticsTbl,
    searchByFilter: selectSearchByFilterAnalyticsTbl,
    dates: selectDates,
    selectedOptions: selectSelectedOptions
})

const mapDispatchToProps = {
    updateSheetDataAnalyticsTbl: () => updateSheetDataAnalyticsTbl(),
    updateColsAnalyticsTbl: (field: string, hidden: boolean) => updateColsAnalyticsTbl(field, hidden),
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTable)
