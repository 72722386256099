import to from 'await-to-js'
import axios from 'axios'
import { SyntheticEvent, useState } from 'react'
import { connect } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { createStructuredSelector } from 'reselect'
import { Button } from 'semantic-ui-react'
import { BASE_URL, toastErrConfig, toastInfoConfig } from '../../../helpers/constants'
import { setData_adDataUtil } from '../../../redux/data-utils/ad-util/adUtilActions'
import { selectChosenApps_adDataUtil, selectChosenQuery_adDataUtil, selectEndYear_adDataUtil, selectFromDate_adDataUtil, selectStartYear_adDataUtil, selectToDate_adDataUtil } from '../../../redux/data-utils/ad-util/adUtilSelectors'
import { AdDataRowFromDB, AdUtilState } from '../../../redux/data-utils/ad-util/interfaces'
import { RdxActionPayload, RootState } from '../../../redux/reduxTypes'
import s from './AdDataUtil.module.scss'
import AdDataAppDropdown from './AdDataUtil/AdDataAppDropdown'
import AdDataDisplay from './AdDataUtil/AdDataDisplay'
import AdDataPicker from './AdDataUtil/AdDataPicker'
import AdDataQueryDropdown from './AdDataUtil/AdDataQueryDropdown'

type Props = {
    startYear: AdUtilState["startYear"],
    endYear: AdUtilState["endYear"],
    toDate: string,
    fromDate: string,
    chosenApps: AdUtilState["chosenApps"],
    chosenQuery: AdUtilState["chosenQuery"],
    setData: (data: AdDataRowFromDB[]) => RdxActionPayload
}

const AdDataUtil = ({
    startYear,
    endYear,
    toDate,
    fromDate,
    chosenApps,
    chosenQuery,
    setData
}: Props) => {

    const { addToast } = useToasts()
    const [dataLoading, setDataLoading] = useState<boolean>(false)

    async function queryDb(e: SyntheticEvent) {
        try {
            if (endYear && startYear) {
                if (endYear < startYear) return addToast('end year must be after start year', toastErrConfig)
                if (+new Date(toDate) < +new Date(fromDate)) return addToast('end date must be after start date', toastErrConfig)
            }

            setDataLoading(true)
            const url = BASE_URL + '/data/marketing'
            let startDate = "", endDate = ""
            if (fromDate) startDate = fromDate

            if (toDate) endDate = toDate
            const data = {
                startDate,
                endDate,
                chosenApps,
                getTests: false,
                chosenQuery
            }

            addToast("It may take a while to fetch the data...", toastInfoConfig)

            const [err, res] = await to(axios.post(url, data))
            if (err) addToast('Error querying server.', toastErrConfig)
            else {
                if (res) {
                    if (res.data.data) {
                        setData(res.data.data.map((row: AdDataRowFromDB) => {
                            return {
                                ...row,
                                appName: row.appName[0],
                                roi: parseFloat((row.roi * 100).toFixed(2)).toString() + "%",
                                costPerInstall: parseFloat(row.costPerInstall.toFixed(2)),
                                trialsPerInstall: parseFloat(row.trialsPerInstall.toFixed(2)),
                                conversionRate: row.conversionRate.toFixed(2) + "%",
                                profit: parseFloat(row.profit.toFixed(2)),
                                installCost: parseFloat(row.installCost.toFixed(2)),
                                totalRevenue: parseFloat(row.totalRevenue.toFixed(2))
                            }
                        }))
                    }
                }
            }
            setDataLoading(false)
        } catch (e: any) {
            addToast(`Error in query function: ${e.message}`, toastErrConfig)
        }
    }

    return (
        <div className={s.wrap}>
            <h2>Get Ad Data (vungle)</h2>
            <h3>Directions/Important Info</h3>
            <ul>
                <li>Leaving all fields blank will get all data for Wissam's slimesmash app, from May 27, 2021 and up till now.</li>
                <li>There is currently no advertisement data for the simlife and techconsolidated app</li>
                <li>All data records on the below table use data from May 27, 2021 and onward; data before this date is not available</li>
                <li>Install data (cost, cost per install, etc.) is calculated from data up until yesterday; it does not include today's install data</li>
            </ul>
            <div className={s.mainUtilWrap}>
                <div className={s.top}>
                    <div className={s.choices}>
                        <AdDataQueryDropdown />
                        <AdDataAppDropdown />
                        <AdDataPicker />
                    </div>
                    <Button
                        color="green"
                        loading={dataLoading}
                        onClick={queryDb}
                        disabled={!chosenQuery}
                    >
                        Get Data
                    </Button>
                </div>
                <AdDataDisplay />
            </div>
        </div>
    )
}

type SelectorProps = Omit<Props, "setData">
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    startYear: selectStartYear_adDataUtil,
    endYear: selectEndYear_adDataUtil,
    toDate: selectToDate_adDataUtil,
    fromDate: selectFromDate_adDataUtil,
    chosenApps: selectChosenApps_adDataUtil,
    chosenQuery: selectChosenQuery_adDataUtil
})

const mapDispatch = {
    setData: (data: AdDataRowFromDB[]) => setData_adDataUtil(data)
}

export default connect(mapStateToProps, mapDispatch)(AdDataUtil)
