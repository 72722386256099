import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL } from '../../helpers/constants'
import { createYearOptions, getDropdownOpts } from '../../helpers/utils'
import { setAppChoices_adDataUtil, setYearChoices_adDataUtil } from '../data-utils/ad-util/adUtilActions'
import { setAppChoicesAnalyticsTbl, setAppVersionChoicesAnalyticsTbl, setEndYearAnalyticsTbl, setFromDateAnalyticsTbl, setInitLoadingAnalyticsTbl, setStartYearAnalyticsTbl, setTableChoicesAnalyticsTbl, setTableDistinctFieldsChoicesAnalyticsTbl, setTableFieldsChoicesAnalyticsTbl, setToDateAnalyticsTbl, setYearOptionsAnalyticsTbl } from './analyticsPageActions'
import { SAGA_ACTIONS } from './interfaces'

function* handleInitState() {
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
    }

    try {
        const res: AxiosResponse = yield call(axios.get, (BASE_URL + '/data/dboptions'), config)
        const { appNames, recentYear, oldestYear, appVersionsData, tableNames, tableFieldData, distinctFieldData } = res.data
        const now = new Date()
        const earlyToday = new Date(parseInt(recentYear), now.getMonth(), now.getDate(), 0, 0, 1)
        const appDropdownOpts = getDropdownOpts(appNames)
        const yearDropdownOpts = createYearOptions(oldestYear, recentYear)
        const tableDropdownOpts = getDropdownOpts(tableNames)

        yield put(setAppVersionChoicesAnalyticsTbl(appVersionsData))
        yield put(setYearOptionsAnalyticsTbl(createYearOptions(oldestYear, recentYear)))
        yield put(setTableChoicesAnalyticsTbl(tableDropdownOpts))
        yield put(setTableFieldsChoicesAnalyticsTbl(tableFieldData))
        yield put(setTableDistinctFieldsChoicesAnalyticsTbl(distinctFieldData))
        yield put(setAppChoicesAnalyticsTbl(appDropdownOpts))
        yield put(setFromDateAnalyticsTbl(earlyToday))
        yield put(setToDateAnalyticsTbl(now))
        yield put(setStartYearAnalyticsTbl(recentYear))
        yield put(setEndYearAnalyticsTbl(recentYear))
        yield put(setInitLoadingAnalyticsTbl(false))
        yield put(setAppChoices_adDataUtil(appDropdownOpts.filter(choice => choice.text === "com.slimesmash.android" ? true : false)))
        yield put(setYearChoices_adDataUtil(yearDropdownOpts))
    } catch (e) {
        yield console.log(e)
    }
}

function* interceptGetState() {
    yield takeLatest(
        [SAGA_ACTIONS.INIT_ANALYTICS_PAGE_STATE],
        handleInitState
    )
}

export function* analyticsPageSagas() {
    yield all([
        call(interceptGetState)
    ])
}
