import { ACTIONS, AnalyticsTableState, DUAL_ACTIONS, SAGA_ACTIONS } from './interfaces'

export function setDataLoadingAnalyticsTbl(bool: boolean) {
    return {
        type: ACTIONS.SET_DATA_LOADING_ANALYTICS,
        payload: bool
    }
}

export function setRowsPerPageAnalyticsTbl(n: number) {
    return {
        type: ACTIONS.SET_ROWS_PER_PAGE_ANALYTICS,
        payload: n
    }
}

export function setCurrPageAnalyticsTbl(n: number) {
    return {
        type: ACTIONS.SET_CURR_PAGE_ANALYTICS,
        payload: n
    }
}

export function updateSheetDataAnalyticsTbl() {
    return {
        type: ACTIONS.UPDATE_SHEET_DATA_ANALYTICS
    }
}

export function setSearchByFilterAnalyticsTbl(bool: boolean) {
    return {
        type: ACTIONS.SET_SEARCH_BY_FILTER_ANALYTICS,
        payload: bool
    }
}

export function getSheetDataAnalytics() {
    return {
        type: ACTIONS.GET_SHEET_DATA_ANALYTICS,
    }
}

export function setNewSheetDataAnalyticsTbl(dbData: AnalyticsTableState) {
    return {
        type: SAGA_ACTIONS.SET_NEW_SHEET_DATA_ANALYTICS,
        payload: {
            totalRows: dbData.totalRows,
            sheetData: dbData.sheetData,
            hasNext: dbData.hasNext
        }
    }
}

export function handleChangePageAnalyticsTbl(dbData: AnalyticsTableState) {
    return {
        type: SAGA_ACTIONS.HANDLE_PAGE_CHANGE_ANALYTICS,
        payload: {
            totalRows: dbData.totalRows,
            sheetData: dbData.sheetData,
            hasNext: dbData.hasNext
        }
    }
}

export function setTotalRowsAnalyticsTbl(n: number) {
    return {
        type: DUAL_ACTIONS.SET_TOTAL_ROWS_ANALYTICS,
        payload: n
    }
}

export function clearAnalyticsTable() {
    return {
        type: DUAL_ACTIONS.CLEAR_TABLE_ANALYTICS,
    }
}
