import React from 'react'
import { Redirect, Route } from 'react-router-dom'

type PrivateRouteProps = {
    component: React.ComponentType,
    authed: boolean,
    [x: string]: any
}

const PrivateRoute = ({ component: Component, authed, ...rest }: PrivateRouteProps) => {
    return (
        <Route
            {...rest}
            render={(props: any) => authed === true
                ? <Component {...props} />
                : <Redirect to='/login' />
            }
        />
    )
}

export default PrivateRoute