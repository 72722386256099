import isEqual from 'lodash.isequal'
import { memo } from 'react'
import "react-datepicker/dist/react-datepicker.css"
import EndDatePicker, { EndDatePickerProps } from './DateOptions/EndDatePicker'
import QuikChoice, { QuikChoiceProps } from './DateOptions/QuikChoice'
import StartDatePicker, { StartDatePickerProps } from './DateOptions/StartDatePicker'
import s from './DateSection.module.scss'

type Props = {
    startDatePicker: StartDatePickerProps,
    endDatePicker: EndDatePickerProps,
    quikChoice: QuikChoiceProps
}

function DateSection(props: Props) {

    return (
        <div className={s.wrap}>
            <div className={s.innerWrap}>
                <StartDatePicker {...props.startDatePicker} />
                <EndDatePicker {...props.endDatePicker} />
                <QuikChoice {...props.quikChoice} />
            </div>
        </div>
    )
}

const comparison = (prevProps: Props, nextState: Props) => isEqual(prevProps, nextState)
export default memo(DateSection, comparison)
