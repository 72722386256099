import { ACTIONS, AdminActionPayload, SAGA_ACTIONS, Toast } from './interfaces'

export function setAuthed(bool: boolean) {
    return {
        type: ACTIONS.SET_AUTHED,
        payload: bool
    }
}

export function checkAdmin() {
    return {
        type: SAGA_ACTIONS.CHECK_ADMIN
    }
}

export function setToast(toast: Toast): AdminActionPayload {
    return {
        type: ACTIONS.SET_TOAST,
        payload: {
            ...toast
        }
    }
}
