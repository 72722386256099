import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Dropdown, DropdownMenuProps } from 'semantic-ui-react'
import { getNameApps } from '../../../../helpers/utils'
import { setChosenApps_adDataUtil } from '../../../../redux/data-utils/ad-util/adUtilActions'
import { selectAppChoices_adDataUtil, selectChosenQuery_adDataUtil } from '../../../../redux/data-utils/ad-util/adUtilSelectors'
import { AdUtilState } from '../../../../redux/data-utils/ad-util/interfaces'
import { PurchasePageState } from '../../../../redux/purchase-page/interfaces'
import { selectInitLoading } from '../../../../redux/purchase-page/purchasePageSelectors'
import { RdxActionPayload, RootState } from '../../../../redux/reduxTypes'
import s from './AdDataAppDropdown.module.scss'

type Props = {
    initLoading: PurchasePageState["initLoading"],
    appChoices: AdUtilState["appChoices"],
    chosenQuery: AdUtilState["chosenQuery"],
    setChosenApps: (data: string[]) => RdxActionPayload,
}


const AdDataUtilAppDropdown = ({
    initLoading,
    appChoices,
    chosenQuery,
    setChosenApps,
}: Props) => {

    function handleAppDropdownChange(e: React.SyntheticEvent, data: DropdownMenuProps) {
        if (data.value) return setChosenApps(data.value)
    }

    return (
        <div className={s.wrap}>
            <h3>Application</h3>
            <Dropdown
                placeholder='Select app'
                selection
                options={getNameApps(appChoices)}
                onChange={handleAppDropdownChange}
                selectOnBlur={false}
                multiple
                loading={initLoading}
                disabled={initLoading || !chosenQuery}
                fluid
            />
        </div>
    )
}

const mapDispatch = {
    setChosenApps: (data: string[]) => setChosenApps_adDataUtil(data),
}

type SelectorProps = Omit<Props, "setChosenApps">
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    initLoading: selectInitLoading,
    appChoices: selectAppChoices_adDataUtil,
    chosenQuery: selectChosenQuery_adDataUtil
})

export default connect(mapStateToProps, mapDispatch)(AdDataUtilAppDropdown)
