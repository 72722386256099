import { createSelector } from 'reselect'
import { RootState } from '../reduxTypes'

const admin = (rootState: RootState) => rootState.admin

export const selectAuthed = createSelector(
    [admin],
    admin => admin.authed
)

export const selectCurrToast = createSelector(
    [admin],
    admin => admin.toast
)
