import { connect } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button } from 'semantic-ui-react'
import { TABLES, toastErrConfig } from '../../../helpers/constants'
import { PurchasePageState, PURCHASE_PAGE_INITIAL_STATE } from '../../../redux/purchase-page/interfaces'
import { getSheetData } from '../../../redux/purchase-table/purchaseTableActions'
import { RdxActionPayload, RootState } from '../../../redux/reduxTypes'
import { dbSearchActionsNeeded } from '../../../redux/store'
import { getTestSheetData } from '../../../redux/test-purchase-table/testPurchaseTableActions'
import AppSection from './AppSection/AppSection'
// import BrandSection from './BrandSection/BrandSection'
import DateSection from './DateSection/DateSection'
import s from './DbSearchOptions.module.scss'
import { formatAndCheckDates, formatChildProps } from './supplement'

type Props = {
    table: string,
    purchasePage: PurchasePageState,
    testPurchasePage: PurchasePageState,
    getSheetData: () => RdxActionPayload,
    getTestSheetData: () => RdxActionPayload
}

const DbSearchOptions = ({
    table,
    purchasePage,
    testPurchasePage,
    getSheetData,
    getTestSheetData
}: Props) => {

    const { addToast } = useToasts()
    const {
        appSectionProps,
        // brandSectionProps, 
        dateSectionProps
    } = formatChildProps(table, purchasePage, testPurchasePage, dbSearchActionsNeeded);

    async function queryDb() {
        try {
            let { endYear, startYear, toDate, fromDate } = (PURCHASE_PAGE_INITIAL_STATE as PurchasePageState);

            switch (table) {
                case TABLES.PURCHASE_TBL:
                    endYear = purchasePage.endYear;
                    startYear = purchasePage.startYear;
                    toDate = purchasePage.toDate;
                    fromDate = purchasePage.fromDate;
                    break;
                case TABLES.TEST_PURCHASE_TBL:
                    endYear = testPurchasePage.endYear;
                    startYear = testPurchasePage.startYear;
                    toDate = testPurchasePage.toDate;
                    fromDate = testPurchasePage.fromDate;
                    break;
            }

            let dates = formatAndCheckDates(startYear, endYear, fromDate, toDate);
            if (dates.endDate === "err") {
                return addToast('Please ensure end year is after start year, and end date is after start date.', toastErrConfig);
            }

            if (table === TABLES.TEST_PURCHASE_TBL) {
                getTestSheetData();
            } else if (table === TABLES.PURCHASE_TBL) {
                getSheetData();
            }
        } catch (e: any) {
            addToast(`Error in function: ${e.message}`, toastErrConfig);
        }
    }
    return (
        <div className={s.actions}>
            <h1>Search Options</h1>
            <div className={s.choices}>
                <AppSection {...appSectionProps} />
                {/* <BrandSection {...brandSectionProps}/> */}
                <DateSection {...dateSectionProps} />
            </div>
            <Button loading={table === TABLES.PURCHASE_TBL ? purchasePage.getDataLoading : false} onClick={queryDb} color="red">Get Data</Button>
        </div>
    )
}


type StateProps = Pick<Props, "purchasePage" | "testPurchasePage">
const mapStateToProps = (state: RootState): StateProps => {
    const { purchasePage, testPurchasePage } = state
    return { purchasePage, testPurchasePage }
}

const mapDispatch = {
    getSheetData: () => getSheetData(),
    getTestSheetData: () => getTestSheetData()
}

export default connect(mapStateToProps, mapDispatch)(DbSearchOptions)
