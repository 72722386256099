import { connect } from "react-redux"
import { Redirect, Route, Switch } from 'react-router-dom'
import { createStructuredSelector } from "reselect"
import 'semantic-ui-css/semantic.min.css'
import s from './App.module.scss'
import ActionPanel from './components/ActionPanel'
import MainSection from './components/MainSection'
import LoginPage from "./components/pages/LoginPage"
import PrivateRoute from "./components/PrivateRoute"
import { checkAdmin } from "./redux/admin-state/adminActions"
import { selectAuthed } from './redux/admin-state/adminSelectors'
import { AdminState } from "./redux/admin-state/interfaces"
import { initAnalyticsPageState } from "./redux/analytics-page/analyticsPageActions"
import { initAdDataUtil } from "./redux/data-utils/ad-util/adUtilActions"
import { initPurchasePageState } from "./redux/purchase-page/purchasePageActions"
import { RdxActionPayload, RootState } from "./redux/reduxTypes"
import { initTestPurchasePage } from "./redux/test-purchase-page/testPurchasePageActions"

type Props = {
    checkAdmin: () => RdxActionPayload,
    initPurchasePageState: () => RdxActionPayload,
    initAnalyticsPageState: () => RdxActionPayload,
    initTestPurchasePage: () => RdxActionPayload,
    initAdDataUtil: () => RdxActionPayload,
    authed: AdminState["authed"]
}

function App({
    checkAdmin,
    initPurchasePageState,
    initAnalyticsPageState,
    initTestPurchasePage,
    initAdDataUtil,
    authed
}: Props) {

    checkAdmin()
    if (authed) {
        initPurchasePageState()
        initAnalyticsPageState()
        initTestPurchasePage()
        initAdDataUtil()
    }

    const AppBody = () => (
        <>
            <ActionPanel />
            <MainSection />
        </>
    )

    return (
        <div className={s.wrap}>
            {authed ? <Redirect to="/home" /> : <Redirect to="/login" />}
            <Switch>
                <Route path="/login" component={LoginPage} />
                <PrivateRoute path="/home" authed={authed} component={AppBody} />
            </Switch>

        </div>
    )
}

type SelectorProps = Pick<Props, "authed">
const mapStateToProps = createStructuredSelector<RootState, SelectorProps>({
    authed: selectAuthed
})

const mapDispatch = {
    checkAdmin: () => checkAdmin(),
    initPurchasePageState: () => initPurchasePageState(),
    initAnalyticsPageState: () => initAnalyticsPageState(),
    initTestPurchasePage: () => initTestPurchasePage(),
    initAdDataUtil: () => initAdDataUtil(),
}

export default connect(mapStateToProps, mapDispatch)(App)
