import isEqual from 'lodash.isequal'
import { memo } from 'react'
import { AnalyticsPageState } from '../../../../redux/analytics-page/interfaces'
import { RdxActionPayload } from '../../../../redux/reduxTypes'
import TableFieldsSelector from './Dropdowns/TableFieldsSelector'
import s from './TableFieldSection.module.scss'

export type TableFieldSectionProps = {
    initLoading: AnalyticsPageState["initLoading"],
    chosenTable: AnalyticsPageState["chosenTable"],
    chosenTableFields: AnalyticsPageState["chosenTableFields"],
    setTableFields: (data: string) => RdxActionPayload,
    tableFieldChoices: AnalyticsPageState["tableFieldChoices"],
    setManyTableFields: (data: string) => RdxActionPayload
}

const TableFieldSection = (props: TableFieldSectionProps) => {

    return (
        <div className={s.wrap}>
            <h3>Fields</h3>
            <TableFieldsSelector {...props} />
        </div>
    )
}

const comparison = (prevProps: TableFieldSectionProps, nextState: TableFieldSectionProps) => isEqual(prevProps, nextState)
export default memo(TableFieldSection, comparison)
