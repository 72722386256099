import isEqual from 'lodash.isequal'
import { memo } from 'react'
import "react-datepicker/dist/react-datepicker.css"
import { Button } from 'semantic-ui-react'
import EndDatePicker, { EndDatePickerProps } from './DateOptions/EndDatePicker'
import QuikChoice, { QuikChoiceProps } from './DateOptions/QuikChoice'
import StartDatePicker, { StartDatePickerProps } from './DateOptions/StartDatePicker'
import s from './DateSection.module.scss'

type Props = {
    startDatePicker: StartDatePickerProps,
    endDatePicker: EndDatePickerProps,
    quikChoice: QuikChoiceProps
    setShowSecondDate: any
}

function DateSectionSecond({
    startDatePicker,
    endDatePicker,
    quikChoice,
    setShowSecondDate
}: Props) {

    return (
        <div className={s.wrap}>
            <div className={s.innerWrap}>
                <StartDatePicker {...startDatePicker} />
                <EndDatePicker {...endDatePicker} />
                <QuikChoice {...quikChoice} />
            </div>
            <Button
                onClick={() => {
                    startDatePicker.setFromDate('')
                    startDatePicker.setStartYear('')
                    endDatePicker.setEndYear('')
                    endDatePicker.setToDate('')
                    quikChoice.setFromDate('')
                    quikChoice.setStartYear('')
                    quikChoice.setEndYear('')
                    quikChoice.setToDate('')
                    quikChoice.setQuikChoice('')
                    setShowSecondDate(false)
                }}
                color="grey"
            >
                Close Comparison Date
            </Button>
        </div>
    )
}

const comparison = (prevProps: Props, nextState: Props) => isEqual(prevProps, nextState)
export default memo(DateSectionSecond, comparison)
